import React from 'react';
import CustomIcon from './CustomIcon';

const Corporation = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 24 24' }}>
        <path d="M6 7V0h13v10h5v14H1V7h5zm0 16v-4H5v4h1zm8-4h-3v4h3v-4zm6 0h-1v4h1v-4zm2-7h-3v6h2v4h1V12zM17 2H8v20h1v-5h7v5h1V2zM4 22v-4h2V9H3v13h1zm17-6h-1v-2h1v2zM4 14h1v2H4v-2zm8 1h-2v-2h2v2zm3 0h-2v-2h2v2zM5 11v2H4v-2h1zm7 1h-2v-2h2v2zm3 0h-2v-2h2v2zm-3-3h-2V7h2v2zm3 0h-2V7h2v2zm-3-3h-2V4h2v2zm3 0h-2V4h2v2z" />
    </CustomIcon>
);

export default Corporation;
