import React from 'react';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TrendingFlatOutlinedIcon from '@material-ui/icons/TrendingFlatOutlined';

import { CheckCircle } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.secondary,
        borderRadius: 20,
        marginRight: theme.spacing(2.5),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(4, 5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.secondaryContrast
        },
        [theme.breakpoints.up('md')]: {
            flex: '0 1 350px',
            marginBottom: 0
        }
    },
    title: {
        width: '100%',
        color: theme.palette.text.headers,
        fontWeight: '500',
        fontSize: 20
    },
    arrow: {
        fontSize: 30,
        color: theme.palette.text.headers
    },
    doneIcon: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        opacity: '.25',
        marginRight: '16px'
    },
    done: {
        opacity: '.6',
        pointerEvents: 'none'
    }
}));

const ConversationButton = (props) => {
    const { title, done, onClick, disabled } = props;
    const classes = useStyles();

    return (
        <div
            onClick={disabled || done ? null : onClick}
            className={classNames(classes.root, {
                [classes.done]: done || disabled
            })}
        >
            {done && (
                <div className={classes.doneIcon}>
                    <CheckCircle />
                </div>
            )}
            <Typography className={classes.title}>{title || ''}</Typography>
            <TrendingFlatOutlinedIcon className={classes.arrow} />
        </div>
    );
};

export default ConversationButton;
