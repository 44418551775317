import gql from 'graphql-tag';

const GET_REGIONS = gql`
    query regions {
        regions {
            count
            regions {
                description
                id
            }
        }
    }
`;

export { GET_REGIONS };
