import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { InputField, Button } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { ArrowForward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        padding: `${theme.spacing(6)}px ${theme.spacing(3)}px`,
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(6)}px 0`
        }
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    login: {
        maxWidth: '450px',
        width: '100%',
        margin: '0 auto'
    },
    title: {
        paddingBottom: `${theme.spacing(1)}px`
    },
    subTitle: {
        paddingBottom: `${theme.spacing(3)}px`
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    button: {
        width: '100%',
        margin: `${theme.spacing(2)}px 0 `,
        height: '62px'
    },
    listItem: {
        paddingLeft: 0,
        transition: 'color 300ms',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.text.hover
        }
    },
    listIcon: {
        minWidth: '40px',
        color: theme.palette.primary.main
    },
    listLink: {
        fontSize: '16px',
        '& .MuiTypography-root': {
            fontSize: '16px'
        }
    }
}));

const LoginForm = (props) => {
    const [username, setUsername] = useState('');
    const [usernameError, setUserNameError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const { onChange, links = true } = props;

    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleLogin();
        }
    };

    const handleLogin = useCallback(() => {
        if (username === '') {
            setUserNameError(true);
        } else {
            setUserNameError(false);
        }
        if (password === '') {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        if (username === '' || password === '') {
            return;
        }

        onChange(username, password);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password, passwordError, usernameError]);

    return (
        <Grid className={classes.root} container>
            <Grid className={classes.wrapper} item xs={12}>
                <div className={classes.login}>
                    <Typography variant="h2" className={classes.title}>
                        {t('loginForm.title')}
                    </Typography>
                    <Typography variant="body1" className={classes.subTitle}>
                        {t('loginForm.subTitle')}
                    </Typography>
                    <form>
                        <InputField
                            className={classes.input}
                            value={username}
                            onChange={(val) => setUsername(val)}
                            type="text"
                            label="E-mailadres"
                            error={usernameError}
                            errorMessage={t('loginForm.errorEmail')}
                            onKeyPress={onKeyPress}
                        />
                        <InputField
                            className={classes.input}
                            value={password}
                            onChange={(val) => setPassword(val)}
                            type="password"
                            label="Wachtwoord"
                            error={passwordError}
                            errorMessage={t('loginForm.errorPassword')}
                            onKeyPress={onKeyPress}
                        />
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            label={t('loginForm.loginSubmit')}
                            onClick={handleLogin}
                        />
                    </form>
                    {links && (
                        <List dense={true}>
                            <ListItem component={Link} className={classes.listItem} variant="body2" to={'/register'}>
                                <ListItemIcon className={classes.listIcon}>
                                    <ArrowForward />
                                </ListItemIcon>
                                <ListItemText disableTypography primary={t('loginForm.noAccount')} />
                            </ListItem>
                            <ListItem
                                component={Link}
                                className={classes.listItem}
                                variant="body2"
                                to={'/register/forgot-password'}
                            >
                                <ListItemIcon className={classes.listIcon}>
                                    <ArrowForward />
                                </ListItemIcon>
                                <ListItemText disableTypography primary={t('loginForm.forgotPassword')} />
                            </ListItem>
                        </List>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default LoginForm;
