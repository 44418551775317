import { useMutation } from '@apollo/react-hooks';
import { ANONYMOUS_SCAN } from 'api';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Redirect = () => {
    const SUPPORTED_TYPES = ['DEFAULT', 'VESTIA'];
    const [getScan] = useMutation(ANONYMOUS_SCAN);
    const { type } = useParams();

    // Parse given custom type (if any, fall back to DEFAULT if none).
    let typeToSub;
    if (type) {
        if (!SUPPORTED_TYPES.includes(type.toUpperCase())) {
            throw Error(`'${type}' is not supported`);
        }
        typeToSub = type.toUpperCase();
    } else {
        typeToSub = 'DEFAULT';
    }

    useEffect(() => {
        getScan({
            variables: {
                type: typeToSub
            }
        }).then((resp) => {
            if (resp.data) {
                const scan = resp.data.createQuickscanAnonymous;
                const url = scan.url;
                if (url) {
                    window.location.href = url;
                } else {
                    console.warn('No valid redirect url found');
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default Redirect;
