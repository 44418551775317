import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';

import { Breadcrumbs, Button, Loader, MyFlowContainer, PersonBlock } from 'shared';

import { ConversationButton } from './parts';
import { CREATE_MEETING, SEND_MEETING_TO_ADVISOR, SEND_MEETING_TO_USER } from './graphql';
import { useCurrentUser } from 'hooks';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%'
        },
        loading: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        title: {
            marginBottom: '20px'
        },
        buttonWrap: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        },
        dialog: {
            padding: '80px 50px 40px 50px',
            maxWidth: '820px',
            width: '100%'
        },
        dialogTitle: {
            fontWeight: 600,
            fontSize: 30,
            textAlign: 'center',
            color: theme.palette.text.headers
        },
        dialogText: {
            display: 'block',
            maxWidth: '550px',
            margin: '0 auto 35px',
            textAlign: 'center'
        },
        phoneText: {
            display: 'block',
            maxWidth: '500px',
            margin: '0 auto 45px'
        },
        dialogActions: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        inputField: {
            width: '100%',
            marginBottom: theme.spacing(6),
            '& .MuiOutlinedInput-multiline': {
                padding: '40px',
                borderColor: theme.palette.primary.light
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.light
            }
        },
        dialogClose: {
            position: 'absolute',
            top: theme.spacing(3),
            right: theme.spacing(3),
            width: 50,
            height: 50,
            borderRadius: '50%',
            border: `1px solid ${theme.palette.primary.light}`,
            color: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                border: `1px solid ${theme.palette.primary.dark}`
            }
        },
        content: {
            maxWidth: '90%'
        }
    }),
    { name: 'careerGuide' }
);

const BreadcrumbPath = [
    {
        title: 'Mijn loopbaanwijzer',
        link: '/mijn-loopbaanwijzer'
    },
    {
        title: 'Gesprek plannen',
        link: '/mijn-loopbaanwijzer/gesprek-plannen'
    }
];

const types = ['ADVISOR_MEETING_ONE', 'ADVISOR_MEETING_TWO', 'ADVISOR_MEETING_THREE'];
const titles = ['Eerste gesprek plannen', 'Tweede gesprek plannen', 'Derde gesprek plannen'];
const buttons = ['Eerste gesprek', 'Tweede gesprek', 'Derde gesprek'];

const meetingStatuses = {
    open: 'OPEN',
    planned: 'PLANNED',
    finished: 'FINISHED',
    cancelled: 'CANCELLED'
};

const CareerGuide = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userMeetings, careerAdvisor, userData, loading, refetchUserData } = useCurrentUser();
    const hasAdvisor = careerAdvisor && Object.keys(careerAdvisor).length > 0;
    //state hooke
    const [width, setWidth] = useState(window.innerWidth);
    const [emailOpen, setEmailOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [defaultText, setDefaultText] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const phoneNumberRef = useRef(null);
    const [currentStep, setCurrentStep] = useState();
    const [emailLoading, setEmailLoading] = useState(false);

    const getDefaultText = (count) => {
        return (
            'Beste ' +
            (careerAdvisor.name || '') +
            ',\n\nGraag wil ik een afspraak maken voor het ' +
            count +
            ' gesprek. \n\nMet vriendelijke groet,\n' +
            (userData.fullName || '')
        );
    };

    //queries
    const [createMeeting] = useMutation(CREATE_MEETING);
    const [sendMeetingAdvisor] = useMutation(SEND_MEETING_TO_ADVISOR);
    const [sendMeetingUser] = useMutation(SEND_MEETING_TO_USER);

    //functions
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    //effect hooks
    useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    }, []);

    useEffect(() => {
        if (width) {
            setWidth(width);
        }
    }, [width]);

    //functions
    const handleClick = (idx) => () => {
        setCurrentStep(idx);
        if (idx === 0) {
            setTitle('Eerste gesprek plannen');
            setDefaultText(getDefaultText('eerste'));
        } else if (idx === 1) {
            setTitle('Tweede gesprek plannen');
            setDefaultText(getDefaultText('tweede', userData.fullName));
        } else if (idx === 2) {
            setTitle('Derde gesprek plannen');
            setDefaultText(getDefaultText('derde', userData.fullName));
        } else {
            setTitle('Gesprek plannen');
            setDefaultText(getDefaultText());
        }
        setEmailOpen(true);
    };

    const clearForm = () => {
        setPhoneNumber('');
        setPhoneNumberError(false);
    };

    const validateForm = () => {
        let errors = false;

        if (!phoneNumber || !/^[0-9\s\\-]*$/.test(phoneNumber)) {
            setPhoneNumberError(true);
            errors = true;
            phoneNumberRef.current.scrollIntoView();
        } else {
            setPhoneNumberError(false);
        }

        return errors;
    };

    const sendEmail = () => {
        if (validateForm()) {
            return;
        }

        setEmailLoading(true);
        createMeeting({
            variables: {
                input: {
                    owner: userData.id || 0,
                    careerAdvisor: hasAdvisor ? careerAdvisor.id : 0,
                    description: defaultText,
                    title: titles[currentStep],
                    type: types[currentStep],
                    status: meetingStatuses.planned,
                    plannedOn: Date.now(),
                    phoneNumber: phoneNumber
                }
            }
        }).then((resp) => {
            setEmailLoading(false);
            refetchUserData();
            if (resp.errors) {
                enqueueSnackbar({
                    variant: 'warning',
                    message: 'We hebben geen gesprek kunnen aanmaken'
                });
                return;
            }

            enqueueSnackbar({
                variant: 'success',
                message: 'Gesprek is aangemaakt '
            });

            setEmailOpen(false);

            sendEmails(resp.data?.createMeeting?.id);
            clearForm();
        });
    };

    const sendEmails = (id) => {
        if (!id) return;
        sendMeetingAdvisor({
            variables: {
                id: id
            }
        }).then((resp) => {
            if (resp.errors) {
                enqueueSnackbar({
                    variant: 'warning',
                    message: 'We hebben geen mail kunnen sturen, neem contact op met de adviseur'
                });
                return;
            }
            enqueueSnackbar({
                variant: 'success',
                message: 'De e-mail is verzonden naar de adviseur'
            });
        });
        sendMeetingUser({
            variables: {
                id: id
            }
        });
    };

    const handleTextChange = (event) => {
        const text = event.target.value;
        if (!text) return;
        setDefaultText(text);
    };

    const handlePhoneNumberChange = (event) => {
        const phoneNumber = event.target.value;
        if (!phoneNumber) return;
        setPhoneNumber(phoneNumber);
    };

    if (loading) {
        return (
            <div className={classes.loading}>
                <Loader />;
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs path={BreadcrumbPath} />
            <MyFlowContainer
                title={t('planMeeting.title')}
                subTitle={t('planMeeting.subTitle')}
                introText={width >= 960 ? t('planMeeting.introtext') : t('planMeeting.introtextMobile')}
                renderInfoBox={() => (
                    <PersonBlock
                        noEdit={!hasAdvisor}
                        person={careerAdvisor}
                        title={t('careerGuide.myCareerGuider')}
                        text={
                            !hasAdvisor
                                ? 'Voor het plannen van een gesprek moet je eerst een loopbaanadviseur hebben gekozen'
                                : ''
                        }
                    />
                )}
            >
                <Typography variant="h4" className={classes.title}>
                    {t('planMeeting.conversations')}
                </Typography>
                <Typography variant="body1" className={classes.content}>
                    Na het aanklikken van een van onderstaande knoppen wordt een (eenmalige) code naar jouw
                    loopbaanadviseur verzonden. Check daarom of je inderdaad jouw voorkeur voor loopbaanadviseur hebt
                    geselecteerd. Want je kunt deze code niet nogmaals gebruiken.
                </Typography>

                <div className={classes.buttonWrap}>
                    {Object.keys(userMeetings).map((meet, idx) => {
                        const meeting = userMeetings[meet];

                        return (
                            <ConversationButton
                                key={idx}
                                disabled={meeting.status !== 'open' || !hasAdvisor}
                                done={meeting.status === 'done'}
                                title={buttons[idx]}
                                onClick={handleClick(idx)}
                            />
                        );
                    })}
                </div>
            </MyFlowContainer>

            <Dialog
                classes={{
                    paper: classes.dialog
                }}
                maxWidth="lg"
                open={emailOpen}
                onClose={() => setEmailOpen(false)}
            >
                <div className={classes.dialogClose} onClick={() => setEmailOpen(false)}>
                    <CloseIcon />
                </div>
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={classes.dialogText}>
                        Via onderstaand venster verstuur je een e-mail naar de door jou gekozen loopbaanadviseur voor
                        het plannen van een gesprek. Je kunt dit bericht eventueel aanpassen.
                    </Typography>
                    <TextField
                        classes={{
                            root: classes.inputField,
                            input: classes.multiline
                        }}
                        multiline
                        rows={8}
                        defaultValue={defaultText}
                        onChange={handleTextChange}
                        variant="outlined"
                    />
                    <Typography variant="body1" className={classes.dialogText}>
                        Wil je ook je telefoonnummer doorgeven? Je bent dan te bereiken als dit via de mail niet lukt.
                    </Typography>
                    <TextField
                        classes={{ root: classes.inputField }}
                        onChange={handlePhoneNumberChange}
                        variant="outlined"
                        placeholder="Telefoonummer"
                        required
                        value={phoneNumber}
                        error={phoneNumberError}
                        ref={phoneNumberRef}
                        helperText={phoneNumberError ? 'Graag je telefoonummer invullen' : null}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        onClick={() => {
                            setEmailOpen(false);
                            clearForm();
                        }}
                        color="primary"
                        iconLeft={() => <ArrowBackIcon />}
                        variant="outlined"
                        label="Terug"
                    />
                    <Button
                        onClick={sendEmail}
                        variant="contained"
                        color="secondary"
                        loading={emailLoading}
                        label="Verstuur bericht"
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CareerGuide;
