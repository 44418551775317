import React from 'react';
import { CheckCircle, Link } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '70px',
        alignItems: 'flex-start',
        width: '100%',
        padding: theme.spacing(2),
        cursor: 'pointer',
        borderTop: `1px solid ${theme.palette.borderColor.main}`,
        textDecoration: 'none',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: theme.spacing(1.25, 3.5),

            '&:hover': {
                backgroundColor: theme.palette.background.primary
            }
        }
    },
    bottomBorder: {
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        borderTop: 0
    },
    disabled: {
        opacity: '.4',
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    title: {
        width: '100%',
        color: theme.palette.primary.main,
        fontWeight: '500',

        [theme.breakpoints.up('md')]: {
            width: '20%'
        }
    },
    descripton: {
        width: '100%',
        color: theme.palette.text.normal,

        [theme.breakpoints.up('md')]: {
            width: '55%',
            padding: theme.spacing(0, 2.5)
        }
    },
    link: {
        width: '100%',
        color: theme.palette.primary.main,
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.5, 0, 0),

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 2.5),
            width: '25%'
        }
    },
    icon: {
        marginRight: theme.spacing(2),
        opacity: '.3',
        display: 'flex',
        alignItems: 'center'
    },
    doneIcon: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        opacity: '.25',
        marginRight: '16px'
    }
}));

const InfoButton = (props) => {
    const {
        title,
        description,
        link,
        icon: Icon,
        disabled = false,
        done = false,
        borderPosition = 'top',
        subStep = false,
        voucher = false
    } = props;
    const classes = useStyles();

    const handleClick = () => {
        if (props.onClick && !disabled) props.onClick();
    };

    return (
        <Link
            className={classNames(
                classes.root,
                { [classes.disabled]: disabled },
                { [classes.bottomBorder]: borderPosition === 'bottom' }
            )}
            onClick={handleClick}
            to={voucher || (subStep.voucher ? subStep.voucher : false)}
            download={(voucher || subStep.voucher) && `Voucher - ${title}.pdf`}
            target={voucher || subStep.voucher ? '_blank' : '_self'}
            disabled={disabled}
        >
            {done && (
                <span className={classes.doneIcon}>
                    <CheckCircle />
                </span>
            )}
            <Typography className={classes.title}>{title || ''}</Typography>
            <Typography className={classes.descripton}>{description || ''}</Typography>
            <Typography className={classes.link}>
                {Icon && (
                    <span className={classes.icon}>
                        <Icon />
                    </span>
                )}
                {link || ''}
            </Typography>
        </Link>
    );
};

export default InfoButton;
