import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InputField, Button } from 'shared';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_EMAIL } from './graphql';
import { useForm } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1300px',
        minWidth: '968px',
        width: '100%',
        margin: '0 auto',
        paddingBottom: `${theme.spacing(12.5)}px`
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginTop: `${theme.spacing(8)}px`,
        position: 'relative'
    },
    content: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        maxWidth: '400px',
        marginBottom: '20px'
    }
}));

const ChangeEmail = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [form, submitForm] = useForm({
        newEmail: {
            required: true,
            format: 'email'
        }
    });

    const [changeEmailMutation] = useMutation(CHANGE_EMAIL);

    const handleClick = () => {
        submitForm().then((resp) => {
            changeEmailMutation({
                variables: {
                    input: {
                        ...resp
                    }
                }
            })
                .then((r) => {
                    if (r.data && r.data.changeEmailRequest && r.data.changeEmailRequest.done) {
                        history.push(`${location.pathname}/succes`);
                    } else {
                        if (r.errors) {
                            if (r.errors.some((i) => i.message === 'error.user.email.exists')) {
                                enqueueSnackbar({
                                    variant: 'warning',
                                    message: 'E-mailadres is in gebruik'
                                });
                            } else {
                                enqueueSnackbar({
                                    variant: 'warning',
                                    message: 'Je kunt alleen een corporatie-mailadres gebruiken.'
                                });
                            }
                        }
                    }
                })
                .catch((e) => console.log('e', e));
        });
    };

    return (
        <Grid container className={classes.root} spacing={8} justify="space-between">
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h2">
                    E-mail wijzigen
                </Typography>
                <div className={classes.content}>
                    <InputField
                        className={classes.input}
                        type="text"
                        label={'Nieuw e-mailadres'}
                        name="newEmail"
                        placeholder="E-mail"
                        form={form}
                    />
                    <Button variant="contained" color="secondary" label="E-mail aanvragen" onClick={handleClick} />
                </div>
            </Grid>
        </Grid>
    );
};

export default ChangeEmail;
