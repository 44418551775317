import { gql } from 'apollo-boost';

export const GET_METER_URL = gql`
    query getQuickscanUrlForCurrentUser {
        getQuickscanUrlForCurrentUser {
            url
        }
    }
`;

export const SAVE_YMERE = gql`
    mutation QuickScanPlusport {
        QuickScanPlusport
    }
`;

export const GET_METER_RESULT = gql`
    query getQuickscanResultsForCurrentUser {
        getQuickscanResultsForCurrentUser(numberOfScores: 1000) {
            conversationPlan {
                id
                name
                type
                url
                description
                id
                s3Id
            }
            scores {
                instrumentScores {
                    callToActions {
                        description
                        id
                        metaScoreLabel
                        url
                        fillColor
                    }
                    id
                    logicalInterpretation
                    lastChangeUtc
                    metaScores {
                        interpretation
                        label
                        name
                        value
                    }
                    name
                    norm
                    textualInterpretation
                }
            }
            spiderweb {
                id
                name
                type
                url
                description
                id
                s3Id
            }
            conversationPlan {
                id
                url
                name
            }
            url
        }
    }
`;
