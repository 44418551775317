import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ArrowRightAlt, Edit } from '@material-ui/icons';
import { TableRow, TableCell } from 'shared';

const useStyles = makeStyles((theme) => ({
    tableLink: {
        display: 'block',
        color: theme.palette.primary.main,
        fontWeight: 500,
        maxWidth: '350px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        fontSize: '1.125em'
    },
    actionButton: {
        border: '1px solid ' + theme.palette.borderColor.secondary,
        borderRadius: '50%',
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        width: '45px',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '& .MuiSvgIcon-root': {
            width: '25px',
            height: '25px'
        },
        '&:hover': {
            border: '1px solid ' + theme.palette.primary.main,
            background: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    },
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    }
}));
const VacancyRow = (props) => {
    const { date, name, saved, reactions, publicationPhase, path = '/', editPath = '/' } = props;
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell>
                <Typography>{date}</Typography>
            </TableCell>
            <TableCell>
                <Link to={path} className={classes.tableLink}>
                    {name}
                </Link>
            </TableCell>
            <TableCell>
                <Typography variant="body1">{saved}×</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1">{reactions}×</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1">{publicationPhase}</Typography>
            </TableCell>
            <TableCell>
                <div className={classes.center}>
                    <Link to={editPath} className={classes.actionButton}>
                        <Edit />
                    </Link>
                    <Link to={path} className={classes.actionButton}>
                        <ArrowRightAlt />
                    </Link>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default VacancyRow;
