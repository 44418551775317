import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import { InputField } from 'shared';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    input: {
        margin: 0,
        minWidth: '380px',

        '& input': {
            paddingRight: `${theme.spacing(8.75)}px`,
            paddingLeft: `${theme.spacing(3.75)}px`,
            height: '34px',
            borderRadius: '34px',
            '&::placeholder': {
                fontSize: '1.125rem',
                opacity: 1,
                color: theme.palette.primary.main
            }
        }
    },
    fab: {
        width: '60px',
        height: '60px',
        boxShadow: 'none',
        position: 'absolute',
        right: 0,
        top: 0
    },
    root: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}));

const SearchDocument = (props) => {
    const { placeholder = '', onSubmit } = props;
    const classes = useStyles();
    const [query, setQuery] = useState('');

    const handleChange = (data) => {
        setQuery(data);
    };

    const submitSearch = (event) => {
        if (event.key === 'Enter') {
            props.onSubmit(query);
        }
    };

    return (
        <div className={classes.root}>
            <InputField
                placeholder={placeholder}
                className={classes.input}
                onChange={handleChange}
                onKeyPress={(e) => submitSearch(e)}
            />
            <Fab size="large" color="secondary" aria-label="add" className={classes.fab} onClick={onSubmit}>
                <SearchIcon />
            </Fab>
        </div>
    );
};

export default SearchDocument;
