import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        fontFamily: 'Galanogrotesque',
        '& p, & ul, & ol': {
            fontSize: '1.12rem',
            lineHeight: 1.8,
            fontWeight: 'normal',
            margin: 0,
            paddingBottom: theme.spacing(4)
        },
        '& a': {
            color: theme.palette.primary.main,
            transition: 'color 300ms',
            '&:hover': {
                color: theme.palette.text.hover
            }
        },
        '& ul, & ol': {
            paddingLeft: theme.spacing(2.25),
            paddingBottom: theme.spacing(6),

            '& li': {
                paddingLeft: theme.spacing(1.25)
            }
        },
        '& ul': {
            listStyle: 'none',
            marginTop: 0,
            '& li': {
                position: 'relative',
                '&::before': {
                    position: 'absolute',
                    left: '-15px',
                    top: '15px',
                    content: '""',
                    height: '5px',
                    width: '5px',
                    background: theme.palette.primary.main,
                    borderRadius: '50%',
                    display: 'inline-block'
                }
            }
        },
        '& ol': {
            listStyle: 'none',
            counterReset: 'li',
            '& li': {
                counterIncrement: 'li',
                position: 'relative',
                '&::before': {
                    position: 'absolute',
                    left: '-15px',
                    content: 'counter(li)',
                    color: theme.palette.primary.main,
                    fontWeight: 500,
                    display: 'inline-block',
                    width: '1em'
                }
            }
        },
        '& h3': {
            margin: 0,
            paddingBottom: theme.spacing(3),
            fontSize: '38px',
            lineHeight: '48px',
            color: '#2C276A',
            fontWeight: 700
        },
        '& h4': {
            margin: 0,
            paddingBottom: theme.spacing(3),
            fontSize: '32px',
            lineHeight: '38px',
            color: '#2C276A',
            fontWeight: 700
        },
        '& h5': {
            margin: 0,
            paddingBottom: theme.spacing(3),
            fontSize: '26px',
            lineHeight: '32px',
            color: '#2C276A',
            fontWeight: 600
        },
        '& h6': {
            margin: 0,
            paddingBottom: theme.spacing(3),
            fontSize: '20px',
            lineHeight: '28px',
            color: '#2C276A',
            fontWeight: 600
        },
        '& [style*="bold"]': {
            fontWeight: `500 !important`
        }
    }
}));

const RichTextContainer = (props) => {
    const classes = useStyles();
    const { children, className, content, parseText = false, format = true } = props;

    const parseAndTrimContent = (value, count) => {
        let newText = '';

        try {
            const rawContentState = JSON.parse(value);
            if (!rawContentState.blocks) return '';
            rawContentState.blocks.forEach((item) => {
                newText += item.text;
            });
        } catch (error) {
            console.warn('No valid json to parse');
            return '';
        }

        if (!count) return newText;

        if (newText.length > count) {
            return newText.substring(0, count - 3) + '...';
        } else {
            return newText.substring(0, count);
        }
    };

    if (format && !parseText) {
        return (
            <div className={classNames(classes.root, className)}>
                <MUIRichTextEditor toolbar={false} readOnly defaultValue={content} />
            </div>
        );
    } else if (parseText) {
        return (
            <div className={classNames(classes.root, className)}>
                <Typography>{parseAndTrimContent(content, 160)}</Typography>
            </div>
        );
    } else if (!format) {
        return (
            <div className={classNames(classes.root, className)}>
                <Typography>{content}</Typography>
            </div>
        );
    } else {
        return <div className={classNames(classes.root, className)}>{children}</div>;
    }
};
export default RichTextContainer;
