import React, { useEffect, useState } from 'react';
import { Button, InputField, AutoCompleteInput } from 'shared';
import { GQL_CITIES } from './graphql';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useForm } from 'hooks';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        padding: `0 ${theme.spacing(3)}px`,
        margin: '0 auto'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    login: {
        maxWidth: '450px',
        width: '100%',
        margin: '0 auto'
    },
    input: {
        width: '100%',
        marginLeft: 0
    },
    disclaimer: {
        marginTop: `${theme.spacing(3)}px`
    },
    button: {
        width: '100%',
        marginTop: `${theme.spacing(3)}px`
    },
    error: {
        marginTop: `${theme.spacing(2)}px`,
        textAlign: 'center',
        color: theme.palette.error.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    link: {
        marginTop: '20px',
        display: 'block'
    }
}));

const formSettings = {
    firstName: {
        required: true
    },
    lastName: {
        required: true
    },
    middleName: {
        required: false
    },
    email: {
        required: true,
        format: 'email'
    },
    city: {
        required: true
    }
};

const RegisterForm = (props) => {
    const { title, subTitle, onSubmit } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const { loading, data } = useQuery(GQL_CITIES);

    const [, setOptions] = useState([]);
    const [form, submitForm] = useForm(formSettings);

    useEffect(() => {
        if (!loading) {
            if (data && data.cities) {
                const { cities = [] } = data.cities;
                setOptions(cities.map((item) => ({ value: item.id, label: item.name })));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleValidate = (e) => {
        e.preventDefault();
        submitForm()
            .then((r) => {
                onSubmit({
                    ...r,
                    city: r.city && r.city.id
                });
            })
            .catch((e) => {
                console.log('error', e);
            });
    };

    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleValidate(e);
        }
    };

    return (
        <div className={classes.login}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="body1">{subTitle}</Typography>
            <form>
                <InputField
                    name="firstName"
                    className={classes.input}
                    type="text"
                    label={t('register.surname')}
                    onKeyPress={onKeyPress}
                    form={form}
                    autoFocus
                />
                <InputField
                    name="middleName"
                    className={classes.input}
                    type="text"
                    label={t('register.middlename')}
                    onKeyPress={onKeyPress}
                    form={form}
                    optional
                />
                <InputField
                    name="lastName"
                    className={classes.input}
                    type="text"
                    label={t('register.lastname')}
                    onKeyPress={onKeyPress}
                    form={form}
                />
                <InputField
                    name="email"
                    className={classes.input}
                    type="email"
                    label={t('register.email')}
                    onKeyPress={onKeyPress}
                    form={form}
                />
                <AutoCompleteInput
                    label={t('register.city')}
                    placeholder={t('profile.chooseCity')}
                    noOptionsText={t('register.searchCity')}
                    className={classes.input}
                    name="city"
                    form={form}
                    data={{
                        query: GQL_CITIES,
                        response: 'cities'
                    }}
                />

                <div className={classes.disclaimer}>
                    <p>
                        Met het aanmaken van een account ga je akkoord met ons{' '}
                        <a
                            href="https://flowweb.nl/privacystatement"
                            rel="noopener noreferrer nofollow"
                            target="_blank"
                        >
                            Privacystatement
                        </a>{' '}
                        en{' '}
                        <a href="https://flowweb.nl/cookiebeleid" rel="noopener noreferrer nofollow" target="_blank">
                            Cookiebeleid
                        </a>
                        .
                    </p>
                </div>

                <Button
                    className={classes.button}
                    onClick={handleValidate}
                    variant="contained"
                    color="secondary"
                    label={t('register.buttonLabel')}
                />
                <Link to="/" className={classes.link}>
                    <Typography>Terug</Typography>
                </Link>
            </form>
        </div>
    );
};

export default RegisterForm;
