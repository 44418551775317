import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormControlLabel, Switch as MSwitch } from '@material-ui/core';
import { Tooltip } from 'shared';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        width: '48px',
        height: '24px'
    },
    base: {
        padding: '0 2px',
        height: '100%',
        left: 0,
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp
        }),
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&$checked': {
            transform: 'translateX(100%)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1
            }
        }
    },
    labelRoot: {
        marginLeft: 0
    },
    track: {
        borderRadius: '20px',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5
    },
    label: {
        marginLeft: theme.spacing(3.5),
        color: theme.palette.secondary.contrastText,
        userSelect: 'none',
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp
        })
    },
    labelText: {
        opacity: 0.5,
        '&$checked': {
            opacity: 1
        }
    },
    checkedLabel: {
        opacity: 1
    },
    checked: {}
}));

const Switch = (props) => {
    const {
        form = false,
        initialValue,
        name,
        checked = false,
        label = false,
        tooltip,
        onChange,
        loading,
        ...otherProps
    } = props;
    const classes = useStyles();

    // States
    const [checkedState, setCheckedState] = useState(false);
    const [value, setValue] = useState(checked);

    // Effects
    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    useEffect(() => {
        if (!loading && initialValue) {
            setCheckedState(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: checkedState });
        setValue(checkedState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedState]);

    const handleClick = (event) => {
        setCheckedState(event.target.checked);
        if (onChange) onChange({ key: name, value: event.target.checked });
    };

    return (
        <FormControlLabel
            className={classes.labelRoot}
            {...otherProps}
            control={
                <MSwitch
                    classes={{
                        root: classNames(classes.root, { [classes.checked]: checkedState }),
                        switchBase: classes.base,
                        checked: classes.checked,
                        track: classes.track,
                        input: classes.input
                    }}
                    onClick={handleClick}
                    checked={checkedState}
                    disableRipple
                    value={value}
                    name={name}
                />
            }
            label={
                <React.Fragment>
                    <span
                        className={classNames(classes.labelText, {
                            [classes.checked]: checkedState
                        })}
                    >
                        {label}
                    </span>
                    {tooltip && <Tooltip title={tooltip} />}
                </React.Fragment>
            }
            classes={{
                label: classNames(classes.label)
            }}
        />
    );
};

export default Switch;
