import { gql } from 'apollo-boost';

export const GQL_GET_USER = gql`
    query {
        currentUser {
            id
            email
            careerProfile {
                careerAdvisor {
                    id
                }
                careerSteps {
                    id
                    subSteps {
                        id
                        type
                    }
                }
                tasks {
                    id
                    finished
                    name
                }
            }
        }
    }
`;

export const GQL_GET_USERS = gql`
    query ($pagination: PaginationInput!, $filter: UserFilterInput) {
        users(pagination: $pagination, filter: $filter) {
            users {
                id
                email
                profile {
                    firstName
                    fullName
                    city {
                        name
                        province
                    }
                    profilePicture {
                        id
                        url
                        thumbnailUrl
                    }
                    phone
                    biography
                    provinces
                    region {
                        id
                        description
                    }
                    regionList {
                        id
                        description
                    }
                }
                roles {
                    id
                    name
                }
            }
            count
        }
    }
`;

export const GQL_UPDATE_SUB_STEP = gql`
    mutation ($input: UpdateCareerSubStepInput!) {
        updateCareerSubStep(input: $input) {
            careerSubStep {
                id
                finished
            }
        }
    }
`;

export const GQL_SAVE_USER = gql`
    mutation ($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                id
                careerProfile {
                    careerAdvisor {
                        id
                    }
                    careerSteps {
                        id
                        subSteps {
                            id
                            finished
                        }
                    }
                }
            }
        }
    }
`;

export const GQL_UPDATE_TASK = gql`
    mutation ($input: UpdateTaskInput!) {
        updateTask(input: $input) {
            task {
                id
            }
        }
    }
`;
