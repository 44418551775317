import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import TablePagination from '@material-ui/core/TablePagination';
import smoothscroll from 'smoothscroll-polyfill';

// detect if the window scrollTo spec is natively supported and take action only when necessary
smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(1)}px ${theme.spacing(-2.5)}px`
    },
    toolbar: {
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        [theme.breakpoints.down(768)]: {
            justifyContent: 'center'
        }
    },
    actions: {
        [theme.breakpoints.down(768)]: {
            marginLeft: 0
        }
    },
    select: {
        fontSize: '1rem',
        flex: '0 0 auto'
    }
}));

const Pagination = (props) => {
    const { count = 0, query: queryObj = {} } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { query = {} } = queryObj;
    const { pagination = false } = query;

    const handleChangePage = (event, newPage) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (queryObj.setQuery)
            queryObj.setQuery({
                pagination: { page: newPage }
            });
    };

    const handleChangeRowsPerPage = (event) => {
        if (queryObj.setQuery)
            queryObj.setQuery({
                pagination: { page: 0, pageSize: parseInt(event.target.value, 10) }
            });
    };

    if (!pagination) return '';
    return (
        <div>
            <TablePagination
                rowsPerPageOptions={[1, 10, 25, 30]}
                count={count}
                rowsPerPage={parseInt(pagination.pageSize || 0)}
                page={count ? parseInt(pagination.page) : 0}
                component="div"
                classes={{
                    root: classes.root,
                    toolbar: classes.toolbar,
                    actions: classes.actions
                }}
                labelRowsPerPage={t('pagination.rowsPerPage')}
                SelectProps={{
                    inputProps: { 'aria-label': t('pagination.rowsPerPage') },
                    native: true,
                    className: classes.select
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default Pagination;
