import React from 'react';
import AddIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button, LocationMarker, Corporation, Image } from 'shared';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            padding: 0
        },
        summary: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',

            [theme.breakpoints.up('md')]: {
                alignItems: 'center'
            }
        },
        image: {
            display: 'flex',
            flexShrink: 0,
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: theme.palette.divider,
            flex: '0 0 60px',
            [theme.breakpoints.up('sm')]: {
                width: '83px',
                height: '83px',
                flex: '0 0 83px'
            },
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }
        },
        imagePlaceholder: {
            width: '1.5em',
            height: '1.5em'
        },
        userInfo: {
            flex: '0 0 calc(100% - 60px)',
            width: 'calc(100% - 60px)',
            padding: theme.spacing(0, 2.5),
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            [theme.breakpoints.up('sm')]: {
                flex: '0 0 calc(100% - 83px)',
                width: 'calc(100% - 83px)'
            },
            [theme.breakpoints.up('lg')]: {
                flex: '2 0 0',
                width: 'auto'
            }
        },
        name: {
            fontSize: '22px',
            fontWeight: '500',
            margin: theme.spacing(0, 0, 1),
            flex: '0 0 100%'
        },
        infoWrap: {
            flex: '0 0 100%',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 170px'
            }
        },
        infoWrapOne: {
            flex: '0 0 1'
        },
        infoWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        },
        city: {
            display: 'flex',
            alignItems: 'center',
            opacity: '.6',
            color: theme.palette.text.secondary,
            lineHeight: 1.25,
            '& + $city': {
                marginTop: theme.spacing(0.5)
            }
        },
        linkText: {
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1.25,
            margin: theme.spacing(0, 1, 1, 0),
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(0, 0, 1, 0)
            }
        },
        locationIcon: {
            marginRight: theme.spacing(1.25),
            color: theme.palette.text.secondary,
            opacity: '.5',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'flex-start',
            width: '16px'
        },
        icon: {
            marginRight: theme.spacing(1.25)
        },
        expanded: {
            width: 'calc(100% + 50px)',
            display: 'flex',
            flexWrap: 'wrap',
            padding: theme.spacing(2.5, 0),
            margin: theme.spacing(0, -3.125),
            borderBottom: `1px solid ${theme.palette.borderColor.main}`,

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2.5, 3.125),
                flexWrap: 'nowrap'
            }
        },
        content: {
            width: '100%',
            paddingRight: theme.spacing(1.25),

            [theme.breakpoints.up('lg')]: {
                width: '560px'
            }
        },
        button: {
            flexShrink: 0,
            margin: theme.spacing(3.125, 0, 0, 0),
            //width: '100%',

            [theme.breakpoints.up('lg')]: {
                margin: theme.spacing(0),
                width: 'auto'
            }
        },
        buttons: {
            justifySelf: 'flex-start',
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                flexWrap: 'nowrap'
            },
            [theme.breakpoints.up('md')]: {
                flexWrap: 'wrap',
                flexDirection: 'column',
                justifyContent: 'flex-start'
            }
        },
        buttonPhone: {
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
                width: 'calc(50% - 15px)'
            },
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(0),
                width: 'auto'
            }
        },
        buttonOverride: {
            width: '100%',
            marginBottom: theme.spacing(1.25),

            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
                width: 'calc(50% - 15px)'
            },
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(0),
                width: 'auto',
                marginBottom: theme.spacing(1.25)
            }
        }
    }),
    { name: 'FindAdvisorBlock' }
);

const ExpansionPanel = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        '&$expanded': {
            margin: 0,
            '&:before': {
                opacity: 1
            }
        }
    },
    expanded: {}
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
    root: {},
    content: {
        margin: theme.spacing(2.5, 0),
        '&$expanded': {
            margin: theme.spacing(2.5, 0)
        }
    },
    expanded: {}
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {}
}))(MuiExpansionPanelDetails);

//check if string is JSON (RichTextField)
const isJSON = (data) => {
    try {
        JSON.parse(data);
    } catch (e) {
        return false;
    }
    return true;
};

const FindAdvisorBlock = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { profile = {}, user = {} } = props;

    const handleEmail = (event) => {
        event.stopPropagation();

        if (user.email) {
            window.location.href = `mailto:${user.email}`;
        }
    };

    const handlePhone = (event) => {
        event.stopPropagation();

        if (user.profile && user.profile.phone) {
            window.location.href = `tel:${user.profile.phone}`;
        }
    };

    const handleChooseAdvisor = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (props.onChooseAdvisor) props.onChooseAdvisor(user.id);
    };

    const getBiography = () => {
        if (isJSON(profile.biography)) {
            let biography = JSON.parse(profile.biography);
            return biography && biography.blocks && biography.blocks[0].text;
        } else {
            return profile.biography;
        }
    };

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary className={classes.root} aria-label="Expand" expandIcon={<AddIcon />}>
                <div className={classes.summary}>
                    <div className={classes.image}>
                        {profile.profilePicture ? (
                            <Image src={profile.profilePicture.thumbnailUrl} />
                        ) : (
                            <PersonIcon className={classes.imagePlaceholder} />
                        )}
                    </div>
                    <div className={classes.userInfo}>
                        {profile.fullName && (
                            <Typography variant="h6" className={classes.name}>
                                {profile.fullName}
                            </Typography>
                        )}
                        <div className={classes.infoWrapper}>
                            <div className={classes.infoWrap}>
                                <div className={classes.buttons}>
                                    {profile.phone && (
                                        <Typography
                                            color="primary"
                                            variant="subtitle1"
                                            className={classes.linkText}
                                            onClick={handlePhone}
                                        >
                                            <PhoneIcon color="inherit" className={classes.icon} />
                                            {profile.phone}
                                        </Typography>
                                    )}
                                    <Typography
                                        color="primary"
                                        variant="subtitle1"
                                        className={classes.linkText}
                                        onClick={handleEmail}
                                    >
                                        <MailOutlineIcon color="inherit" className={classes.icon} />
                                        {t('general.emailing')}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.infoWrapOne}>
                                {profile.provinces && profile.provinces.length > 0 && (
                                    <Typography className={classes.city}>
                                        <LocationMarker color="inherit" className={classes.locationIcon} />
                                        {profile.provinces.join(', ')}
                                    </Typography>
                                )}
                                {profile.regionList && profile.regionList.length > 0 && (
                                    <Typography className={classes.city}>
                                        <Corporation color="inherit" className={classes.locationIcon} />
                                        {profile.regionList.map((region) => region.description).join(', ')}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </div>
                    <Button
                        label={t('advisorsOverview.chooseAdvisor')}
                        color="secondary"
                        variant="contained"
                        className={classes.button}
                        onClick={handleChooseAdvisor}
                    />
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className={classes.expanded}>
                    <Typography className={classes.content}>
                        {getBiography() && getBiography().length ? getBiography() : ''}
                    </Typography>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default FindAdvisorBlock;
