import React from 'react';
import CustomIcon from './CustomIcon';

const Background = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 1280.38 1900.38' }}>
        <g data-name="00-mijnflow-inloggen">
            <path
                d="M531 1558.03l161.1 161.1c14.74 14.75 4.3 40-16.55 40H514.41a23.41 23.41 0 01-23.41-23.41v-161.14c0-20.85 25.21-31.29 40-16.55z"
                style={{
                    isolation: 'isolate'
                }}
                stroke="#d0bada"
                strokeWidth={5}
                opacity={0.25}
                fill="none"
            />
            <path
                d="M141.61 119.85c0-147.73-157.09-117.25 283-117.25l807.16 807.19a165.82 165.82 0 010 234.49l-807.12 807.2c-104.45 104.45-283 30.47-283-117.25z"
                fill="#f8f5f9"
                fillRule="evenodd"
            />
            <path
                d="M15.45 1391.45l54.23 54.23a7.88 7.88 0 01-5.57 13.45H9.88a7.88 7.88 0 01-7.88-7.88v-54.23a7.88 7.88 0 0113.45-5.57z"
                style={{
                    isolation: 'isolate'
                }}
                strokeWidth={4}
                stroke="#6e3286"
                opacity={0.35}
                fill="none"
            />
            <path
                data-name="Stroke-1"
                d="M127.48 1862.27l81.35-82.14c7.44-7.52 20.17-2.19 20.17 8.43v82.12a11.87 11.87 0 01-11.82 11.93h-81.34c-10.53.02-15.84-12.83-8.36-20.34z"
                style={{
                    isolation: 'isolate'
                }}
                stroke="#b3d450"
                opacity={0.6}
                strokeWidth={4}
                fill="none"
            />
        </g>
    </CustomIcon>
);

export default Background;
