import React from 'react';
import { Typography, List as MList, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    listHeading: {
        marginBottom: theme.spacing(0.5)
    },
    listItem: {
        padding: 0
    },
    listIcon: {
        minWidth: 40,
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1)
    },
    text: {
        marginTop: theme.spacing(2)
    }
}));

const List = (props) => {
    const { title, listItems, text, iconAlign = 'left' } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            {title && (
                <Typography className={classes.listHeading} variant="h4">
                    {title}
                </Typography>
            )}
            {text && <Typography className={classes.text}>{text}</Typography>}
            {listItems && (
                <MList>
                    {listItems &&
                        listItems.map((item, idx) => (
                            <ListItem className={classes.listItem} alignItems="flex-start" disableGutters key={idx}>
                                {iconAlign === 'left' && (
                                    <ListItemIcon className={classes.listIcon}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                )}
                                <ListItemText>
                                    <span dangerouslySetInnerHTML={{ __html: item }} />
                                </ListItemText>
                                {iconAlign === 'right' && (
                                    <ListItemIcon className={classes.listIcon}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                )}
                            </ListItem>
                        ))}
                </MList>
            )}
        </React.Fragment>
    );
};

export default List;
