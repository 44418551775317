import React from 'react';
import CustomIcon from './CustomIcon';

const Hexa = (props) => {
    return (
        <CustomIcon width="28" height="26" {...props}>
            <path
                d="M22.302 7.422L14.51 2.926a1 1 0 00-1.004.002l-7.794 4.5a1 1 0 00-.504.868l-.002 8.996a1 1 0 00.5.866l7.792 4.496a1 1 0 001.004-.002l7.794-4.5a1 1 0 00.504-.868l.002-8.996a1 1 0 00-.5-.866zm-1.552 9.313l-6.746 3.895-6.798-3.915.008-7.845 6.79-3.92 6.798 3.915-.052 7.87z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </CustomIcon>
    );
};

export default Hexa;
