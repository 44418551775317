import classNames from 'classnames';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import React from 'react';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background
    },
    root: {
        padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
        '&:first-child': {
            paddingLeft: 0
        }
    }
}))(TableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            overflowX: 'auto'
        },
        tableCell: {
            opacity: '.5'
        },
        table: {
            width: '100%'
        },
        link: {
            cursor: 'pointer'
        },
        icon: {
            color: theme.palette.primary.light,
            transform: 'translateX(0)',
            transition: 'transform 200ms'
        },
        row: {
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    '& $icon': {
                        transform: 'translateX(10px)'
                    }
                }
            }
        }
    }),
    { name: 'table' }
);

const TableComponent = (props) => {
    const { head, renderRows, className } = props;
    const classes = useStyles();

    return (
        <Table className={classNames(classes.table, className)} aria-label="customized table">
            <TableHead>
                <TableRow>
                    {head &&
                        head.map((item, idx) => (
                            <StyledTableCell key={idx}>
                                <Typography className={classes.tableCell}>{item}</Typography>
                            </StyledTableCell>
                        ))}
                </TableRow>
            </TableHead>
            <TableBody>{renderRows && renderRows()}</TableBody>
        </Table>
    );
};

export default TableComponent;
