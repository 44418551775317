import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import nlLocale from 'date-fns/locale/nl';
import { DatePicker as MDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import smoothscroll from 'smoothscroll-polyfill';

// detect if the window scrollTo spec is natively supported and take action only when necessary
smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `${theme.spacing(3.125)}px 0 ${theme.spacing(1)}px`,
        position: 'relative',
        width: '100%',
        '& .MuiInputBase-root': {
            marginTop: `${theme.spacing(2)}px`
        },
        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    pickerRoot: {
        width: '100%'
    },
    icon: {
        position: 'absolute',
        right: '15px',
        bottom: '13px',
        height: '20px',
        width: '20px',
        color: theme.palette.text.icon,
        pointerEvents: 'none'
    },
    inputComponent: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '3px',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(1.875)}px`,
        '&:before, &:after': {
            display: 'none'
        },
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: '0 0 0 4px #E7DCEC'
        }
    },
    inputElement: {},
    inputLabel: {
        position: 'relative',
        fontSize: '1.125rem',
        fontWeight: 500,
        transform: 'none',
        userSelect: 'none',
        paddingBottom: theme.spacing(0.5),
        '& + $inputElement': {
            marginTop: theme.spacing(2)
        }
    },
    optionalLabel: {
        fontSize: '1rem',
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        marginLeft: theme.spacing(1)
    }
}));

const DatePicker = (props) => {
    const classes = useStyles(props);
    const {
        label = '',
        disablePast = true,
        disableFuture = false,
        initialValue = null,
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        form = false,
        clearable = true,
        onChange,
        onClose,
        variant = 'dialog',
        orientation = 'portrait',
        openTo = 'year',
        name,
        open
    } = props;

    const [selectedDate, handleDateChange] = useState();
    const [error, setError] = useState(errorFromProps);
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps);

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: initialValue });
        handleDateChange(initialValue);
    }, [initialValue, form, name]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
    }, [form, name]);

    const handleChange = (event) => {
        if (form) form.onFieldChange({ key: name, value: event });
        handleDateChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    const handleDialogOnEntered = (e) => {
        const yearSelected = e.querySelector('.MuiPickersYear-yearSelected');
        yearSelected && yearSelected.scrollIntoView({ block: 'center' });
    };

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider className={classes.pickerRoot} utils={DateFnsUtils} locale={nlLocale}>
                <MDatePicker
                    open={open}
                    variant={variant}
                    orientation={orientation}
                    label={label}
                    value={selectedDate}
                    onChange={handleChange}
                    onClose={onClose}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    openTo={openTo}
                    format="dd-MM-yyyy"
                    clearable={clearable}
                    clearLabel="Leeg"
                    cancelLabel="Annuleer"
                    okLabel="Selecteer"
                    todayLabel="Vandaag"
                    name={name}
                    error={error}
                    helperText={error ? errorMessage : ''}
                    InputLabelProps={{
                        shrink: false,
                        className: classes.inputLabel
                    }}
                    InputProps={{
                        className: classes.inputElement,
                        disableUnderline: true,
                        endAdornment: <DateRangeIcon className={classes.icon} />
                    }}
                    inputProps={{
                        className: classes.inputComponent
                    }}
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    DialogProps={{
                        onEntered: handleDialogOnEntered
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default DatePicker;
