import gql from 'graphql-tag';

const GET_USER_COMPLETENESS = gql`
    query getUserCompleteness($id: Long!) {
        user(id: $id) {
            id
            email
            profile {
                profilePublic
                completeness {
                    name
                    done
                }
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                id
                profile {
                    profilePublic
                }
            }
        }
    }
`;

export { GET_USER_COMPLETENESS, UPDATE_USER };
