import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import { GET_USER_FOLDERS, GET_USER_FOLDER_DATA, DELETE_DOCUMENT, UPLOAD_DOCUMENT } from './graphql';

import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Grid } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ROLES } from 'const';

import { Table, TableRow, ArrowRight, Button, Folder, FolderChecked, Loader, SwipeWrapper } from 'shared';
import DeleteDialog from './DeleteDialog';
import Row from './Row';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5, 3, 15, 3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(15, 3, 27.5, 3)
        }
    },
    loader: {
        position: 'absolute',
        top: theme.spacing(16),
        left: '50%',
        transform: 'translate(-50%, 0)'
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: `${theme.spacing(5.5)}px`,
        minHeight: '60px'
    },
    subTitle: {
        fontWeight: 500,
        marginBottom: `${theme.spacing(1.5)}px`
    },
    bottom: {
        borderTop: `1px solid ${theme.palette.borderColor.main}`
    },
    maps: {
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        padding: theme.spacing(5.5, 2.5),
        position: 'relative',

        [theme.breakpoints.up('md')]: {
            borderBottom: 0,
            borderRight: `1px solid ${theme.palette.borderColor.main}`,
            minHeight: '350px',
            padding: theme.spacing(5.5, 2.5, 20, 0)
        }
    },
    addDocument: {
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4)
        }
    },
    hoverArrow: {
        display: 'flex',
        marginLeft: 'auto'
    },
    map: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginBottom: `${theme.spacing(1.25)}px`,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                color: theme.palette.text.hover
            }
        }
    },
    active: {
        fontWeight: 500
    },
    documents: {
        position: 'relative',
        minHeight: '100px',
        padding: theme.spacing(5, 2.5),

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5.5, 0, 7.5, 6.25)
        }
    },
    folder: {
        color: theme.palette.primary.main,
        marginRight: `${theme.spacing(2.5)}px`,
        width: '18px',
        height: '18px'
    },
    loading: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    visibilityMarker: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: '#eaeaea',
        padding: '5px 15px',
        borderRadius: '30px'
    },
    visibilityIcon: {
        marginRight: 10
    }
}));

const Documents = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const inputRef = React.useRef();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [folders, setFolders] = useState([]);
    const [folderData, setFolderData] = useState([]);
    const [currentFolder, setCurrentFolder] = useState('');
    const [currentDocumentId, setCurrentDocumentId] = useState(0);

    // Selectors from the store
    const currentUserRole = useSelector((state) => state.auth.role);

    useEffect(() => {
        getFolderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserRole]);

    // Get User asset folders on role id
    const getFolderData = () => {
        const role = ROLES[currentUserRole];
        getUserAssetFolders({
            variables: {
                roleId: role.id
            }
        });
    };

    // Get all the user folders and the first documents of de current folder
    const [getUserAssetFolders, { loading: loadingUserFolders }] = useLazyQuery(GET_USER_FOLDERS, {
        onCompleted: (data) => {
            setFolders([]);
            const userAssetFolders = data.userAssetFolders.assetFolders;
            setFolders([...userAssetFolders]);
            setCurrentFolder(userAssetFolders[0]);

            getDocuments({
                variables: {
                    folderId: userAssetFolders[0].id,
                    pagination: {
                        sortProperties: ['createdOn'],
                        page: 0,
                        pageSize: 1000
                    }
                }
            });
        }
    });

    const handleFolderClick = (idx) => {
        const newFolder = folders.find((folder) => folder.id === idx);
        // return if clicked folder is current folder
        if (!newFolder || newFolder.id === currentFolder.id) return;

        setFolderData([]);
        setCurrentFolder(newFolder);

        getDocuments({
            variables: {
                folderId: idx,
                pagination: {
                    sortProperties: ['createdOn'],
                    page: 0,
                    pageSize: 1000
                }
            }
        });
    };

    const [getDocuments, { loading, error }] = useLazyQuery(GET_USER_FOLDER_DATA, {
        onCompleted: (documentsData) => {
            if (!documentsData) return;
            setFolderData(documentsData.userAssets.assets);
        }
    });

    const [removeDocument] = useMutation(DELETE_DOCUMENT);

    const handleDelete = () => {
        removeDocument({
            variables: {
                id: currentDocumentId
            }
        })
            .then((response) => {
                const {
                    data: { deleteDocument }
                } = response;
                if (deleteDocument) {
                    getDocuments({
                        variables: {
                            folderId: currentFolder.id,
                            pagination: {
                                sortProperties: ['createdOn'],
                                page: 0,
                                pageSize: 1000
                            }
                        }
                    });
                    setDialogOpen(false);
                }
            })
            .catch((error) => {
                console.warn('handleDelete error: ', error);
            });
    };

    const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);

    const handleChange = (input) => {
        const file = input.target.files[0];

        if (file) {
            inputRef.current.value = null;
            uploadDocument({
                variables: {
                    file: file,
                    input: {
                        assetFolder: currentFolder.id
                    }
                },
                context: {
                    hasUpload: true
                }
            })
                .then((response) => {
                    const {
                        data: { uploadDocument }
                    } = response;
                    if (uploadDocument) {
                        getDocuments({
                            variables: {
                                folderId: currentFolder.id,
                                pagination: {
                                    sortProperties: ['createdOn'],
                                    page: 0,
                                    pageSize: 1000
                                }
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.warn('uploadDocument error: ', error);
                });
        }
    };

    const handleClick = (data, value) => {
        if (value === 'delete') {
            setDialogOpen(true);
            setCurrentDocumentId(data);
        }
    };

    const addDocument = () => {
        inputRef.current.click();
    };

    return (
        <Container className={classes.root} maxWidth="lg">
            <div className={classes.top}>
                <Typography className={classes.title} variant="h2" component="h1">
                    {t('documents.title')}
                </Typography>
            </div>
            <Grid container className={classes.bottom}>
                <Grid item className={classes.maps} xs={12} md={3}>
                    <Typography variant="h6" gutterBottom className={classes.subTitle}>
                        {t('documents.maps')}
                    </Typography>
                    {loadingUserFolders ? (
                        <Loader />
                    ) : (
                        <div className={classes.mapsContainer}>
                            {folders.map((userAssetFolder) => (
                                <Typography
                                    className={classNames(classes.map, {
                                        [classes.active]: userAssetFolder.id === currentFolder.id
                                    })}
                                    onClick={() => handleFolderClick(userAssetFolder.id)}
                                    key={userAssetFolder.id}
                                >
                                    <React.Fragment>
                                        {userAssetFolder.id === currentFolder.id ? (
                                            <>
                                                <FolderChecked className={classes.folder} />
                                                {userAssetFolder.name}
                                                <span className={classes.hoverArrow}>
                                                    <ArrowRight />
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <Folder className={classes.folder} />
                                                {userAssetFolder.name}
                                            </>
                                        )}
                                    </React.Fragment>
                                </Typography>
                            ))}
                        </div>
                    )}

                    {currentFolder.canCreateFiles && (
                        <React.Fragment>
                            <Button
                                className={classes.addDocument}
                                variant="contained"
                                label={t('documents.addDocument')}
                                color="secondary"
                                onClick={addDocument}
                                iconLeft={() => <PlusIcon />}
                            />
                            <input type="file" ref={inputRef} hidden="hidden" onChange={handleChange} />
                        </React.Fragment>
                    )}
                </Grid>

                <Grid item className={classes.documents} xs={12} md={9}>
                    <Typography variant="h6" gutterBottom className={classes.subTitle}>
                        {currentFolder && currentFolder.name}
                    </Typography>
                    {currentFolder.canCreateFiles && currentFolder.isPrivate && (
                        <Typography variant="subtitle2" className={classes.visibilityMarker}>
                            <VisibilityOffIcon className={classes.visibilityIcon} /> De bestanden in deze map zijn
                            alleen voor jou te zien.
                        </Typography>
                    )}
                    {currentFolder.canCreateFiles && !currentFolder.isPrivate && (
                        <Typography variant="subtitle2" className={classes.visibilityMarker}>
                            <VisibilityIcon className={classes.visibilityIcon} /> De bestanden die je in deze map
                            uploadt zijn zichtbaar voor alle anderen in deze groep.
                        </Typography>
                    )}
                    {loading && !error && <Loader />}
                    {!loading && !error && folderData && folderData.length > 0 && (
                        <SwipeWrapper>
                            <Table
                                head={[t('documents.name'), t('documents.date'), t('documents.fileSize'), '']}
                                renderRows={() =>
                                    folderData.map((row) => (
                                        <TableRow key={row.id}>
                                            <Row
                                                row={row}
                                                onClick={handleClick}
                                                folderId={currentFolder.id}
                                                allowedToDelete={currentFolder.canDeleteFiles}
                                                currentFolder={currentFolder}
                                            />
                                        </TableRow>
                                    ))
                                }
                            />
                        </SwipeWrapper>
                    )}
                    {!loading && !error && folderData && folderData.length === 0 && t('documents.noDocuments')}
                    {!loading && error && t('documents.networkError')}
                </Grid>
            </Grid>
            <DeleteDialog open={dialogOpen} onClose={() => setDialogOpen(false)} onDelete={handleDelete} />
        </Container>
    );
};
export default Documents;
