import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '25px',
        // maxWidth: '610px',
        display: 'flex',
        background: theme.palette.background.primary,
        padding: `${theme.spacing(6)}px`,

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            padding: theme.spacing(3.5, 5)
        }
    },
    white: {
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.borderColor.main}`
    },
    blue: {
        background: theme.palette.background.secondary,
        color: theme.palette.secondary.contrastText
    },
    smaller: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 500,
        paddingTop: `${theme.spacing(3)}px`,
        paddingBottom: `${theme.spacing(3)}px`
    },
    row: {
        flexDirection: 'row'
    }
}));

const Block = (props) => {
    const { children, title = false, variant, row, smaller, className } = props;
    const classes = useStyles();

    return (
        <Grid
            className={classNames(classes.root, classes[variant], classes[row], classes[smaller], className)}
            container
            direction="column"
        >
            {title && <Typography variant="h4">{title}</Typography>}
            {children}
        </Grid>
    );
};

export default Block;
