import { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useFilter = (scheme) => {
    const history = useHistory();
    const location = useLocation();
    const initial = {
        pagination: {
            page: 0,
            pageSize: 10,
            ...(scheme ? scheme.pagination || {} : {})
        },
        filter: {
            ...(scheme ? scheme.filter || {} : {})
        }
    };

    const [query, setQuery] = useState({ ...initial });

    const queryFunctions = {
        setQuery: (data) => setFilter(data),
        query,
        filters: query.filter,
        pagination: query.pagination
    };

    const handleSetQuery = useCallback((parsed, pagination) => {
        const filter = {
            ...initial.filter
        };
        if (Object.keys(parsed).length) {
            Object.keys(parsed).forEach((key) => {
                if (pagination[key] !== undefined) pagination[key] = parsed[key];
                else {
                    if (parsed[key] === 'false' || parsed[key] === 'true') {
                        filter[key] = parsed[key] === 'true';
                    } else {
                        filter[key] = parsed[key];
                    }
                }
            });

            setQuery({ pagination: { ...pagination }, filter: { ...filter } });
        } else {
            setQuery({ ...initial });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const pagination = { ...query.pagination };
        const filter = { ...query.filter };

        handleSetQuery(parsed, pagination, filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const setHistory = (filter) => {
        const stringified = queryString.stringify({ ...filter.pagination, ...filter.filter });
        history.push({
            search: stringified
        });
    };

    const setFilter = (scheme) => {
        const hasFilter = !!(scheme.filter && Object.keys(scheme.filter).length);
        const temp = { ...query.filter };
        for (const key in scheme.filter) {
            if (!scheme.filter[key]) {
                delete temp[key];
            } else {
                temp[key] = scheme.filter[key];
            }
        }

        setHistory({
            filter: {
                ...temp
            },
            pagination: {
                ...query.pagination,
                ...(scheme ? scheme.pagination || {} : {}),
                ...(hasFilter ? { page: 0 } : {})
            }
        });
    };

    return queryFunctions;
};

export default useFilter;
