import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { isEmpty } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Loader, Pagination, Button, Notification, Search } from 'shared';

import { SET_ALERT } from './queries';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `0 auto ${theme.spacing(5)}px`,
        maxWidth: '1280px',
        padding: theme.spacing(0, 3.75)
    },
    filtersRoot: {
        padding: theme.spacing(1, 5),

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5)
        }
    },
    title: {
        lineHeight: '40px',
        fontWeight: '400',
        fontSize: '1.35rem',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            lineHeight: '75px'
        }
    },
    results: {
        lineHeight: '30px',
        color: theme.palette.text.secondary,
        fontWeight: '400',

        [theme.breakpoints.up('md')]: {
            lineHeight: '80px'
        }
    },
    alertButton: {
        margin: theme.spacing(2, 0, 0, 0),
        width: '100%',
        fontSize: '1rem',
        background: theme.palette.secondary.light,

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0, 1, 0)
        },

        '&:last-of-type': {
            marginBottom: theme.spacing(2.5)
        }
    },
    alertIcon: {
        width: '20px',
        height: '20px',
        transform: 'translateY(-2px)'
    },
    childRoot: {
        position: 'relative'
    },
    content: {
        padding: 0
    },
    loader: {
        position: 'absolute',
        top: theme.spacing(25),
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 2,
        '& + $content': {
            opacity: '.2',
            filter: 'blur(2px)'
        }
    }
}));

const FilterOverview = (props) => {
    const { t } = useTranslation();
    const {
        children,
        query = {},
        count,
        loading,
        filterContent: FilterContent,
        className,
        title = '',
        alert = false,
        useResults = true,
        currentSearchValue,
        showFilters = true
    } = props;
    const { query: queryData = {} } = query;
    const { pagination = {} } = queryData;
    const { filters } = query;
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();
    const user = useSelector((state) => state.auth.user);
    const loggedIn = !isEmpty(user);

    const [currentPageSize, setCurrentPageSize] = useState(15);
    const [currentStartSize, setCurrentStartSize] = useState(1);

    useEffect(() => {
        if (pagination.pageSize) {
            const sum = (parseFloat(pagination.page) + 1) * parseFloat(pagination.pageSize);
            const start = parseFloat(pagination.page) * parseFloat(pagination.pageSize) + 1;
            setCurrentPageSize(sum > count ? count : sum);
            setCurrentStartSize(start);
        }
    }, [pagination, count]);

    const [setAlerts] = useMutation(SET_ALERT);

    const handleChange = (data) => {
        if (data.arrayName) {
            const { query: _query = {} } = query;
            const { filter = {} } = _query;
            let _arr = filter[data.arrayName] || [];

            if (_arr.length > 0 && !Array.isArray(_arr)) {
                _arr = [_arr];
            }

            const arr = [];

            _arr.forEach((item) => {
                if (item === data.name && !data.value) {
                    _arr = _arr.filter((value) => value !== data.name);
                } else if (item != null && !arr.includes(item)) {
                    arr.push(item);
                }
            });

            if (data.value) {
                arr.push(data.name);
                query.setQuery({
                    filter: {
                        [data.arrayName]: [...arr]
                    }
                });
            } else {
                if (filter[data.arrayName] && !Array.isArray(filter[data.arrayName])) {
                    delete filter[data.arrayName];
                    query.setQuery({
                        filter: { ...filter }
                    });
                } else {
                    query.setQuery({
                        filter: {
                            [data.arrayName]: filter[data.arrayName].filter((val) => {
                                return val.toString() !== data.name.toString();
                            })
                        }
                    });
                }
            }
        } else {
            query.setQuery({ filter: { [data.name]: data.value } });
        }
    };

    const setSearchAlert = (activate = false) => {
        const { city, search, distance } = currentSearchValue;
        if (!search) {
            enqueueSnackbar({
                variant: 'warning',
                message: t('Voer een zoekterm in ')
            });
            return;
        }
        const searchObj = {};
        if (city && city.id) {
            searchObj.cityId = city.id;
        }
        if (distance) {
            searchObj.distance = distance;
        }
        if (search) {
            searchObj.search = search;
        }
        if (filters) {
            Object.entries(filters).forEach((filter) => {
                const key = filter[0];
                const value = filter[1];
                if (key !== 'draft' && key !== 'city') {
                    searchObj[key] = value;
                }
            });
        }
        if (activate === true) {
            searchObj.active = true;
        }

        setAlerts({
            variables: {
                input: {
                    ...searchObj
                }
            }
        }).then((resp) => {
            enqueueSnackbar({
                variant: 'success',
                message: t('Zoekopdracht/kansenalert opgeslagen')
            });
        });
    };

    return (
        <Container className={classNames(classes.root, className)}>
            <Grid container spacing={10}>
                {FilterContent && showFilters && (
                    <Grid item xs={12} md={4} className={classes.filtersRoot}>
                        {alert && loggedIn && (
                            <>
                                <Button
                                    onClick={setSearchAlert}
                                    variant="contained"
                                    color="secondary"
                                    className={classes.alertButton}
                                    label={t('filter.alertButtonSave')}
                                    iconLeft={() => <Search className={classes.alertIcon} />}
                                />
                                <Button
                                    onClick={() => setSearchAlert(true)}
                                    variant="contained"
                                    color="secondary"
                                    className={classes.alertButton}
                                    label={t('filter.alertButtonActivate')}
                                    iconLeft={() => <Notification className={classes.alertIcon} />}
                                />
                            </>
                        )}

                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <FilterContent onChange={handleChange} loading={loading} filters={filters} />
                    </Grid>
                )}

                <Grid item xs={12} md={FilterContent ? 8 : 12} className={classes.childRoot}>
                    {useResults && currentPageSize > 0 && currentStartSize >= 0 && count && (
                        <Typography className={classes.results}>
                            Zoekresultaten ({`${currentStartSize} - ${currentPageSize} van ${count}`})
                        </Typography>
                    )}
                    {loading && (
                        <div className={classes.loader}>
                            <Loader />
                        </div>
                    )}
                    {children && <div className={classes.content}>{children}</div>}
                </Grid>
            </Grid>
            <Pagination query={query} count={count} loading={loading} />
        </Container>
    );
};

export default FilterOverview;
