import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ANONYMOUS_RESULTS } from 'api/quickScanQueries';
import parseHroQuickScanScore from 'components/QuickScan/parser';
import { Button, Loader, Download, Hexa } from 'shared';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import background1 from 'assets/images/background-1.svg';
import background2 from 'assets/images/background-2.svg';
import MeterContainer from '../QuickScan/MeterContainer';
import ResultPart from '../QuickScan/ResultPart';
import AppHeader from 'components/AppHeader';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHroId } from 'store/actions';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            minHeight: '500px',
            position: 'relative',
            background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat`,

            [theme.breakpoints.up('md')]: {
                background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat,
                url(${background1}) bottom right / 250px no-repeat`
            }
        },
        loader: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        title: {
            marginBottom: theme.spacing(5)
        },
        blockTitle: {
            fontWeight: 600,
            marginBottom: theme.spacing(2)
        },
        resultLink: {
            display: 'inline-flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: '1.125rem',
            fontWeight: 500,
            marginTop: theme.spacing(1),
            marginRight: 'auto',
            padding: theme.spacing(1.25, 2.5),

            '&:hover': {
                '& .MuiSvgIcon-root': {
                    opacity: 1
                }
            }
        },
        resultIcon: {
            width: '28px',
            height: '28px',
            opacity: 0.5
        },
        downloadIcon: {
            color: theme.palette.primary.contrastText
        },
        blockLink: {
            display: 'inline-flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: '1.125rem',
            fontWeight: 500,
            marginTop: theme.spacing(2),
            background: theme.palette.background.secondary,
            padding: theme.spacing(1.25, 2.5),

            '&:hover': {
                background: theme.palette.background.secondaryContrast,

                '& .MuiSvgIcon-root': {
                    opacity: 1
                }
            }
        },
        linkIcon: {
            width: '22px',
            height: '22px',
            opacity: '0.5',
            transition: 'opacity 300ms, color 300ms'
        },
        redoWrap: {
            padding: theme.spacing(2, 4, 10),
            margin: '0 auto',
            width: '100%',
            maxWidth: '1340px',

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2, 4, 18)
            }
        },
        redoButton: {
            marginRight: 'auto',
            padding: theme.spacing(1, 2.5),
            color: theme.palette.primary.main,

            '&:hover .MuiSvgIcon-root': {
                color: theme.palette.primary.contrastText
            }
        },
        redoIcon: {
            width: '18px',
            marginRight: `${theme.spacing(2)}px`
        },
        bottomWrap: {
            maxWidth: '1340px',
            margin: '0 auto 80px auto'
        },
        notFoundWrap: {
            maxWidth: '1340px',
            margin: '160px auto 0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        button: {
            marginTop: theme.spacing(1)
        }
    }),
    { name: 'Quickscan' }
);

const QuickScanResults = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [currentScore, setCurrentScore] = useState(null);
    const [instrumentScores, setInstrumentScores] = useState({});
    const [allScores, setAllScores] = useState({});
    const [meterResult, setMeterResults] = useState(null);
    const [report, setReport] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const paths = location.pathname.split('/');
    const id = paths[paths.length - 1];

    const { data, loading, error } = useQuery(GET_ANONYMOUS_RESULTS, {
        variables: {
            id: id
        }
    });

    useEffect(() => {
        if (data) {
            const result = data.getQuickscanAnonymousResultsForHroPersonId;
            if (result) {
                setMeterResults(result);
                dispatch(setHroId(id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (meterResult) {
            const { scores = {}, conversationPlan = {} } = meterResult;
            if (scores) {
                const { instrumentScores = {} } = scores;
                if (instrumentScores) {
                    setInstrumentScores(instrumentScores);
                    setAllScores(parseHroQuickScanScore(meterResult));
                }
            }

            if (conversationPlan && conversationPlan.url) {
                setReport(conversationPlan);
            }
        }
    }, [meterResult]);

    useEffect(() => {
        if (instrumentScores.length) {
            // Sort the scores based on their dates, pick the first one in the resulting array as that has the most recent date
            const current = instrumentScores.sort(
                (scoreOne, scoreTwo) => new Date(scoreTwo.lastChangeUtc) - new Date(scoreOne.lastChangeUtc)
            )[0];
            setCurrentScore(current);
        }
    }, [instrumentScores]);

    const infoBlock = () => (
        <React.Fragment>
            <Typography variant="h5" className={classes.blockTitle}>
                {t('quickScan.myResult')}
            </Typography>

            <Button
                color="secondary"
                variant="contained"
                className={classes.resultLink + '  AnonymousResults'}
                label={t('quickScan.resultSaveButton')}
                iconRight={() => <Hexa className={classes.resultIcon} />}
                component={Link}
                to="/login?AnonymousResults"
                data-label="resultaten"
            />

            {report && (
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.resultLink}
                    label={t('quickScan.resultDownloadButton')}
                    iconRight={() => <Download className={classes.downloadIcon} />}
                    component={Link}
                    to={report.url}
                    download={report.name}
                    target="_blank"
                    rel="noreferrer noopener"
                />
            )}
        </React.Fragment>
    );

    return (
        <div className={classes.root}>
            <AppHeader noLinks noLogin={true} />
            {loading && <Loader className={classes.loader} />}
            {!loading && (error || !data || !meterResult) && (
                <div className={classes.notFoundWrap}>
                    <Typography>We hebben helaas geen resultaten kunnen vinden voor deze gebruiker</Typography>

                    <Button
                        className={classes.button}
                        to={'/'}
                        component={Link}
                        color="primary"
                        variant="contained"
                        label="Terug naar Mijn FLOW"
                    />
                </div>
            )}
            {!loading && !error && data && currentScore && currentScore.metaScores.length > 0 && (
                <>
                    <MeterContainer
                        title={t('quickScan.title')}
                        result={currentScore ? currentScore.logicalInterpretation : ''}
                        renderInfoBox={infoBlock}
                        introText={t('quickScan.introTextAnonymous')}
                    >
                        {Object.entries(allScores).map(([key, value]) => {
                            return (
                                <ResultPart
                                    key={key}
                                    title={allScores[key].name}
                                    currentMood={allScores[key].value}
                                    description={allScores[key].interpretation}
                                    callToActions={allScores[key].ctas ? allScores[key].ctas : []}
                                    previous={allScores[key].previous ? allScores[key].previous : ''}
                                    activeState={true}
                                />
                            );
                        })}
                    </MeterContainer>
                    <div className={classes.bottomWrap}>
                        <Button
                            className="AnonymousResults"
                            component={Link}
                            to="/login?AnonymousResults"
                            color="secondary"
                            variant="contained"
                            iconRight={() => <Hexa className={classes.resultIcon} />}
                            label={t('quickScan.resultSaveButton')}
                            data-label="resultaten"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default QuickScanResults;
