import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Modal } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4)
    }
}));

const MobileNotify = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [width, setWidth] = React.useState(window.innerWidth < 968);

    const handleResize = () => {
        setWidth(window.innerWidth < 968);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        width && (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <Typography variant="h5" id="server-modal-title">
                        {t('noMobile.title')}
                    </Typography>
                    <Typography variant="body1" id="server-modal-description">
                        {t('noMobile.content')}
                    </Typography>
                </div>
            </Modal>
        )
    );
};

export default MobileNotify;
