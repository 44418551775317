import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ArrowRight } from 'shared';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(0)}px`,
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        '&:hover': {
            '& $title': {
                color: theme.palette.text.hover
            },
            '& $arrow': {
                color: theme.palette.text.hover
            }
        }
    },
    checkbox: {
        width: '24px',
        height: '24px',
        flex: '0 0 24px',
        borderRadius: '50%',
        border: `1px solid ${theme.palette.primary.main}`,
        marginRight: '24px'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        marginRight: 'auto',
        width: '100%',
        transition: 'color 300ms'
    },
    lastItem: {
        borderBottom: 0
    },
    check: {
        position: 'absolute',
        left: 0,
        top: '19px',
        height: '18px',
        zIndex: 2,
        color: theme.palette.primary.contrastText
    },
    arrow: {},
    checked: {
        '& $checkbox': {
            opacity: 0.25,
            border: 0,
            backgroundColor: theme.palette.primary.main
        },
        '& $title': {
            opacity: 0.5
        },
        '& $arrow': {
            opacity: 0.25
        },
        '&:hover': {
            '& $title': {
                color: theme.palette.primary.main
            },
            '& $arrow': {
                color: theme.palette.primary.main
            }
        }
    }
}));

const ProfilePointer = (props) => {
    const { title, path = '/', checked = false, last } = props;
    const classes = useStyles();

    return (
        <Link
            className={classNames(classes.root, { [classes.lastItem]: last }, { [classes.checked]: checked })}
            to={path}
        >
            {checked && <CheckIcon className={classes.check} />}
            <span className={classes.checkbox} />
            <Typography className={classes.title} variant="body1">
                {title}
            </Typography>
            <ArrowRight className={classes.arrow} />
        </Link>
    );
};

export default ProfilePointer;
