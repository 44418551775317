import React from 'react';
import CustomIcon from './CustomIcon';

const LoginIcon = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 18 18' }}>
        <path
            d="M9.53 9.9L7.46 12a.91.91 0 101.28 1.28l3.6-3.6a1.17 1.17 0 00.19-.3.89.89 0 000-.68 1.17 1.17 0 00-.19-.3l-3.6-3.6a.88.88 0 00-.87-.23.87.87 0 00-.64.64.88.88 0 00.23.79l2.07 2.1H.9a.9.9 0 100 1.8zM9 0A9 9 0 00.9 5a.91.91 0 001.62.81A7.2 7.2 0 119 16.2a7.12 7.12 0 01-6.44-4 .92.92 0 00-1.22-.4.89.89 0 00-.4 1.2A9 9 0 109 0z"
            fill="currentColor"
        />
    </CustomIcon>
);

export default LoginIcon;
