import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TableCell, TableRow, Button, Grid, Typography } from '@material-ui/core';
import { Pencil } from 'shared';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const MeetingRow = (props) => {
    const { meeting, classes, openDatePicker, setCurrentMeeting, updateMeeting } = props;
    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(false);

    const localeDateString = (date) => {
        return new Date(date).toLocaleDateString();
    };

    const toggleEditMode = () => {
        setEditMode((prevState) => !prevState);
    };

    useEffect(() => {
        setEditMode(editMode);
    }, [editMode]);

    if (!meeting.owner) {
        console.warn('No owner found for meeting, so skipping row (for now).', meeting);
        return null;
    }

    return (
        <TableRow>
            <TableCell className={classes.tableCell}>{meeting.code}</TableCell>
            <TableCell className={classes.tableCell}>
                {/* VB: the owner property should not be null, but somehow it is on production. So temp fix it like this*/}
                <div className={classes.maxWidth}>{meeting.owner?.profile?.fullName || '-'}</div>
            </TableCell>
            <TableCell className={classNames(classes.tableCell, classes.tableCellPrimary)}>
                <div className={classNames(classes.maxWidth, classes.widerMaxWidth)}>{meeting.owner.email}</div>
            </TableCell>
            <TableCell className={classNames(classes.tableCell, classes.maxWidth)}>{meeting.mappedType}</TableCell>
            <TableCell className={classNames(classes.tableCell, classes.buttonCell)}>
                <Grid container spacing={2}>
                    <Grid item className={classes.alignCenter}>
                        {editMode ? (
                            <Button
                                variant="contained"
                                className={classNames(classes.button, classes.buttonSecondary, classes.meetingDate)}
                                disableElevation
                                onClick={() => {
                                    setCurrentMeeting(meeting);
                                    openDatePicker();
                                }}
                            >
                                {localeDateString(meeting.finishedOn)}
                            </Button>
                        ) : (
                            <Typography className={classes.meetingDate}>
                                {localeDateString(meeting.finishedOn)}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={() => {
                                toggleEditMode();
                                updateMeeting({
                                    id: meeting.id,
                                    ownerId: meeting.owner.id,
                                    status: 'CANCELLED',
                                    message: t('dashboard.meetingCanceledMessage')
                                });
                            }}
                            variant="contained"
                            className={classNames(classes.button, classes.hidden, {
                                [classes.visible]: editMode
                            })}
                            disableElevation
                        >
                            {t('dashboard.meetingCanceled')}
                        </Button>
                    </Grid>
                    <Grid item className={classes.gridEnd}>
                        <Button
                            variant="contained"
                            className={classNames(classes.button, classes.editButton)}
                            onClick={toggleEditMode}
                            disableElevation
                        >
                            {editMode ? (
                                <CloseIcon color="inherit" className={classes.editIcon} />
                            ) : (
                                <Pencil color="inherit" className={classes.editIcon} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default MeetingRow;
