import React, { useState, useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';
import { Checkbox as MCheckbox, FormControlLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        width: '48px',
        height: '24px'
    },
    formControl: {
        width: '100%'
    },
    loading: {
        pointerEvents: 'none',
        opacity: '.5'
    },
    checkboxRoot: {
        color: theme.palette.primary.light,
        padding: `${theme.spacing(0.875)}px ${theme.spacing(1.125)}px`
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '18px',
        height: '18px',
        border: '1px solid currentColor',
        borderRadius: '2px'
    },
    iconSVG: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.contrastText
    },
    iconChecked: {
        backgroundColor: theme.palette.primary.main
    },
    label: {
        marginLeft: theme.spacing(1.25),
        color: theme.palette.text.primary,
        padding: `${theme.spacing(0.75)}px 0`,
        lineHeight: 1.4,
        fontSize: theme.typography.htmlFontSize
    },
    labelRoot: {
        alignItems: 'flex-start',
        userSelect: 'none'
    }
}));

const Checkbox = (props) => {
    const { label = false, value, checked = false, form = false, name, arrayName, loading, tooltip } = props;
    const classes = useStyles(props);

    const [checkedState, setCheckedState] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: checked });
        setCheckedState(checked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.errors]);

    const handleChange = (event) => {
        if (form) form.onFieldChange({ key: name, value: event.target.checked });
        setCheckedState(event.target.checked);
        if (props.onChange) {
            const obj = {
                value: event.target.checked,
                name: name || ''
            };
            if (arrayName) obj.arrayName = arrayName;
            props.onChange(obj);
        }
    };

    return (
        <FormControl
            component="fieldset"
            className={classNames(classes.formControl, { [classes.loading]: loading })}
            error={error}
        >
            <FormControlLabel
                className={classes.labelRoot}
                control={
                    <MCheckbox
                        classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checked
                        }}
                        icon={<span className={classes.icon}></span>}
                        checkedIcon={
                            <span
                                className={classNames(classes.icon, {
                                    [classes.iconChecked]: checkedState
                                })}
                            >
                                <CheckIcon className={classes.iconSVG} />
                            </span>
                        }
                        onChange={handleChange}
                        checked={checkedState}
                        value={value}
                        color={'primary'}
                    />
                }
                label={
                    tooltip ? (
                        <React.Fragment>
                            {label}
                            <Tooltip title={tooltip} />
                        </React.Fragment>
                    ) : (
                        label
                    )
                }
                classes={{
                    label: classNames(classes.label, { [classes.checkedLabel]: checkedState })
                }}
            />
            {errorMessage && <FormHelperText>{error && errorMessage}</FormHelperText>}
        </FormControl>
    );
};

export default Checkbox;
