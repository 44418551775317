import React from 'react';
import CustomIcon from './CustomIcon';

const FolderChecked = (props) => {
    return (
        <CustomIcon {...props} svgAtts={{ viewBox: '0 0 18 17' }}>
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M11.061 7.4174L8.1 10.37 6.939 9.207c-.353-.3509-.925-.3509-1.278 0a.8947.8947 0 000 1.2705l1.8 1.7895a.9027.9027 0 00.639.2646c.24 0 .47-.0953.639-.2646l3.6-3.579a.8947.8947 0 000-1.2705c-.353-.3509-.925-.3509-1.278 0zM15.3 2.6842H9.648l-.288-.8947C8.9773.7135 7.952-.0044 6.804 0H2.7C1.2088 0 0 1.2018 0 2.6842v11.6316C0 15.7982 1.2088 17 2.7 17h12.6c1.4912 0 2.7-1.2018 2.7-2.6842V5.3684c0-1.4824-1.2088-2.6842-2.7-2.6842zm.9 11.6316c0 .4941-.403.8947-.9.8947H2.7c-.497 0-.9-.4006-.9-.8947V2.6842c0-.4941.403-.8947.9-.8947h4.104c.387-.001.7312.244.855.6084l.486 1.4674c.1238.3644.468.6094.855.6084h6.3c.497 0 .9.4006.9.8947v8.9474z"
                opacity=".8"
            />
        </CustomIcon>
    );
};

export default FolderChecked;
