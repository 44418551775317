import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'shared';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'color 300ms',
        '&:hover': {
            color: theme.palette.text.hover,
            '& $arrow': {
                color: theme.palette.text.hover
            }
        }
    },
    arrow: {
        height: '12px',
        marginLeft: `${theme.spacing(2)}px`,
        transition: 'color 300ms'
    }
}));

const ReadMore = (props) => {
    const classes = useStyles();
    const { label, className, path = '#' } = props;
    return (
        <Link className={`${classes.root} ${className}`} to={path}>
            {label} <ArrowRight className={classes.arrow} />
        </Link>
    );
};

export default ReadMore;
