import Container from '@material-ui/core/Container';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Block } from 'shared';
import { SmileyGood, SmileyNeutral, SmileyBad } from 'shared';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            maxWidth: '1340px',
            padding: theme.spacing(5, 3.75, 7),

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(8, 3.75, 12.5)
            }
        },
        title: {
            marginBottom: theme.spacing(2.5),
            fontSize: '2.2rem',
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(5),
                fontSize: '3rem',
                lineHeight: 1.3
            }
        },
        subHeader: {
            marginBottom: theme.spacing(2),
            fontWeight: 600
        },
        smiley: {
            marginRight: theme.spacing(1),
            width: '28px',
            height: '28px',
            transform: 'translateY(4px)'
        },
        ctaBlocks: {
            marginBottom: theme.spacing(2)
        },
        block: {
            margin: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',

            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(6.25)
            }
        },
        contentWrapper: {
            padding: theme.spacing(10, 5, 2.5, 5),

            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(5)
            }
        },
        item: {
            padding: theme.spacing(5),

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(5),
                marginTop: theme.spacing(5)
            }
        },
        introText: {
            maxWidth: '560px',
            '& + p': {
                marginTop: theme.spacing(4)
            }
        },
        children: {
            [theme.breakpoints.up('md')]: {
                paddingTop: '0!important'
            }
        }
    }),
    { name: 'MyFlowContainer' }
);

const MeterContainer = (props) => {
    const { title, result, introText, renderInfoBox, renderAdditional, children } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    const getSmile = (mood) => {
        let content = '';

        switch (mood) {
            case 'H':
                content = (
                    <Typography variant="h5" className={classes.subHeader}>
                        <SmileyGood className={classes.smiley} /> {t('quickScan.yourResult')}: {t('quickScan.good')}
                    </Typography>
                );
                break;
            case 'M':
                content = (
                    <Typography variant="h5" className={classes.subHeader}>
                        <SmileyNeutral className={classes.smiley} />
                        {t('quickScan.yourResult')}: {t('quickScan.neutral')}
                    </Typography>
                );
                break;
            case 'L':
                content = (
                    <Typography variant="h5" className={classes.subHeader}>
                        <SmileyBad className={classes.smiley} />
                        {t('quickScan.yourResult')}: {t('quickScan.bad')}
                    </Typography>
                );
                break;
            default:
                content = '';
        }

        return content;
    };

    return (
        <Container maxWidth="lg" fixed className={classes.root}>
            <Grid container spacing={10} justify="space-between">
                <Grid item className={classes.contentWrapper} xs={12} md={7}>
                    <Typography className={classes.title} variant="h3">
                        {title}
                    </Typography>

                    {getSmile(result)}

                    {introText && <Typography className={classes.introText}>{introText}</Typography>}
                    {renderAdditional && renderAdditional()}
                </Grid>
                {renderInfoBox && (
                    <Grid item xs={12} md={5} className={classes.item}>
                        <div className={classes.block}>
                            <Block>{renderInfoBox && renderInfoBox()}</Block>
                        </div>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.children}>
                    {children}
                </Grid>
            </Grid>
        </Container>
    );
};

export default MeterContainer;
