import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUserRole } from 'store/actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import classNames from 'classnames';

import {
    AppBar,
    Toolbar,
    MenuItem,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@material-ui/core';
import Cookie from 'js-cookie';
import { KeyboardArrowDown, Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { Button, LoginIcon, NavBar, SearchAlerts } from 'shared';
import { ROLES } from 'const';

const useStyles = makeStyles((theme) => ({
    appBar: {
        boxShadow: 'none',
        color: theme.palette.text.secondary,
        backgroundColor: (props) =>
            props.plateBackground
                ? props.showHeader
                    ? theme.palette.background.primary
                    : 'transparent'
                : props.showHeader
                ? theme.palette.background.paper
                : 'transparent',
        border: (props) =>
            `1px solid ${
                props.plateBackground
                    ? props.showHeader
                        ? theme.palette.background.primary
                        : 'transparent'
                    : props.showHeader
                    ? theme.palette.borderColor.main
                    : 'transparent'
            }`,
        '& a': {
            margin: theme.spacing(0, 2.5)
        },
        [theme.breakpoints.down('md')]: {
            '& a': {
                margin: theme.spacing(0, 1),
                fontSize: '0.9rem'
            }
        },
        [theme.breakpoints.down('sm')]: {
            '& a': {
                fontSize: '1.2rem'
            }
        }
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white'
        }
    },
    logo: {
        display: 'flex',
        '& img': {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2)
        }
    },
    userInfo: {
        display: 'flex',
        marginLeft: 'auto',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            marginLeft: 'initial'
        }
    },
    spacer: {
        width: '100%',
        height: '80px'
    },
    buttonWrap: {
        position: 'relative'
    },
    buttonMenu: {
        position: 'relative',
        zIndex: 2,
        fontSize: '18px',
        height: '50px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '25px',
        transition: 'border-radius 300ms',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1)
        },
        '& svg': {
            margin: 0
        },
        '& .MuiSvgIcon-root': {
            transition: 'transform 300ms'
        },
        '& .MuiButton-label': {
            justifyContent: 'space-between'
        },
        [theme.breakpoints.up(1280)]: {
            minWidth: '220px'
        }
    },
    buttonMenuActive: {
        borderBottom: 0,
        borderRadius: '25px 25px 0 0',
        '&:hover': {
            borderBottom: 0
        },
        '& $arrow': {
            transition: 'transform 300ms',
            transform: 'rotate(180deg)'
        }
    },
    person: {
        transform: 'translateY(4px)',
        marginLeft: theme.spacing(1.25)
    },
    personLabel: {
        margin: 0
    },
    arrow: {},
    menu: {
        '& > .MuiPopover-paper': {
            marginTop: '50px',
            zIndex: '-1',
            borderRadius: '0 0 25px 25px',
            paddingBottom: theme.spacing(1),
            minWidth: '0',
            boxShadow: 'none',
            backgroundColor: (props) =>
                props.plateBackground ? theme.palette.background.primary : theme.palette.background.paper,
            border: `1px solid ${theme.palette.primary.light}`,
            [theme.breakpoints.up(1280)]: {
                borderTop: 'none'
            },
            '& .MuiListItem-button': {
                fontSize: '16px',
                padding: theme.spacing(0, 2),
                lineHeight: '36px',
                whiteSpace: 'initial',
                color: theme.palette.secondary.contrastText,

                '&:hover': {
                    color: theme.palette.text.hover,
                    background: 'transparent'
                }
            }
        }
    },
    iconSVG: {
        transform: 'rotate(180deg)',
        width: '16px',
        marginRight: '5px'
    },
    hamburger: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        flexShrink: 0,
        border: `1px solid ${theme.palette.primary.light}`,
        zIndex: 10,
        background: theme.palette.background.primary,
        position: 'relative',
        margin: theme.spacing(0, 0, 0, 1),
        cursor: 'pointer',

        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        '& > span': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme.palette.primary.main,
            width: '20px',
            height: '2px',
            transition: 'background-color 0ms linear 200ms',
            '&::before, &::after': {
                content: '" "',
                backgroundColor: theme.palette.primary.main,
                width: '20px',
                height: '2px',
                position: 'absolute',
                transition: 'top 150ms ease-in 150ms, bottom 150ms ease-in 150ms, transform 150ms ease-out 0ms'
            },
            '&::before': {
                top: '-5px'
            },
            '&::after': {
                bottom: '-5px'
            }
        }
    },
    hamburgerActive: {
        '& > span': {
            backgroundColor: 'transparent',

            '&::before, &::after': {
                top: 0,
                bottom: 0,
                transition: 'top 200ms ease-out 0ms, bottom 200ms ease-out 0ms, transform 200ms ease-in 200ms'
            },
            '&::before': {
                transform: 'rotateZ(45deg)'
            },
            '&::after': {
                transform: 'rotateZ(-45deg)'
            }
        }
    },
    loginIcon: {
        marginRight: theme.spacing(1.25)
    }
}));

const Header = (props) => {
    const {
        renderLogo,
        showNotifications = true,
        links,
        user,
        signOut,
        headerBackground = false,
        showProfileLink = true,
        hrUser = false,
        careerAdvisor = false,
        diAdvisor = false,
        showHrAccountLink = false,
        showReadMore = true,
        closeNotifications = false,
        noLogin = false
    } = props;

    const location = useLocation();
    const history = useHistory();
    const [headerVisible, setHeaderVisible] = useState(true);
    const classes = useStyles({ showHeader: headerVisible, plateBackground: headerBackground });
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [buttonOpen, setButtonOpen] = React.useState(false);

    const currentRole = useSelector((state) => state.auth.role);
    const currentUser = useSelector((state) => state.auth.user);
    const userRoles = currentUser.roles || [];

    useEffect(() => {
        if (location) {
            if (location.pathname === '/login') {
                setHeaderVisible(false);
            } else if (location.pathname.match('/register[a-z,A-Z,0-9,/]*')) {
                setHeaderVisible(false);
            } else {
                setHeaderVisible(true);
            }
        }
    }, [location]);

    window.onresize = () => {
        if (width !== window.innerWidth) {
            setWidth(window.innerWidth);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        const menuWidth = event.currentTarget.offsetWidth + 'px';
        if (width >= 1280) {
            document.getElementsByClassName('MuiPaper-elevation8')[0].style.width = menuWidth;
        }

        if (buttonOpen) {
            setButtonOpen(false);
        } else {
            setButtonOpen(true);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setButtonOpen(false);
    };

    const handleProfileClick = () => {
        setAnchorEl(null);
        setButtonOpen(false);
        history.push('/mijn-gegevens/');
    };

    const handleHelpClick = () => {
        setAnchorEl(null);
        setButtonOpen(false);
        window.open(`${process.env.REACT_APP_FLOW_WEB_DOMAIN}/veelgestelde-vragen`, '_blank');
    };

    const handleRoleChange = (roleName) => {
        setAnchorEl(null);
        setButtonOpen(false);

        if (!roleName) return;
        dispatch(setUserRole(roleName));
        Cookie.set('roleSelect', roleName, { expires: 365 });

        if (roleName === ROLES.DI_SPP) {
            window.open(`${process.env.REACT_APP_MY_FLOW_DOMAIN}?role=${roleName}`, '_self');
        }

        history.push('/');
    };

    const handeSignOut = () => {
        setAnchorEl(null);
        setButtonOpen(false);
        signOut();
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const hamburgerEvent = () => {
        setMenuOpen(!menuOpen);
    };

    const linkClick = () => {
        setMenuOpen(false);
        setButtonOpen(false);
    };

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {renderLogo && <div className={classes.logo}>{renderLogo()}</div>}
                    {headerVisible && (
                        <React.Fragment>
                            <NavBar
                                links={links}
                                open={!!menuOpen}
                                onSucces={linkClick}
                                user={user}
                                signOut={signOut}
                            />
                            <div className={classes.userInfo}>
                                {!user ? (
                                    width > 1023 &&
                                    !noLogin && (
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            component={Link}
                                            to={'/login'}
                                            label="Inloggen"
                                            iconLeft={() => <LoginIcon className={classes.loginIcon} />}
                                        />
                                    )
                                ) : (
                                    <React.Fragment>
                                        {showNotifications && (
                                            <SearchAlerts
                                                showReadMore={showReadMore}
                                                closeNotifications={closeNotifications}
                                            />
                                        )}
                                        <div className={classes.buttonWrap}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={handleClick}
                                                className={classNames(classes.buttonMenu, {
                                                    [classes.buttonMenuActive]: buttonOpen
                                                })}
                                                classes={{ label: classes.personLabel }}
                                                label={
                                                    width > 1280 ? (
                                                        (user && user.profile && user.profile.fullName) || 'Profiel'
                                                    ) : (
                                                        <Person className={classes.person} />
                                                    )
                                                }
                                                iconRight={() => <KeyboardArrowDown className={classes.arrow} />}
                                            />
                                            <Menu
                                                id="simple-menu"
                                                className={classes.menu}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleHelpClick}>{t('menu.help')}</MenuItem>

                                                {showProfileLink && (
                                                    <MenuItem onClick={handleProfileClick}>Profiel</MenuItem>
                                                )}

                                                {showHrAccountLink && !hrUser && !careerAdvisor && !diAdvisor && (
                                                    <MenuItem onClick={() => setOpen(true)}>Kans plaatsen?</MenuItem>
                                                )}
                                                {userRoles &&
                                                    userRoles.map(
                                                        (item) =>
                                                            item.name !== currentRole && (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    onClick={() => handleRoleChange(item.name)}
                                                                >
                                                                    Naar {item.title}
                                                                </MenuItem>
                                                            )
                                                    )}
                                                <MenuItem onClick={handeSignOut}>
                                                    <ExitToAppIcon className={classes.iconSVG} />
                                                    Uitloggen
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                            <div
                                onClick={hamburgerEvent}
                                className={classNames(classes.hamburger, {
                                    [classes.hamburgerActive]: menuOpen
                                })}
                            >
                                <span></span>
                            </div>
                        </React.Fragment>
                    )}
                </Toolbar>
            </AppBar>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Kans plaatsen?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>
                            Wil je graag kansen plaatsen? Vraag dan een zogenoemd HR-account aan via{' '}
                            <a href="mailto:kansenportaal@flowweb.nl">kansenportaal@flowweb.nl</a>. Graag met vermelding
                            van naam, functie, corporatie en je corporatiemailadres. De sollicitaties op de kans komen
                            binnen op het mailadres dat je doorgeeft.
                        </p>
                        <p>
                            Met een HR-account ben je verplicht om vanuit je functie of rol vertrouwelijk om te gaan met
                            de persoonsgegevens van kansenzoekers op het Kansenportaal zoals vermeld in de AVG.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" variant="contained" autoFocus label="Sluiten" />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Header;
