import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs as MBreadcrumbs } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 0),
        width: '100%',
        background: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',

        [theme.breakpoints.up('md')]: {
            position: 'absolute'
        }
    },
    breadcrumb: {
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        padding: theme.spacing(0, 3),
        fontSize: '0.88rem'
    },
    smaller: {
        maxWidth: '1330px'
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 500,
        transition: 'color 250ms',

        '&:hover': {
            color: theme.palette.text.hover
        }
    },
    back: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        paddingLeft: `${theme.spacing(4)}px`,
        position: 'relative',
        fontWeight: 500,
        transition: 'color 250ms',

        '& .MuiSvgIcon-root': {
            transform: 'translateY(3px)',
            position: 'absolute',
            left: 0
        },
        '&:hover': {
            color: theme.palette.text.hover
        }
    }
}));

const Breadcrumbs = (props) => {
    const { t } = useTranslation();
    const { path = '', variant } = props;
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <MBreadcrumbs className={classNames(classes.breadcrumb, classes[variant])} aria-label="breadcrumb">
                <Link variant="body2" onClick={() => history.goBack()} className={classes.back} to={''}>
                    <KeyboardBackspace /> {t('breadcrumb.back')}
                </Link>

                {path &&
                    path.map((item, idx) => (
                        <Link className={classes.link} to={item.link ? item.link : ''} key={idx}>
                            {item.title}
                        </Link>
                    ))}
            </MBreadcrumbs>
        </div>
    );
};

export default Breadcrumbs;
