import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '940px',
        margin: `${theme.spacing(8)}px auto`
    },
    heading: {
        width: '100%',
        margin: `${theme.spacing(4)}px 0`
    },
    paragraph: {
        marginBottom: `${theme.spacing(2)}px`
    }
}));

const Cookies = () => {
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Typography className={classes.heading} variant="h2">
                Cookiebeleid
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Wat zijn cookies? FLOW maakt gebruik van cookies en webstatistieken. Hieronder lees je wat cookies zijn,
                waarom FLOW ze gebruikt en welke cookies FLOW precies plaatst. Ook geven we informatie over de
                webstatistieken die wij bijhouden.
            </Typography>
            <Typography className={classes.heading} variant="h4">
                Waarom plaatst FLOW cookies?
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Dat doen we om te kijken hoe bezoekers van onze site de site gebruiken. Deze informatie helpt ons om de
                site te verbeteren. Omdat dit soort cookies zeer weinig impact hebben op jouw privacy, vallen ze onder
                de uitzondering in de cookiewet. Dat betekent dat FLOW geen toestemming hoeft te vragen om deze cookies
                te plaatsen.
            </Typography>
            <Typography className={classes.heading} variant="h4">
                Webstatistieken
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW maakt gebruik van webstatistieken. We analyseren deze gegevens. Hierdoor kunnen we de website nog
                beter op bezoekers afstemmen. We gebruiken de verzamelde gegevens niet voor een ander doel dan voor
                verbetering van de website. Ook stellen we de verzamelde gegevens niet beschikbaar aan derden.
                <br />
                <br />
                Het gaat om onder meer de volgende gegevens:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">
                        de browser die wordt gebruikt (zoals Internet Explorer, Chrome, Edge, Firefox of Safari);
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">het tijdstip en de duur van je bezoek;</Typography>
                </li>
                <li>
                    <Typography variant="body1">welke pagina's bezocht zijn;</Typography>
                </li>
                <li>
                    <Typography variant="body1">zoekopdrachten die bezoekers binnen de website plaatsen.</Typography>
                </li>
            </ul>
            <Typography className={classes.heading} variant="h4">
                Cookies
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Hieronder staan alle cookies die tijdens een bezoek aan flowweb.nl geplaatst kunnen worden.
            </Typography>
            <Typography className={classes.heading} variant="h6">
                Google Analytics - diverse cookies
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Google Analytics is een webanalyse-service die wordt aangeboden door Google Inc. Via deze cookies krijgt
                de klant inzage in het websitebezoek. Denk aan bezoekersaantallen, populaire pagina’s en onderwerpen. Op
                deze manier kan de klant de communicatie beter afstemmen op de behoeften van de websitebezoekers. De
                klant kan niet zien wie (welke pc) zijn website bezoekt. Google kan dit als aanbieder van de dienst wel.
                De cookie wordt bewaard van de sessie tot 2 jaar na sessie.
            </Typography>
        </Grid>
    );
};

export default Cookies;
