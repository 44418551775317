import React from 'react';
import { makeStyles } from '@material-ui/styles';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    '@keyframes move': {
        '0%': {
            transform: 'translateX(0) scale(1)'
        },
        '10%': {
            transform: 'translateX(0) scale(1)'
        },
        '20%': {
            transform: 'translateX(0) scale(0.9)'
        },
        '80%': {
            transform: 'translateX(-30%) scale(0.9)'
        },
        '90%': {
            transform: 'translateX(-30%) scale(0.9)'
        },
        '100%': {
            transform: 'translateX(0) scale(1)'
        }
    },
    tableWrapper: {
        overflow: 'auto',
        position: 'relative'
    },
    start: {
        height: '100%',
        width: '25px',
        position: 'absolute',
        top: 0,
        left: '-1px',
        zIndex: 10,
        opacity: 0,
        transition: 'opacity 300ms',
        pointerEvents: 'none',
        backgroundImage: 'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',

        [theme.breakpoints.up(1268)]: {
            display: 'none'
        }
    },
    started: {
        opacity: 1
    },
    fade: {
        height: '100%',
        width: '100px',
        maxWidth: '100px',
        position: 'absolute',
        top: 0,
        right: '-1px',
        zIndex: 10,
        pointerEvents: 'none',
        transition: 'maxWidth 500ms',
        backgroundImage: 'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',

        [theme.breakpoints.up(1268)]: {
            display: 'none'
        }
    },
    swipe: {
        position: 'absolute',
        top: '50%',
        right: '10px',
        height: '40px',
        width: '40px',
        color: theme.palette.primary.main,
        zIndex: 11,
        transform: 'traslate(-50%, -50%)',
        animation: `$move 1000ms infinite ease`,
        transformOrigin: 'bottom center',
        transition: 'opacity 500ms',

        [theme.breakpoints.up(1268)]: {
            display: 'none'
        }
    },
    smaller: {
        maxWidth: '25px'
    },
    hide: {
        opacity: 0
    }
}));

const SwipeWrapper = (props) => {
    const { children } = props;
    const classes = useStyles();

    const [count, setCount] = React.useState(0);

    const [hide] = React.useState(false);
    const [started, setStarted] = React.useState(false);

    const handleScroll = (e) => {
        if (count <= 20) {
            setCount(count + 1);
        }
        if (e.currentTarget.scrollLeft > 20) {
            setStarted(true);
        } else {
            setStarted(false);
        }
    };

    // if (initSwipe) {
    return (
        <div className={classes.root}>
            <div className={classNames(classes.start, { [classes.started]: started })}></div>
            <div className={classNames(classes.fade, { [classes.smaller]: hide })}>
                <TouchAppIcon className={classNames(classes.swipe, { [classes.hide]: hide })} />
            </div>

            <div className={classes.tableWrapper} onScroll={handleScroll}>
                {children}
            </div>
        </div>
    );
};

export default SwipeWrapper;
