import React from 'react';
import CustomIcon from './CustomIcon';

const ArrowRight = (props) => {
    return (
        <CustomIcon {...props} svgAtts={{ viewBox: '0 0 20 11' }}>
            <path fill="none" d="M-1-1h22v13H-1z" />
            <g>
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M20 5.27L14.34 0l-1.41 1.22 3.24 3.01H0v1.95h16.17l-3.24 3.35L14.34 11 20 5.27z"
                />
            </g>
        </CustomIcon>
    );
};

export default ArrowRight;
