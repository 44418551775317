export const getEnv = (name) => {
    const value = process.env[name];

    if (value === null) {
        return null;
    }
    if (typeof value === 'string') {
        if (value.trim() === 'null') {
            return null;
        }
    }

    return value;
};
