import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Tooltip as MTooltip } from '@material-ui/core';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        tooltipIcon: {
            width: '24px',
            height: '24px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            border: `1px solid ${theme.palette.primary.light}`,
            marginLeft: theme.spacing(1),
            '&::before': {
                content: "'?'",
                fontWeight: 500,
                fontSize: '16px',
                color: theme.palette.primary.main
            }
        },
        tooltip: {
            fontSize: '14px',
            fontWeight: 400,
            color: theme.palette.primary.main,
            background: theme.palette.primary.light
        }
    }),
    { name: 'Tooltip' }
);

const Tooltip = (props) => {
    const { title, className } = props;
    const classes = useStyles();

    return (
        <MTooltip title={title} disableFocusListener classes={{ tooltip: classes.tooltip }}>
            <span className={classNames(classes.tooltipIcon, className)}></span>
        </MTooltip>
    );
};

export default Tooltip;
