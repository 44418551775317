import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { Checkbox, ExpansionPanel } from 'shared';
import { useTranslation } from 'react-i18next';

const Filtercontent = (props) => {
    const { loading = false, onChange = false, filters = {} } = props;
    const { provinces: _provinces } = filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const provinces = Array.isArray(_provinces) ? _provinces : [_provinces];

    const [expanded, setExpanded] = useState(true);
    const [width] = useState(window.innerWidth);
    const { t } = useTranslation();

    useEffect(() => {
        if (!loading && !expanded) {
            setExpanded(!!provinces.length);
        }
    }, [loading, expanded, provinces]);

    return (
        <React.Fragment>
            <ExpansionPanel title="Provincie" expanded={!(width < 962)}>
                <FormGroup>
                    <Checkbox
                        label={t('planMeeting.drenthe')}
                        onChange={onChange}
                        name="drenthe"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'drenthe')}
                    />
                    <Checkbox
                        label={t('planMeeting.flevoland')}
                        onChange={onChange}
                        name="flevoland"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'flevoland')}
                    />
                    <Checkbox
                        label={t('planMeeting.friesland')}
                        onChange={onChange}
                        name="friesland"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'friesland')}
                    />
                    <Checkbox
                        label={t('planMeeting.gelderland')}
                        onChange={onChange}
                        name="gelderland"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'gelderland')}
                    />
                    <Checkbox
                        label={t('planMeeting.groningen')}
                        onChange={onChange}
                        name="groningen"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'groningen')}
                    />
                    <Checkbox
                        label={t('planMeeting.limburg')}
                        onChange={onChange}
                        name="limburg"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'limburg')}
                    />
                    <Checkbox
                        label={t('planMeeting.noordBrabant')}
                        onChange={onChange}
                        name="noord-brabant"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'noord-brabant')}
                    />
                    <Checkbox
                        label={t('planMeeting.noordHolland')}
                        onChange={onChange}
                        name="noord-holland"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'noord-holland')}
                    />
                    <Checkbox
                        label={t('planMeeting.overijssel')}
                        onChange={onChange}
                        name="overijssel"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'overijssel')}
                    />
                    <Checkbox
                        label={t('planMeeting.utrecht')}
                        onChange={onChange}
                        name="utrecht"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'utrecht')}
                    />
                    <Checkbox
                        label={t('planMeeting.zeeland')}
                        onChange={onChange}
                        name="zeeland"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'zeeland')}
                    />
                    <Checkbox
                        label={t('planMeeting.zuidHolland')}
                        onChange={onChange}
                        name="zuid-holland"
                        arrayName="provinces"
                        loading={loading}
                        checked={provinces.some((i) => i === 'zuid-holland')}
                    />
                </FormGroup>
            </ExpansionPanel>
        </React.Fragment>
    );
};

export default Filtercontent;
