import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import OpenMeetings from './OpenMeetings';
import FinishedMeetings from './FinishedMeetings';
import CanceledMeetings from './CanceledMeetings';
import { UPDATE_MEETING } from '../graphql';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(4)
    },
    title: {
        marginBottom: theme.spacing(2.75)
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    tableHeadCell: {
        padding: '1rem 0',
        color: theme.palette.text.secondaryLight,
        fontWeight: 'normal',
        fontSize: '1.125rem',
        '&:first-child': {
            width: '200px',
            minWidth: '200px'
        }
    },
    tableCell: {
        padding: '1.125rem 0',
        fontSize: '1.125rem',
        '&:first-child': {
            width: '200px',
            minWidth: '200px'
        }
    },
    buttonCell: {
        padding: '0.10rem 0',
        [theme.breakpoints.down(1286)]: {
            padding: '1.125rem 0'
        }
    },
    tableCellPrimary: {
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    meetingsHeader: {
        margin: theme.spacing(0, 0, 2)
    },
    button: {
        borderRadius: '35px',
        minHeight: '50px',
        background: theme.palette.background.secondary,
        transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    },
    buttonSecondary: {
        background: theme.palette.background.blue
    },
    editButton: {
        background: 'transparent',
        minWidth: '50px',
        borderRadius: '50%',
        padding: 0,
        border: `1px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.main,
        marginLeft: 'auto',
        '&:hover': {
            color: theme.palette.common.white
        }
    },
    editIcon: {
        width: 16,
        height: 'auto'
    },
    hidden: {
        visibility: 'hidden'
    },
    visible: {
        visibility: 'visible'
    },
    maxWidth: {
        maxWidth: '240px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingRight: '.25rem',
        [theme.breakpoints.down(1286)]: {
            width: '240px',
            minWidth: '240px'
        }
    },
    widerMaxWidth: {
        maxWidth: '260px',
        paddingRight: '.5rem',
        [theme.breakpoints.down(1286)]: {
            width: '260px',
            minWidth: '260px'
        }
    },
    gridEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            flexWrap: 'nowrap'
        }
    },
    meetingDate: {
        minWidth: '125px'
    },
    editable: {},
    table: {
        background: theme.palette.background.paper,
        minWidth: '1000px',

        [theme.breakpoints.up('md')]: {
            minWidth: 0,
            maxWidth: '1200px'
        }
    },
    datePicker: {
        display: 'none'
    }
}));

const Meetings = (props) => {
    const { meetings, handleRefetch } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [openMeetings, setOpenMeetings] = useState([]);
    const [finishedMeetings, setFinishedMeetings] = useState([]);
    const [canceledMeetings, setCanceledMeetings] = useState([]);
    const [mutateMeeting, { loading: mutationLoading, data: updateData }] = useMutation(UPDATE_MEETING);
    const [refetchedAfterUpdate, setRefetchedAfterUpdate] = useState(false);

    // Functions
    const updateMeeting = (meeting) => {
        setRefetchedAfterUpdate(false);

        mutateMeeting({
            variables: {
                input: {
                    id: meeting.id,
                    status: meeting.status,
                    finishedOn: meeting.finishedOn,
                    owner: meeting.ownerId
                }
            }
        });
        enqueueSnackbar({
            variant: 'success',
            message: meeting.message
        });
    };

    const mapMeetingType = (type) => {
        switch (type) {
            case 'ADVISOR_MEETING_ONE':
                return '1e';
            case 'ADVISOR_MEETING_TWO':
                return '2e';
            case 'ADVISOR_MEETING_THREE':
                return '3e';
            default:
                return '';
        }
    };

    // Effects
    useEffect(() => {
        const open = [];
        const finished = [];
        const cancelled = [];

        if (meetings) {
            meetings.forEach((meeting) => {
                if (meeting.status === 'OPEN' || meeting.status === 'PLANNED') {
                    open.push({ ...meeting, mappedType: mapMeetingType(meeting.type) });
                } else if (meeting.status === 'CANCELLED') {
                    cancelled.push({ ...meeting, mappedType: mapMeetingType(meeting.type) });
                }
                // } else if (meeting.status === 'FINISHED') {
                else if (meeting.finishedOn !== null) {
                    finished.push({ ...meeting, mappedType: mapMeetingType(meeting.type) });
                }
            });

            setOpenMeetings(open);
            setFinishedMeetings(finished);
            setCanceledMeetings(cancelled);
        }
    }, [meetings]);

    useEffect(() => {
        if (!mutationLoading && updateData && !refetchedAfterUpdate) {
            handleRefetch();
            setRefetchedAfterUpdate(true);
        }
    }, [updateData, mutationLoading, handleRefetch, refetchedAfterUpdate]);

    return (
        <Grid container spacing={8} className={classes.root}>
            <Grid item xs={12}>
                <OpenMeetings classes={classes} meetings={openMeetings} updateMeeting={updateMeeting} />
            </Grid>
            <Grid item xs={12}>
                <FinishedMeetings classes={classes} meetings={finishedMeetings} updateMeeting={updateMeeting} />
            </Grid>
            <Grid item xs={12}>
                <CanceledMeetings classes={classes} meetings={canceledMeetings} />
            </Grid>
        </Grid>
    );
};

export default Meetings;
