import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Upload as UploadIcon, PdfFile, JpegFile, PngFile, Mp4File, DocFile } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: `${theme.spacing(2)}px`,
        [theme.breakpoints.down(580)]: {
            flexFlow: 'column nowrap',
            alignItems: 'stretch'
        }
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
        opacity: '.5',
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '1.25',
        [theme.breakpoints.down(580)]: {
            margin: theme.spacing(1, 0, 0)
        }
    },
    fileIcon: {
        marginRight: theme.spacing(1),
        color: 'currentColor',
        width: '24px',
        height: '24px'
    },
    fileName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    clearIcon: {
        width: '16px',
        height: '16px',
        marginLeft: theme.spacing(1),
        transition: 'transform 200ms ease',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
            color: theme.palette.primary.main
        }
    }
}));

const FileUpload = (props) => {
    const { t } = useTranslation();
    const {
        label,
        accept,
        acceptLabel,
        maxFileSize = '5 MB',
        onSucces,
        onClear,
        form = false,
        name,
        initialValue = '',
        multi = false,
        noFormChance = false,
        className = {},
        noLabel = false
    } = props;
    const classes = useStyles(props);
    const fileInput = React.useRef();

    // State hooks
    const [amount, setAmount] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialName, setInitialName] = useState(null);

    // reducers
    const [files, setFiles] = useReducer((state, data) => {
        const temp = [];
        if (data.remove) {
            state.forEach((s) => {
                if (s.name !== data.file.name) temp.push(s);
            });
            setAmount(temp.length);
            return [...temp];
        } else if (multi) {
            const temp = [...state];
            temp.push(data);

            return [...temp];
        } else {
            return [data];
        }
    }, []);

    // effect hooks
    useEffect(() => {
        if (files.length === amount) {
            setLoading(false);
            if (multi) onSucces(files);
            else onSucces(files[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    useEffect(() => {
        if (multi && initialValue) {
            setAmount(initialValue.length);
            initialValue.forEach((file) => {
                setFiles(file);
            });
        } else if (initialValue && initialValue.id) {
            if (form) form.onFieldChange({ key: name, value: initialValue.id });
            setInitialName(initialValue.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    // Functions
    const handleClick = () => {
        fileInput.current.click();
    };

    const handleChange = (input) => {
        setLoading(true);
        handleUpload(input.currentTarget);

        if (form && !noFormChance) form.onFieldChange({ key: name, value: input.target.value });
    };

    const clearFile = (file) => () => {
        setFiles({
            remove: true,
            file: file
        });

        if (onClear) onClear(file);
    };

    const clearInitial = (id) => () => {
        if (form) form.onFieldChange({ key: name, value: null });
        setInitialName('');
        if (onClear) onClear(id);
    };

    const handleUpload = (input) => {
        if (input.files && input.files.length) {
            if (multi) {
                setAmount(amount + input.files.length);
                input.files.forEach((file) => {
                    parseFile(file);
                });
            } else {
                setAmount(input.files.length);
                parseFile(input.files[0]);
            }
        }
    };

    const parseFile = (file) => {
        const _fileReader = new FileReader();
        const { size } = file;
        const inputFile = file;
        _fileReader.readAsDataURL(inputFile);

        if (size > parseInt(maxFileSize) * 1048576) {
            _fileReader.abort();
            clearFile();
            alert(`${t('fileUpload.tooLargeError')} ${maxFileSize}`);
            setLoading(false);
        } else {
            _fileReader.onload = (e) => {
                setFiles(inputFile);
            };
        }
    };

    const mimeTypeLabel = [];
    if (accept) {
        const mimeTypes = accept.split(', ');
        mimeTypes.forEach((mimeType) => {
            mimeTypeLabel.push([mimeType.split('/')[1].toUpperCase()]);
        });
    }

    return (
        <div className={classNames(className, classes.root)}>
            <Button
                variant="outlined"
                label={label || t('fileUpload.chooseFile')}
                onClick={() => handleClick()}
                iconLeft={() =>
                    loading ? (
                        <CircularProgress size={24} className={classes.uploadProgress} />
                    ) : (
                        <UploadIcon className={classes.fileIcon} />
                    )
                }
            />
            <input
                type="file"
                ref={fileInput}
                onChange={(el) => handleChange(el)}
                accept={accept}
                name={name}
                hidden
                multiple={multi}
            />
            {!noLabel && (
                <div className={classes.label}>
                    {files.length
                        ? files.map((file) => (
                              <React.Fragment key={file.name}>
                                  {file && (
                                      <React.Fragment>
                                          {file.type && file.type.match(/jpg|jpeg/) && (
                                              <JpegFile className={classes.fileIcon} />
                                          )}
                                          {file.type && file.type.match(/png/) && (
                                              <PngFile className={classes.fileIcon} />
                                          )}
                                          {file.type && file.type.match(/pdf/) && (
                                              <PdfFile className={classes.fileIcon} />
                                          )}
                                          {file.type && file.type.match(/mp4/) && (
                                              <Mp4File className={classes.fileIcon} />
                                          )}
                                          {file.type && file.type.match(/(doc|word)/) && (
                                              <DocFile className={classes.fileIcon} />
                                          )}
                                          <span className={classes.fileName}>{file.name}</span>
                                          <ClearIcon onClick={clearFile(file)} className={classes.clearIcon} />
                                      </React.Fragment>
                                  )}
                              </React.Fragment>
                          ))
                        : initialName && (
                              <React.Fragment>
                                  {initialName || ''}
                                  <ClearIcon onClick={clearInitial(initialValue.id)} className={classes.clearIcon} />
                              </React.Fragment>
                          )}

                    {!files.length && !initialValue && (
                        <React.Fragment>
                            {acceptLabel && acceptLabel}
                            {!acceptLabel && mimeTypeLabel && mimeTypeLabel.join(', ')}
                            {maxFileSize && ` (max ${maxFileSize})`}
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
};

export default FileUpload;
