import React from 'react';
import { Link } from 'react-router-dom';

const LinkComponent = React.forwardRef((props, ref) => {
    const { children, to, disabled, ...otherProps } = props;

    if (!to || disabled)
        return (
            <div ref={ref} {...otherProps}>
                {children}
            </div>
        );
    if (/^https?:\/\//.test(to))
        return (
            <a ref={ref} href={to} {...otherProps}>
                {children}
            </a>
        );

    return (
        <Link to={to} ref={ref} {...otherProps}>
            {children}
        </Link>
    );
});

export default LinkComponent;
