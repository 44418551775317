import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    TableCell,
    Delete,
    DocFile,
    Download,
    PdfFile,
    PngFile,
    JpegFile,
    CsvFile,
    UnknownFile,
    ZipFile,
    RtfFile,
    PowerPoint,
    Excel
} from 'shared';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    name: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        lineHeight: '1.25',
        maxWidth: '480px',
        textDecoration: 'none',

        [theme.breakpoints.up('md')]: {
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    icon: {
        border: `1px solid ${theme.palette.borderColor.main}`,
        width: '40px',
        borderRadius: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'color 200ms, border-color 200ms',
        color: theme.palette.text.secondary,

        [theme.breakpoints.up('md')]: {
            '&:hover': {
                color: theme.palette.text.hover,
                borderColor: theme.palette.text.hover
            }
        },
        '& svg': {
            fontSize: '1.1rem'
        }
    },
    deleteIcon: {
        marginRight: `${theme.spacing(1.25)}px`,

        [theme.breakpoints.up('md')]: {
            '&:hover': {
                color: theme.palette.background.red,
                borderColor: theme.palette.background.red
            }
        }
    },
    typeIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: `${theme.spacing(2.75)}px`
    },
    typeColor1: {
        color: theme.palette.icon.pdfIconColor
    },
    typeColor2: {
        color: theme.palette.icon.docIconColor
    },
    typeColor3: {
        color: theme.palette.icon.sheetIconColor
    },
    typeColor4: {
        color: theme.palette.icon.imageIconColor
    }
}));

const Row = (props) => {
    const { row, allowedToDelete = false, vouchers, currentFolder } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const formatDate = (string) => {
        const date = new Date(string);
        return date.toLocaleDateString();
    };

    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const handleClick = (data, value) => {
        props.onClick(data, value);
    };

    const fileType = (url) => {
        const type = url.substring(url.lastIndexOf('.'));

        switch (true) {
            case /pdf/.test(type):
                return <PdfFile color="inherit" className={classNames(classes.typeIcon, classes.typeColor1)} />;
            case /png/.test(type):
                return <PngFile color="inherit" className={classNames(classes.typeIcon)} />;
            case /doc/.test(type):
                return <DocFile color="inherit" className={classNames(classes.typeIcon, classes.typeColor2)} />;
            case /jpeg|jpg/.test(type):
                return <JpegFile color="inherit" className={classNames(classes.typeIcon, classes.typeColor4)} />;
            case /csv/.test(type):
                return <CsvFile color="inherit" className={classNames(classes.typeIcon, classes.typeColor3)} />;
            case /zip/.test(type):
                return <ZipFile color="inherit" className={classNames(classes.typeIcon)} />;
            case /ppt/.test(type):
                return <PowerPoint color="inherit" className={classNames(classes.typeIcon, classes.typeColor1)} />;
            case /xls/.test(type):
                return <Excel color="inherit" className={classNames(classes.typeIcon, classes.typeColor3)} />;
            case /rtf/.test(type):
                return <RtfFile color="inherit" className={classNames(classes.typeIcon)} />;
            default:
                return <UnknownFile color="inherit" className={classes.typeIcon} />;
        }
    };

    return (
        <React.Fragment>
            <TableCell>
                <a
                    className={classNames(classes.text, classes.name)}
                    href={row.url}
                    download={row.name}
                    title={t('documents.download')}
                >
                    {fileType(row.url)}
                    {currentFolder && currentFolder.name === 'Vouchers' && vouchers
                        ? vouchers.map((voucher) => {
                              if (voucher.name === row.name && voucher.voucherType === 'MEETING1') {
                                  return 'Voucher 1e gesprek';
                              } else if (voucher.name === row.name && voucher.voucherType === 'MEETING2') {
                                  return 'Voucher 2e gesprek';
                              } else if (voucher.name === row.name && voucher.voucherType === 'MEETING3') {
                                  return 'Voucher 3e gesprek';
                              } else {
                                  return false;
                              }
                          })
                        : row.name}
                </a>
            </TableCell>
            <TableCell>
                <Typography className={classes.text}>{formatDate(row.createdOn)}</Typography>
            </TableCell>
            <TableCell>
                {row.size ? <Typography className={classes.text}>{formatBytes(row.size)}</Typography> : '-'}
            </TableCell>
            <TableCell>
                <div className={classes.buttons}>
                    {allowedToDelete && (
                        <span
                            className={classNames(classes.icon, classes.deleteIcon)}
                            onClick={() => handleClick(row.id, 'delete')}
                        >
                            <Delete color="inherit" />
                        </span>
                    )}
                    <a className={classes.icon} href={row.url} download={row.name}>
                        <Download color="inherit" />
                    </a>
                </div>
            </TableCell>
        </React.Fragment>
    );
};

export default Row;
