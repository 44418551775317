import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import renderHTML from 'react-render-html';

import { Typography, Grid, TableRow, TableCell } from '@material-ui/core';
import { Done as DoneIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { ExpansionPanel, ArrowRight, Link } from 'shared';

import { useCurrentUser } from 'hooks';
import { useStyles } from './styles';

const Roadmap = (props) => {
    const { userMeetings, userMeetingsDone, careerAdvisor, skillTest, hasPlannedMeeting, userData } = useCurrentUser();
    const classes = useStyles();
    const { t } = useTranslation();
    const hasAdvisor = careerAdvisor && Object.keys(careerAdvisor).length > 0;
    const getDescription = (idx, status, testDone) => {
        const desc = {
            0: `Plan je eerste gesprek met de door jou gekozen loopbaanadviseur. ${
                !testDone ? 'Je kunt eventueel van tevoren de Loopbaanwijzer-test doen.' : ''
            }`,
            1: `Plan je tweede gesprek met de door jou gekozen loopbaanadviseur. ${
                !testDone ? 'Je kunt eventueel van tevoren de Loopbaanwijzer-test doen.' : ''
            }`,
            2: `Plan je derde gesprek met de door jou gekozen loopbaanadviseur. ${
                !testDone ? 'Je kunt eventueel van tevoren de Loopbaanwijzer-test doen.' : ''
            }`
        };

        const closeDesc = {
            0: `Je hebt je eerste gesprek gepland. ${
                !testDone ? 'Je kunt eventueel van tevoren de Loopbaanwijzer-test doen.' : ''
            } Als je je eerste gesprek hebt gehad, kun je je tweede gesprek plannen.`,
            1: `Je hebt je tweede gesprek gepland. ${
                !testDone ? 'Je kunt eventueel van tevoren de Loopbaanwijzer-test doen.' : ''
            } Als je je tweede gesprek hebt gehad, kun je je derde gesprek plannen.`,
            2: `Je hebt je derde gesprek gepland. ${
                !testDone ? 'Je kunt eventueel van tevoren de Loopbaanwijzer-test doen.' : ''
            } Als je dit gesprek hebt gehad, heb je alle gesprekken gevoerd!`
        };

        return status === 'close' || status === 'done' || status === 'planned' ? closeDesc[idx] : desc[idx];
    };

    return (
        <div>
            <Grid container className={classes.careerGuideGrid}>
                {/* Loopbaanwijzer test block */}
                <Grid className={classes.careerGuideBlock} item md={6} xs={12}>
                    <Typography variant="h4" className={classes.title}>
                        Loopbaanwijzer-test
                        <div className={classes.time}>
                            <div className={classes.timeNumber}>60</div>
                            <div>MIN</div>
                        </div>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>
                        De Loopbaanwijzer-test bestaat uit vijf onderdelen. Na afloop krijg je de uitkomsten te zien in
                        je persoonlijke rapport. Na twee onderdelen ontvang je een tussenrapport.
                    </Typography>

                    {/* Loopbaanwijzer test panel */}
                    <ExpansionPanel
                        title={
                            <React.Fragment>
                                <span className={classes.status}>{skillTest.finished && <DoneIcon />}</span>
                                Loopbaanwijzer-test
                            </React.Fragment>
                        }
                        variant="large"
                        expanded={!skillTest.finished}
                        icon={<ExpandMoreIcon className={classes.panelMoreIcon} />}
                        classes={{
                            root: classNames(classes.panelRoot, {
                                [classes.finished]: skillTest.finished
                            }),
                            content: classes.panelContent,
                            summaryFont: classes.panelTitle,
                            expandIcon: classes.expandIcon,
                            expanded: classes.panelContentExpanded,
                            expansionPanelSummaryRoot: classes.expansionPanelSummaryRoot,
                            expansionDetails: classes.expansionDetails
                        }}
                    >
                        <p>
                            {skillTest.finished
                                ? 'Bekijk je rapport van de Loopbaanwijzer-test.'
                                : 'Krijg meer inzicht in wie je bent, waar je interesses liggen en wat bij jouw persoonlijkheid past. Ontdek waar je goed in bent, wat je talenten zijn en waar je kansen liggen.'}

                            {!hasPlannedMeeting && ' Heb je trouwens al een loopbaangesprek ingepland? '}
                        </p>

                        <Link
                            className={classNames(classes.stepLink)}
                            to={skillTest.finished ? '/mijn-documenten' : skillTest.testUrl}
                        >
                            <ArrowRight />
                            {skillTest.finished ? 'Download rapport' : 'Start de test'}
                        </Link>
                    </ExpansionPanel>

                    {/* Test afgerond panel */}
                    <ExpansionPanel
                        title={
                            <React.Fragment>
                                <span className={classes.status}>{skillTest.finished && <DoneIcon />}</span>
                                Je hebt de test afgerond
                            </React.Fragment>
                        }
                        variant="large"
                        classes={{
                            root: classNames(classes.panelRoot, classes.finishedPanel, {
                                [classes.finished]: skillTest.finished
                            }),
                            content: classes.panelContent,
                            summaryFont: classes.panelTitle,
                            expandIcon: classes.expandIcon,
                            expanded: classes.panelContentExpanded,
                            expansionPanelSummaryRoot: classes.expansionPanelSummaryRoot,
                            expansionDetails: classes.expansionDetails
                        }}
                    ></ExpansionPanel>

                    {userData && userData.skillTestArchivedCount > -1 && (
                        <Typography variant="subtitle1" className={classes.subTitle}>
                            <br />
                            Heb je eerder de Loopbaanwijzer-test gedaan? Het rapport daarvan kun je vinden in Mijn
                            documenten.
                            <Link className={classNames(classes.stepLink)} to={'/mijn-documenten'}>
                                <ArrowRight />
                                {'Naar Mijn documenten'}
                            </Link>
                        </Typography>
                    )}
                </Grid>

                {/* Loopbaangesprekken blok */}
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classNames(classes.careerGuideBlock, classes.careerGuideBlockRight)}
                >
                    <Typography variant="h4" className={classes.title}>
                        Loopbaangesprekken
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>
                        Je hebt recht op drie gratis gesprekken (fysiek of online) met een van onze loopbaanadviseurs.
                        Je kunt eerst de Loopbaanwijzer-test doen, maar dat hoeft niet.
                    </Typography>

                    {/* Advisor block */}
                    <ExpansionPanel
                        title={
                            <React.Fragment>
                                <span className={classes.status}>{hasAdvisor && <DoneIcon />}</span>
                                {t('careerGuide.advisor')}
                            </React.Fragment>
                        }
                        variant="large"
                        expanded={!hasAdvisor}
                        icon={<ExpandMoreIcon className={classes.panelMoreIcon} />}
                        classes={{
                            root: classNames(classes.panelRoot, {
                                [classes.finished]: hasAdvisor
                            }),
                            content: classes.panelContent,
                            summaryFont: classes.panelTitle,
                            expandIcon: classes.expandIcon,
                            expanded: classes.panelContentExpanded,
                            expansionPanelSummaryRoot: classes.expansionPanelSummaryRoot,
                            expansionDetails: classes.expansionDetails
                        }}
                    >
                        <p>
                            {hasAdvisor
                                ? 'Je hebt je loopbaanadviseur gekozen.'
                                : 'Kies jouw loopbaanadviseur. Je kunt je keuze later altijd aanpassen.'}
                        </p>
                        <Link className={classes.stepLink} to={'/mijn-loopbaanwijzer/loopbaanadviseur-kiezen'}>
                            <ArrowRight />
                            {hasAdvisor ? 'Wijzig jouw adviseur' : 'Kies jouw adviseur'}
                        </Link>
                    </ExpansionPanel>

                    {/* Loop over meeting in current user */}
                    {Object.keys(userMeetings).map((meet, idx) => {
                        const meeting = userMeetings[meet];

                        return (
                            <ExpansionPanel
                                key={idx}
                                title={
                                    <React.Fragment>
                                        <span className={classes.status}>
                                            {(meeting.status === 'done' || meeting.status === 'planned') && (
                                                <DoneIcon />
                                            )}
                                        </span>
                                        {meeting.title}
                                    </React.Fragment>
                                }
                                variant="large"
                                expanded={meeting.status === 'open' && hasAdvisor}
                                icon={<ExpandMoreIcon className={classes.panelMoreIcon} />}
                                classes={{
                                    root: classNames(classes.panelRoot, {
                                        [classes.finished]: meeting.status === 'done' || meeting.status === 'planned'
                                    }),
                                    content: classes.panelContent,
                                    summaryFont: classes.panelTitle,
                                    expandIcon: classes.expandIcon,
                                    expanded: classes.panelContentExpanded,
                                    expansionPanelSummaryRoot: classes.expansionPanelSummaryRoot,
                                    expansionDetails: classes.expansionDetails
                                }}
                            >
                                <p>{renderHTML(getDescription(idx, meeting.status, skillTest.finished))}</p>
                                <Link
                                    className={classNames(classes.stepLink, {
                                        [classes.disabledLink]:
                                            meeting.status === 'done' || meeting.status === 'planned'
                                    })}
                                    disabled={meeting.status === 'done' || meeting.status === 'planned'}
                                    to="/mijn-loopbaanwijzer/gesprek-plannen"
                                >
                                    <ArrowRight />
                                    {meeting.status === 'done' || meeting.status === 'planned'
                                        ? meeting.linkDoneTitle
                                        : meeting.linkTitle}
                                </Link>
                            </ExpansionPanel>
                        );
                    })}
                    {/* All meetings done block */}
                    {/* Advisor block */}
                    <ExpansionPanel
                        title={
                            <React.Fragment>
                                <span className={classes.status}>{userMeetingsDone && <DoneIcon />}</span>
                                Je hebt alle gesprekken gepland
                            </React.Fragment>
                        }
                        variant="large"
                        noExpand={true}
                        icon={false}
                        classes={{
                            root: classNames(classes.panelRoot, {
                                [classes.finished]: userMeetingsDone
                            }),
                            content: classes.panelContent,
                            summaryFont: classes.panelTitle,
                            expandIcon: classes.expandIcon,
                            expanded: classes.panelContentExpanded,
                            expansionPanelSummaryRoot: classes.expansionPanelSummaryRoot,
                            expansionDetails: classes.expansionDetails
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Roadmap;
