import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';

import { ThemeProvider, makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import store from 'store';
import Theme from 'styling';
import { client } from 'api';

import Bootstrap from 'components/Bootstrap';
import Login from 'components/Login';
import PrivateRoute from 'components/PrivateRoute';
import Redirect from 'components/Redirect';
import QuickScanResults from 'components/QuickScanResults';
import Register, { PasswordChange, ForgotPassword } from 'components/Register';
import { EmailSucces, EmailError, ValidateEmail, EmailValidationSucces, GTMLayer, Snackbar } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: 'hidden',
        position: 'relative'
    }
}));

const Root = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Provider store={store}>
                <ApolloProvider client={client}>
                    <ThemeProvider theme={Theme}>
                        <CssBaseline />
                        <SnackbarProvider
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            content={(key, object) => (
                                <Snackbar id={key} message={object.message} variant={object.variant} />
                            )}
                        >
                            <Router>
                                <GTMLayer>
                                    <Switch>
                                        <Route exact path="/quick-scan/doen/:type?" component={Redirect} />
                                        <Route exact path="/quick-scan/resultaten/:id" component={QuickScanResults} />
                                        {/* register routes */}
                                        <Route path="/register/forgot-password" exact component={ForgotPassword} />
                                        <Route path="/register/password" exact component={PasswordChange} />
                                        <Route path="/register/password/:id" component={PasswordChange} />
                                        <Route path="/register" exact component={() => <Register />} />
                                        <Route path="/login" component={Login} />
                                        <Route path="/logout" component={Login} />
                                        {/* email wijzigen routes */}
                                        <Route exact path="/email-wijzigen/succes" component={EmailSucces} />
                                        <Route exact path="/email-wijzigen/er-ging-iets-mis" component={EmailError} />
                                        <Route
                                            exact
                                            path="/email-wijzigen/validatie-succes"
                                            component={EmailValidationSucces}
                                        />
                                        <Route exact path="/change/email/:token" component={ValidateEmail} />

                                        {/* private routes */}
                                        <PrivateRoute path="/" component={Bootstrap} />
                                    </Switch>
                                </GTMLayer>
                            </Router>
                        </SnackbarProvider>
                    </ThemeProvider>
                </ApolloProvider>
            </Provider>
        </div>
    );
};

export default Root;
