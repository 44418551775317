import React, { useState, useRef, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'shared';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        [theme.breakpoints.down(580)]: {
            flexFlow: 'column nowrap'
        }
    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: theme.spacing(4.5),
        width: '150px',
        height: '150px',
        backgroundColor: theme.palette.background.primary,
        [theme.breakpoints.down(580)]: {
            margin: `0 auto ${theme.spacing(2)}px`
        }
    },
    imagePreview: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        transition: 'opacity 300ms ease, transform 300ms ease',
        backfaceVisibility: 'hidden',
        '&:hover': {
            transform: 'scale(1.05)'
        }
    },
    loading: {
        opacity: '.1'
    },
    image: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    input: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer'
    },
    inputPlaceholder: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: theme.spacing(4)
    },
    uploadProgress: {
        pointerEvents: 'none',
        position: 'relative',
        zIndex: 1
    },
    uploadButtons: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flex: '1 0 auto',
        [theme.breakpoints.down(580)]: {
            alignItems: 'stretch'
        }
    },
    button: {
        '&:nth-child(n+2)': {
            marginTop: theme.spacing(2)
        }
    }
}));

const ImageUpload = (props) => {
    const { placeholder, name, form = false, initialValue = {} } = props;
    const classes = useStyles(props);
    const _fileReader = new FileReader();
    const mimeTypes = ['image/jpeg, image/png'];
    const maxFileSize = 8388608;
    const fileInput = useRef();

    const [image, setImage] = useState();
    const [realImage, setRealImage] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setImage(placeholder);
    }, [placeholder]);

    useEffect(() => {
        if (initialValue.url) {
            setImage(initialValue.url);
        }
    }, [initialValue]);

    useEffect(() => {
        if (realImage || realImage === null) {
            if (form) form.onFieldChange({ key: name, value: realImage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realImage]);

    const openFileSelect = () => {
        fileInput.current.click();
    };

    const handleChange = (input) => {
        setLoading(true);
        readImage(input.target);
    };

    const clearImage = () => {
        setImage();
        fileInput.current.value = '';
        setRealImage(null);
    };

    const readImage = (input) => {
        if (input.files && input.files[0]) {
            const fileSize = input.files[0].size;
            if (fileSize > maxFileSize) {
                setLoading(false);
                clearImage();
                _fileReader.abort();
                alert('Het bestand mag niet groter zijn dan 8MB');
            } else {
                _fileReader.onload = function (e) {
                    setTimeout(() => {
                        setImage(e.target.result);
                        setLoading(false);
                    }, 500);
                };
                _fileReader.readAsDataURL(input.files[0]);
                setRealImage(input.files[0]);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.imageWrapper}>
                <div className={classNames(classes.imagePreview, { [classes.loading]: loading })}>
                    {image ? (
                        <img src={image} alt="" className={classes.image} />
                    ) : (
                        <PersonIcon className={classes.inputPlaceholder} />
                    )}
                    <input
                        type="file"
                        onChange={handleChange}
                        accept={mimeTypes.join(', ')}
                        className={classes.input}
                        ref={fileInput}
                    />
                </div>
                {loading && <CircularProgress className={classes.uploadProgress} />}
            </div>
            <div className={classes.uploadButtons}>
                <Button
                    variant={'outlined'}
                    label={'Wijzigen'}
                    onClick={openFileSelect}
                    className={classes.button}
                    iconRight={() => <CreateIcon />}
                />
                <Button
                    variant={'outlined'}
                    label={'Verwijderen'}
                    onClick={clearImage}
                    className={classes.button}
                    iconRight={() => <DeleteOutlineIcon />}
                />
            </div>
        </div>
    );
};

export default ImageUpload;
