import React, { useEffect, useState } from 'react';
import background1 from 'assets/images/background-1.svg';
import background2 from 'assets/images/background-2.svg';
import { Loader, Dialog, MyFlowContainer } from 'shared';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { getInfoBlock } from './steps';
import Roadmap from './Roadmap';
import { useCurrentUser } from 'hooks';
import Moment from 'moment';
import ChatBot from '../ChatBot';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat`,

            [theme.breakpoints.up('md')]: {
                background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat,
                url(${background1}) bottom right / 250px no-repeat`
            }
        },
        loading: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }),
    { name: 'careerGuide' }
);

const CareerGuide = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { loading, userMeetings, careerAdvisor, careerSteps, userData } = useCurrentUser();

    const [dialogState, setDialog] = useState({
        open: false,
        content: ''
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClose = () => {
        setDialog({ ...dialogState, open: false });
    };

    if (loading) {
        return (
            <div className={classes.loading}>
                <Loader />;
            </div>
        );
    }

    let skillTestTekst =
        userData &&
        userData.skillTestResetOn &&
        ' Jouw driejaarsperiode loopt af op ' + Moment(userData.skillTestResetOn).format('D-MM-YYYY') + '. ';
    skillTestTekst = '';
    const introText =
        'Op deze pagina kun je naar de Loopbaanwijzer-test en loopbaangesprekken. Je kunt zowel met een test als een gesprek beginnen.' +
        '<br><br>Volgens de cao Woondiensten mag je de Loopbaanwijzer eens in de drie jaar (gratis) doen. ' +
        skillTestTekst +
        'Daarna kun je alles opnieuw doen, dus zowel het invullen van de test als het voeren van de gesprekken. ' +
        '<br><br>Vragen? Ga naar je <a href="/" style="color:#6E3286">Dashboard</a> en klik op Hulp nodig. ' +
        'Of stuur een mail naar <a href="mailto:support@flowweb.nl" style="color:#6E3286">support@flowweb.nl</a>.';

    return (
        <div className={classes.root}>
            <MyFlowContainer
                title={t('careerGuide.title')}
                introText={introText}
                renderInfoBox={() => getInfoBlock(t, careerAdvisor || false)}
            >
                <Roadmap steps={careerSteps} meetings={userMeetings} />
            </MyFlowContainer>
            <ChatBot />
            <Dialog {...dialogState} onClose={handleClose} />
        </div>
    );
};

export default CareerGuide;
