import React from 'react';
import CustomIcon from './CustomIcon';

const SmileyGood = (props) => {
    return (
        <CustomIcon width="28" height="28" {...props}>
            <path
                d="M14 1c7.168 0 13 5.832 13 13s-5.832 13-13 13S1 21.168 1 14 6.832 1 14 1zm0 1.13C7.455 2.13 2.13 7.455 2.13 14c0 6.545 5.325 11.87 11.87 11.87 6.545 0 11.87-5.325 11.87-11.87 0-6.545-5.325-11.87-11.87-11.87zm4.54 15.037a.59.59 0 01.777-.04.47.47 0 01.043.707C18.005 19.21 16.05 20 14 20c-2.05 0-4.004-.79-5.36-2.166a.469.469 0 01.043-.706.59.59 0 01.777.039C10.607 18.332 12.262 19 14 19c1.739 0 3.393-.668 4.54-1.833zM18.5 10c1.378 0 2.5 1.121 2.5 2.5a.5.5 0 01-1 0c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5a.5.5 0 01-1 0c0-1.379 1.122-2.5 2.5-2.5zm-9 0c1.379 0 2.5 1.121 2.5 2.5a.5.5 0 01-1 0c0-.827-.673-1.5-1.5-1.5S8 11.673 8 12.5a.5.5 0 01-1 0C7 11.121 8.122 10 9.5 10z"
                fill="#B3D34F"
                fillRule="nonzero"
                stroke="#B3D34F"
                strokeWidth=".5"
            />
        </CustomIcon>
    );
};

export default SmileyGood;
