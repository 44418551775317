import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Mail } from '@material-ui/icons';
import { Linkedin, Facebook, Button } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',

        '& .MuiButton-root': {
            padding: '0!important'
        }
    },
    title: {
        fontWeight: 500,
        color: theme.palette.text.secondary,
        marginRight: `${theme.spacing(3)}px`
    },
    button: {
        height: '51px',
        width: '51px',
        lineHeight: '51px',
        minWidth: '51px',
        borderRadius: '26px',
        padding: 0,

        '& .MuiSvgIcon-root': {
            width: '23px',
            height: '20px',
            color: theme.palette.text.secondary
        },

        '&:hover': {
            background: theme.palette.text.secondary,
            borderColor: theme.palette.text.secondary,
            '& .MuiSvgIcon-root': {
                color: '#fff'
            }
        }
    }
}));

const ShareButtons = (props) => {
    const { title = 'Flow', description = '' } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const currUrl = window.location.href;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${currUrl}`;
    const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${currUrl}&title=${title}&summary=${''}&source=LinkedIn`;
    const mailUrl = `mailto:?&subject=${title}&body=${description} ${currUrl}`;

    return (
        <Grid className={classes.root}>
            <Typography variant="body1" className={classes.title}>
                {t('shareButtons.share')}
            </Typography>
            <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
                href={mailUrl}
                iconRight={() => <Mail />}
            />
            <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
                href={linkedinUrl}
                iconRight={() => <Linkedin />}
            />
            <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
                href={facebookUrl}
                iconRight={() => <Facebook />}
            />
        </Grid>
    );
};

export default ShareButtons;
