// User roles from the database
export const ROLE_NAMES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    CAREER_ADVISOR: 'CAREER_ADVISOR',
    DI_ADVISOR: 'DI_ADVISOR',
    PROJECT_LEADER: 'PROJECT_LEADER',
    USER: 'USER',
    DI_SPP: 'DI_SPP'
};

export const ROLES = {
    SUPER_ADMIN: {
        id: 1,
        name: ROLE_NAMES.SUPER_ADMIN
    },
    ADMIN: {
        id: 2,
        name: ROLE_NAMES.ADMIN
    },
    CAREER_ADVISOR: {
        id: 3,
        name: ROLE_NAMES.CAREER_ADVISOR
    },
    DI_ADVISOR: {
        id: 4,
        name: ROLE_NAMES.DI_ADVISOR
    },
    PROJECT_LEADER: {
        id: 5,
        name: ROLE_NAMES.PROJECT_LEADER
    },
    USER: {
        id: 6,
        name: ROLE_NAMES.USER
    },

    HR_USER: {
        id: 7,
        name: ROLE_NAMES.HR_USER
    },

    DI_SPP: {
        id: 8,
        name: ROLE_NAMES.DI_SPP
    }
};
