import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from 'shared';

const AppFooter = () => {
    const { t } = useTranslation();

    const footerLinks = useMemo(
        () => [
            {
                label: t('footer.cookieLink'),
                path: `${process.env.REACT_APP_FLOW_WEB_DOMAIN}/cookiebeleid`,
                external: true
            },
            {
                label: t('footer.privacyLink'),
                path: `${process.env.REACT_APP_FLOW_WEB_DOMAIN}/privacystatement`,
                external: true
            },
            {
                label: t('footer.disclaimerLink'),
                path: `${process.env.REACT_APP_FLOW_WEB_DOMAIN}/disclaimer`,
                external: true
            }
        ],
        [t]
    );

    return <Footer links={footerLinks} />;
};

export default AppFooter;
