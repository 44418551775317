import React, { useState, useEffect } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import classNames from 'classnames';
import { Typography, Box } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    error: {
        color: 'rgba(217, 40, 28, 1)'
    },
    root: {
        paddingTop: `${theme.spacing(2)}px`,
        '& #mui-rte-toolbar': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingBottom: `${theme.spacing(1)}px`,

            '& .MuiIconButton-root': {
                borderRadius: '50%',
                padding: `${theme.spacing(0.5)}px`,
                border: `1px solid ${theme.palette.borderColor.secondary}`,
                marginLeft: `${theme.spacing(0.625)}px`,
                width: '31px',
                height: '31px',
                '& .MuiSvgIcon-root': {
                    width: '18px',
                    height: '18px'
                },
                '& .MuiIconButton-label': {
                    width: '18px',
                    height: '18px'
                }
            },
            '& .MuiIconButton-colorPrimary': {
                borderColor: theme.palette.primary.main,
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText
            }
        },
        '& #mui-rte-editor': {
            color: theme.palette.text.primary,
            fontFamily: 'Galanogrotesque',
            border: `1px solid ${theme.palette.borderColor.secondary}`,
            minHeight: '375px',
            borderRadius: '5px',
            padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
            fontSize: '16px',

            '& p, & ul, & ol': {
                fontSize: '16px',
                lineHeight: '22px',
                fontWeight: 'normal',
                paddingBottom: `${theme.spacing(4)}px`
            },
            '& a': {
                color: theme.palette.primary.main,
                transition: 'color 300ms',
                '&:hover': {
                    color: theme.palette.text.hover
                }
            },
            '& ul, & ol': {
                paddingLeft: `${theme.spacing(2.25)}px`,
                paddingBottom: `${theme.spacing(0)}px`,

                '& li': {
                    paddingLeft: `${theme.spacing(1.25)}px`
                }
            },
            '& ul': {
                listStyle: 'none',
                marginTop: 0,
                '& li': {
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: '-15px',
                        top: '10px',
                        content: '""',
                        height: '5px',
                        width: '5px',
                        background: theme.palette.primary.main,
                        borderRadius: '50%',
                        display: 'inline-block'
                    }
                }
            },
            '& ol': {
                listStyle: 'none',
                counterReset: 'li',
                '& li': {
                    counterIncrement: 'li',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: '-15px',
                        content: 'counter(li)',
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                        display: 'inline-block',
                        width: '1em'
                    }
                }
            },
            '& h2': {
                margin: 0,
                paddingBottom: `${theme.spacing(3)}px`,
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 500
            }
        }
    },
    title: {
        fontSize: '18px'
    },
    box: {},
    editor: {}
}));

const InputField = (props) => {
    const {
        className,
        title,
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        onChange,
        form = false,
        name,
        initialValue = '',
        subtitle
    } = props;
    const classes = useStyles(props);

    const [error, setError] = useState(errorFromProps);
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setError(errorFromProps);
        setErrorMessage(errorMessageFromProps);
    }, [errorFromProps, errorMessageFromProps]);

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: initialValue });
        setValue(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const handleChange = (prop) => (event) => {
        // Use convertToRaw to save the editor state including inline styles, blocks, etc.
        const content = JSON.stringify(convertToRaw(event.getCurrentContent()));
        // https://draftjs.org/docs/api-reference-content-state#getplaintext
        // const content = event.getCurrentContent().getPlainText()
        if (form) form.onFieldChange({ key: name, value: content, smooth: true });

        if (onChange) {
            onChange(content);
        }
    };

    return (
        <div className={classes.root}>
            <Box mb={5} className={classes.box}>
                <Typography
                    variant="h6"
                    className={classNames(classes.title, classes[className], {
                        [classes.error]: error
                    })}
                >
                    {title}
                </Typography>
                {subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </Box>
            <MUIRichTextEditor
                classes={{ root: classes.editor }}
                controls={['numberList', 'bulletList', 'bold', 'italic', 'underline', 'link', 'clear']}
                onChange={handleChange()}
                value={value}
            />
            {error && <FormHelperText error={true}>{errorMessage || 'Er ging iets fout'}</FormHelperText>}
        </div>
    );
};

export default InputField;
