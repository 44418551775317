import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './locales/i18n';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';

import Root from './components/Root';
import { getEnv } from './helpers';

const sentryDsn = getEnv('REACT_APP_SENTRY_DSN');

if (typeof sentryDsn === 'string' && sentryDsn.trim().length > 0) {
    Sentry.init({
        dsn: sentryDsn,
        environment: getEnv('REACT_APP_SENTRY_ENVIRONMENT')
    });
}

const tagManagerArgs = {
    gtmId: 'GTM-M592NXH',
    dataLayerName: 'PageDataLayer'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<Root />, document.getElementById('root'));
