import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ArrowRight, PersonBlock } from 'shared';

export const getButtons = (step, t, steps) => {
    let buttons = [];
    let hasAdvisor = false;
    steps.forEach((item) => {
        item.subSteps &&
            item.subSteps.forEach((i) => {
                if (i.type === 'CHOOSE_ADVISOR') hasAdvisor = i.finished || false;
            });
    });

    switch (step) {
        case 1:
            if (hasAdvisor) {
                buttons = [
                    {
                        id: 1,
                        action: 'PLAN_START',
                        color: 'primary',
                        text: t('careerGuide.planStart'),
                        to: '/mijn-loopbaanwijzer/gesprek-plannen'
                    },
                    {
                        id: 2,
                        color: 'secondary',
                        text: t('careerGuide.takeInterestTest'),
                        to: steps[0].subSteps[1].testUrl,
                        disabled: steps[0].subSteps[1].finished
                    }
                ];
            } else {
                buttons = [
                    {
                        id: 1,
                        color: 'primary',
                        text: t('careerGuide.chooseAdvisor'),
                        to: '/mijn-loopbaanwijzer/loopbaanadviseur-kiezen'
                    },
                    {
                        id: 2,
                        color: 'secondary',
                        text: t('careerGuide.takeInterestTest'),
                        to: steps[0].subSteps[1].testUrl,
                        disabled: steps[0].subSteps[1].finished
                    }
                ];
            }
            break;
        case 2:
            buttons = [
                {
                    id: 1,
                    action: 'PLAN_START',
                    color: 'primary',
                    text: t('careerGuide.planStart'),
                    to: '/mijn-loopbaanwijzer/gesprek-plannen'
                },
                {
                    id: 2,
                    color: 'secondary',
                    text: t('careerGuide.takeCapacityTest'),
                    to: steps[1].subSteps[1].testUrl,
                    disabled: steps[1].subSteps[1].finished
                }
            ];
            break;
        case 3:
            buttons = [
                {
                    id: 1,
                    color: 'primary',
                    text: t('careerGuide.downloadReports'),
                    to: '/mijn-documenten'
                },
                {
                    id: 2,
                    color: 'secondary',
                    text: t('careerGuide.planConversation'),
                    to: '/mijn-loopbaanwijzer/gesprek-plannen'
                }
            ];
            break;
        case 4:
            buttons = [
                {
                    id: 1,
                    color: 'primary',
                    text: t('careerGuide.downloadReports'),
                    to: '/mijn-documenten'
                }
            ];
            break;
        default:
            buttons = [];
    }

    return buttons;
};

export const getIntroduction = (step, t, steps) => {
    let hasAdvisor = false;
    if (steps[0]) {
        steps[0].subSteps &&
            steps[0].subSteps.forEach((subStep) => {
                if (subStep.type === 'CHOOSE_ADVISOR' && subStep.finished) {
                    hasAdvisor = true;
                }
            });
    }
    let introduction = '';

    switch (step) {
        case 1:
            if (hasAdvisor) {
                introduction = t('careerGuide.step1introAlt');
            } else {
                introduction = t('careerGuide.step1intro');
            }
            break;
        case 2:
            introduction = t('careerGuide.step2intro');
            break;
        case 3:
            introduction = t('careerGuide.step3intro');
            break;
        case 4:
            introduction = t('careerGuide.step4intro');
            break;
        default:
            introduction = '';
    }

    return introduction;
};

const infoBlockStyle = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    body: {
        color: theme.palette.text.primaryLight
    },
    link: {
        width: '100%',
        fontSize: '18px',
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        textDecoration: 'none',
        fontWeight: 500
    },
    innerLink: {
        marginRight: theme.spacing(2),
        color: theme.palette.primary.main
    },
    advisorInfo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '26px'
    },
    image: {
        width: '120px',
        height: '120px',
        borderRadius: '100%',
        backgroundColor: theme.palette.background.secondary,
        marginRight: '20px'
    },
    city: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        color: theme.palette.text.secondary
    },
    locationIcon: {
        marginRight: '10px',
        color: theme.palette.text.secondary,
        opacity: '.5',
        display: 'flex',
        alignItems: 'center'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export const getInfoBlock = (t, person) => {
    let infoBlock = '';
    const classes = infoBlockStyle();

    if (person && person.name) {
        infoBlock = <PersonBlock person={person} title={t('careerGuide.myCareerGuider')} />;
    } else {
        infoBlock = (
            <div className={classes.root}>
                <Typography variant="h5" className={classes.title}>
                    {t('careerGuide.step1infoTitle')}
                </Typography>
                <Typography className={classes.body}>{t('careerGuide.step1info')}</Typography>
                <a
                    href={`${process.env.REACT_APP_FLOW_WEB_DOMAIN}/loopbaanwijzer`}
                    className={classes.link}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <span className={classes.innerLink}>{t('careerGuide.moreInfo')}</span>
                    <ArrowRight />
                </a>
            </div>
        );
    }

    return infoBlock;
};
