const parseHroQuickScanScore = (score) => {
    /**
     * Parse the different elements (score, date and CTAs) from given instrument score for given element (kwadrant).
     *
     * @param instrumentScore Instrument score to parse
     * @param elementFilter Filter for the specific 'kwadrant' to parse
     * @return Parsed score for a specific 'kwadrant'.
     */
    const mapElementsFromInstrumentScore = (instrumentScore, elementFilter) => {
        const metaScore = instrumentScore.metaScores.filter((metascore) => {
            return metascore.label ? metascore.label.includes(elementFilter + '-LMH') : null;
        });

        if (metaScore.length !== 1) {
            throw Error(`Not exactly 1 metascore found for element filter '${elementFilter}'`);
        }

        const date = instrumentScore.metaScores.filter((metascore) =>
            metascore.label.includes(elementFilter + '-date')
        );

        if (date.length !== 1) {
            throw Error(`Not exactly 1 date found for element filter '${elementFilter}'`);
        }

        const ctas = instrumentScore.callToActions.filter((cta) => cta.metaScoreLabel.includes(elementFilter));

        return {
            ...metaScore[0],
            date: date[0].value,
            ctas: ctas
        };
    };
    /**
     * Parse the 4 'kwadranten' from given instrumentScore.
     *
     * @param instrumentScore Instrument score to parse
     * @return parsed 'kwadranten' from instrumentScore
     */
    const mapInstrumentScore = (instrumentScore) => {
        return {
            motivatie: mapElementsFromInstrumentScore(instrumentScore, 'kwadrant-motivatie'),
            balans: mapElementsFromInstrumentScore(instrumentScore, 'kwadrant-balans'),
            vak: mapElementsFromInstrumentScore(instrumentScore, 'kwadrant-vak'),
            gezondheid: mapElementsFromInstrumentScore(instrumentScore, 'kwadrant-gezondheid')
        };
    };

    const initialParsed = score.scores.instrumentScores.map(mapInstrumentScore);

    // if (initialParsed.length < 1 || initialParsed.length > 2) {
    //     throw Error(`Unexpected amount (${initialParsed.length}) of scores from initial parse`);
    // }

    const mapElementWithPreviousScore = (initialParsed, element) => {
        const current = initialParsed[0][element];
        let noPrevious = true;
        let result = current;

        Object.entries(initialParsed).forEach((key, value) => {
            if (
                noPrevious &&
                key !== 0 &&
                initialParsed[key] &&
                initialParsed[key][element] &&
                initialParsed[key][element].date !== current.date
            ) {
                noPrevious = false;
                result = {
                    ...current,
                    previous: initialParsed[key][element]
                };
            }
        });

        return result;
    };

    let result;
    if (initialParsed.length > 1) {
        const motivatie = mapElementWithPreviousScore(initialParsed, 'motivatie');
        const balans = mapElementWithPreviousScore(initialParsed, 'balans');
        const vak = mapElementWithPreviousScore(initialParsed, 'vak');
        const gezondheid = mapElementWithPreviousScore(initialParsed, 'gezondheid');

        result = {
            motivatie,
            balans,
            vak,
            gezondheid
        };
    } else {
        result = initialParsed[0];
    }

    return result;
};

export default parseHroQuickScanScore;
