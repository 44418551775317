import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');

export function isEmpty(obj) {
    for (var key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function formatDate(date) {
    const newDate = moment(date).format('D MMMM Y');

    return newDate;
}

export function convertToSlug(name) {
    if (!name) return '';
    return name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export function mapToSelect(array, label, value) {
    if (!array) return;

    const newArray = array.map((item) => {
        return {
            label: item[label],
            value: item[value]
        };
    });

    return newArray;
}

export function lightenDarkenColor(col, amt) {
    if (!col) return;

    var usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export const isUndefinedOrNull = (value) => value === undefined || value === null;
export const isUndefinedOrNullOrEmptyString = (value) => isUndefinedOrNull(value) || value === '';
