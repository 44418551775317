import React, { useState } from 'react';
import { Typography, Table, TableCell, TableRow, TableHead, TableBody, Button, Grid } from '@material-ui/core';
import { SwipeWrapper } from 'shared';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import DatePicker from './MeetingDatePicker';

const OpenMeetings = (props) => {
    const { classes, meetings, updateMeeting } = props;
    const { t } = useTranslation();

    const [currentMeeting, setCurrentMeeting] = useState({});
    const [showDatePicker, setShowDatePicker] = useState(false);

    const openDatePicker = () => setShowDatePicker(true);

    const handleDateChange = (date) => {
        setShowDatePicker(false);
        updateMeeting({
            id: currentMeeting.id,
            ownerId: currentMeeting.owner.id,
            status: 'FINISHED',
            finishedOn: date,
            message: t('dashboard.meetingUpdatedMessage')
        });
    };

    return (
        <React.Fragment>
            <Typography variant="h4" className={classes.meetingsHeader}>
                {t('dashboard.openMeetings')}
            </Typography>
            <SwipeWrapper>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>{t('dashboard.meetingCode')}</TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.maxWidth)}>
                                {t('dashboard.meetingUserName')}
                            </TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.maxWidth)}>
                                {t('dashboard.meetingUserEmail')}
                            </TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.maxWidth)}>
                                {t('dashboard.meetingNumber')}
                            </TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.hidden)}>
                                Datum gesprek
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {meetings.map((meeting) => (
                            <TableRow key={meeting.id}>
                                <TableCell className={classes.tableCell}>{meeting.code}</TableCell>
                                <TableCell className={classNames(classes.tableCell, classes.maxWidth)}>
                                    {meeting.owner.profile.fullName}
                                </TableCell>
                                <TableCell
                                    className={classNames(
                                        classes.tableCell,
                                        classes.tableCellPrimary,
                                        classes.maxWidth,
                                        classes.widerMaxWidth
                                    )}
                                >
                                    {meeting.owner.email}
                                </TableCell>
                                <TableCell className={classNames(classes.tableCell, classes.maxWidth)}>
                                    {meeting.mappedType}
                                </TableCell>
                                <TableCell className={classNames(classes.tableCell, classes.buttonCell)}>
                                    <Grid container spacing={2} className={classes.gridEnd}>
                                        <Grid item>
                                            <Button
                                                onClick={() =>
                                                    updateMeeting({
                                                        id: meeting.id,
                                                        ownerId: meeting.owner.id,
                                                        status: 'CANCELLED',
                                                        message: t('dashboard.meetingCanceledMessage')
                                                    })
                                                }
                                                variant="contained"
                                                className={classes.button}
                                                disableElevation
                                            >
                                                {t('dashboard.meetingCanceled')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                className={classNames(classes.button, classes.buttonSecondary)}
                                                disableElevation
                                                onClick={() => {
                                                    setCurrentMeeting(meeting);
                                                    openDatePicker();
                                                }}
                                            >
                                                {t('dashboard.meetingFinished')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                        {meetings.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={5} className={classes.tableCell}>
                                    Er zijn geen openstaande verzoeken.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </SwipeWrapper>
            <DatePicker
                classes={{ root: classes.datePicker }}
                open={showDatePicker}
                onClose={() => setShowDatePicker(false)}
                onChange={handleDateChange}
                disablePast={false}
                openTo="date"
                clearable={false}
            />
        </React.Fragment>
    );
};

export default OpenMeetings;
