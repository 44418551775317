import React, { useState, useEffect, useReducer } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';
import { Checkbox, FormControlLabel, InputLabel, FormControl, FormGroup, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        width: '48px',
        height: '24px'
    },
    formControl: {
        width: '100%'
    },
    loading: {
        pointerEvents: 'none',
        opacity: '.5'
    },
    checkboxRoot: {
        color: theme.palette.primary.light,
        padding: `${theme.spacing(0.875)}px ${theme.spacing(1.125)}px`
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '18px',
        height: '18px',
        border: '1px solid currentColor',
        borderRadius: '2px'
    },
    iconSVG: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.contrastText
    },
    iconChecked: {
        backgroundColor: theme.palette.primary.main
    },
    label: {
        marginLeft: theme.spacing(1.25),
        color: theme.palette.text.secondary,
        padding: `${theme.spacing(0.75)}px 0`,
        lineHeight: 1.4,
        fontSize: theme.typography.htmlFontSize
    }
}));

const CheckboxGroup = (props) => {
    const {
        loading,
        options = [],
        formClasses = {},
        itemRootClasses = {},
        name,
        form,
        initialValue,
        label,
        labelClass,
        optional
    } = props;
    const classes = useStyles(props);

    const reducer = (state, action) => {
        const _state = [];
        if (!action.initial) {
            state.forEach((i) => {
                if (options.some((opt) => opt.value === i)) _state.push(i);
            });
        }

        if (action.checked) {
            if (!_state.some((i) => i === action.value)) return [..._state, action.value];
        } else if (action.initial) {
            return [...action.value];
        } else {
            const arr = [];
            _state.forEach((i) => {
                if (i !== action.value) arr.push(i);
            });
            return arr;
        }
    };
    const [checkedState, setCheckedState] = useReducer(reducer, []);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);

    const [loadingInitialValue, setLoadingInitialValue] = useState(false);

    useEffect(() => {
        if (initialValue && !loadingInitialValue) {
            setLoadingInitialValue(true);
            setCheckedState({
                value: initialValue.map((i) => i.id),
                initial: true
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: checkedState });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedState]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.errors]);

    const handleChange = (value) => (event) => {
        setCheckedState({ value: value, checked: event.target.checked });
    };

    return (
        <FormControl
            component="fieldset"
            className={classNames(classes.formControl, { [classes.loading]: loading })}
            error={error}
        >
            {label && (
                <InputLabel className={classNames(classes.label, labelClass)}>
                    {label}
                    {optional && <span className={classes.optionalLabel}>(optioneel)</span>}
                </InputLabel>
            )}
            <FormGroup className={classNames(classes.formGroupRoot, formClasses)}>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        className={classNames(classes.formControlLabelRoot, itemRootClasses)}
                        control={
                            <Checkbox
                                classes={{
                                    root: classes.checkboxRoot,
                                    checked: classes.checked
                                }}
                                icon={<span className={classes.icon}></span>}
                                checkedIcon={
                                    <span
                                        className={classNames(classes.icon, {
                                            [classes.iconChecked]: checkedState
                                        })}
                                    >
                                        <CheckIcon className={classes.iconSVG} />
                                    </span>
                                }
                                onChange={handleChange(option.value)}
                                checked={checkedState.some((i) => i === option.value)}
                                value={option.value}
                                color={'primary'}
                            />
                        }
                        label={
                            option.tooltip ? (
                                <React.Fragment>
                                    {option.label}
                                    <Tooltip title={option.tooltip} />
                                </React.Fragment>
                            ) : (
                                option.label
                            )
                        }
                        classes={{
                            label: classNames(classes.label, {
                                [classes.checkedLabel]: checkedState
                            })
                        }}
                    />
                ))}
            </FormGroup>
            {errorMessage && <FormHelperText>{error && errorMessage}</FormHelperText>}
        </FormControl>
    );
};

export default CheckboxGroup;
