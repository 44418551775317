import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOverview, FindAdvisorBlock, Breadcrumbs } from 'shared';
import FilterContent from './FilterContent';
import { GQL_GET_USER, GQL_GET_USERS, GQL_SAVE_USER, GQL_UPDATE_SUB_STEP, GQL_UPDATE_TASK } from './graphql';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useFilter } from 'hooks';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        maxWidth: '1280px',
        margin: '0 auto',
        padding: theme.spacing(2.5, 0, 15),

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(15, 0, 27.5)
        }
    },
    content: {
        display: 'block'
    },
    title: {
        width: '100%',
        padding: theme.spacing(0, 3.75),
        marginBottom: theme.spacing(2.5),
        fontSize: '1.8rem',

        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4.5),
            fontSize: '3rem',
            lineHeight: 1.3
        }
    }
}));

const BreadcrumbPath = [
    {
        title: 'Mijn loopbaanwijzer',
        link: '/mijn-loopbaanwijzer'
    },
    {
        title: 'Loopbaanadviseur kiezen',
        link: '/mijn-loopbaanwijzer/loopbaanadviseur-kiezen'
    }
];

const CareerGuideAdvisor = (props) => {
    const classes = useStyles();
    const query = useFilter({
        filter: { type: 'CAREER_ADVISOR' },
        pagination: {
            sortProperties: ['profile.firstName', 'profile.lastName']
        }
    });
    const { t } = useTranslation();

    const [error, setError] = React.useState();

    const { data: userData = {} } = useQuery(GQL_GET_USER);
    const [getUsers, { loading, data = {} }] = useLazyQuery(GQL_GET_USERS, {
        onError: (r) => {
            setError('De server kon je verzoek momenteel niet verwerken.');
        }
    });

    useEffect(() => {
        getUsers({
            variables: { ...query.query }
        });
    }, [getUsers, query.query]);

    const { users = {} } = data;
    const { currentUser: user = {} } = userData;

    const [saveUser] = useMutation(GQL_SAVE_USER);
    const [saveSubStep] = useMutation(GQL_UPDATE_SUB_STEP);
    const [saveTask] = useMutation(GQL_UPDATE_TASK);

    const handleClick = (id) => {
        const { careerProfile } = user;
        let advisorId = 0;

        careerProfile.careerSteps.forEach((item) => {
            item.subSteps &&
                item.subSteps.forEach((subStep) => {
                    if (subStep.type === 'CHOOSE_ADVISOR') advisorId = subStep.id;
                });
        });

        const { tasks } = careerProfile;
        let task = false;
        tasks.forEach((item) => {
            if (item.name === 'career_advisor') {
                task = item.id;
            }
        });

        if (id && user.id) {
            saveTask({
                variables: {
                    input: {
                        id: task,
                        finished: true
                    }
                }
            });

            saveSubStep({
                variables: {
                    input: {
                        id: advisorId,
                        finished: true
                    }
                }
            }).then((r) => {});

            saveUser({
                variables: {
                    input: {
                        id: user.id,
                        careerProfile: {
                            careerAdvisor: id
                        }
                    }
                }
            })
                .then((r) => {
                    props.history.push('/mijn-loopbaanwijzer');
                })
                .catch((e) => {
                    return false;
                });
        }
    };

    const sortedUsers =
        users.users && users.users.length
            ? users.users.sort((a, b) => {
                  var nameA = a.profile.firstName;
                  var nameB = b.profile.firstName;
                  if (nameA < nameB) {
                      return -1;
                  }
                  if (nameA > nameB) {
                      return 1;
                  }

                  return 0;
              })
            : [];

    return (
        <React.Fragment>
            <Breadcrumbs path={BreadcrumbPath} />
            <div className={classes.root}>
                <Typography className={classes.title} variant="h3" component="h1" gutterBottom>
                    {t('careerGuide.chooseAdvisor')}
                </Typography>
                <FilterOverview
                    filterContent={FilterContent}
                    title="Filters"
                    query={query}
                    count={users.count}
                    loading={loading}
                >
                    {sortedUsers && sortedUsers.length
                        ? sortedUsers.map((user) => (
                              <FindAdvisorBlock
                                  user={user}
                                  profile={user.profile || {}}
                                  key={user.id}
                                  onChooseAdvisor={handleClick}
                              />
                          ))
                        : !loading && (error || t('advisorsOverview.noResults'))}
                </FilterOverview>
            </div>
        </React.Fragment>
    );
};

export default CareerGuideAdvisor;
