import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ToDoIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary['light']
    },
    done: {
        color: theme.palette.secondary['main']
    },
    text: {
        marginLeft: '12px'
    }
}));

const StatusText = (props) => {
    const { finished = false, text = 'Nog niet gestart' } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, { [classes.done]: finished })}>
            {finished && <DoneIcon />}
            {!finished && <ToDoIcon />}
            <Typography className={classes.text}>{text}</Typography>
        </div>
    );
};

export default StatusText;
