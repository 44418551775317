import React from 'react';
import CustomIcon from './CustomIcon';

const Excel = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801' }}>
        <path d="M475.095 131.997c-.031-2.526-.828-5.021-2.562-6.992L366.325 3.694c-.021-.034-.053-.045-.085-.076-.633-.707-1.36-1.29-2.141-1.804-.232-.15-.464-.287-.707-.422-.686-.366-1.392-.67-2.13-.892-.2-.058-.38-.14-.58-.192C359.87.119 359.048 0 358.204 0H97.2C85.293 0 75.6 9.693 75.6 21.601v507.6c0 11.913 9.692 21.601 21.6 21.601h356.4c11.918 0 21.6-9.688 21.6-21.601V133.207c0-.411-.063-.809-.105-1.21zM201.192 523.221l-12.129-24.258c-4.967-9.345-8.152-16.306-11.928-24.058h-.398c-2.787 7.757-6.162 14.713-10.336 24.058l-11.141 24.258h-34.594l38.773-67.807-37.378-66.213h34.792l11.728 24.458c3.976 8.153 6.961 14.713 10.149 22.265h.388c3.185-8.543 5.767-14.513 9.142-22.265l11.34-24.458h34.594l-37.774 65.422 39.77 68.598h-34.998zm134.99 0h-83.71V389.212H282.9v108.559h53.282v25.45zm50.688 1.982c-15.304 0-30.417-3.977-37.969-8.152l6.165-25.049c8.158 4.166 20.683 8.342 33.607 8.342 13.917 0 21.268-5.758 21.268-14.512 0-8.354-6.365-13.126-22.465-18.89-22.265-7.752-36.793-20.081-36.793-39.562 0-22.865 19.09-40.368 50.715-40.368 15.104 0 26.235 3.186 34.193 6.761l-6.761 24.453c-5.368-2.584-14.919-6.36-28.028-6.36-13.12 0-19.48 5.959-19.48 12.926 0 8.548 7.552 12.323 24.849 18.884 23.657 8.754 34.794 21.072 34.794 39.962-.01 22.486-17.302 41.565-54.095 41.565zM97.2 366.758V21.605h250.204v110.516c0 5.962 4.83 10.8 10.8 10.8H453.6l.011 223.837H97.2z" />
        <path d="M307.353 138.375H128.25v167.4H402.3v-167.4h-94.947zm0 81h-84.148v-35.1h84.148v35.1zm-173.703-75.6h84.148v35.1H133.65v-35.1zm0 40.5h84.148v35.1H133.65v-35.1zm0 40.5h84.148v35.1H133.65v-35.1zm0 75.6v-35.1h84.148v35.1H133.65zm89.554 0v-35.1h84.148v35.1h-84.148zm173.696 0h-84.148v-35.1H396.9v35.1zm0-81h-84.148v-35.1H396.9v35.1zm-84.148-40.5v-35.1H396.9v35.1h-84.148z" />
    </CustomIcon>
);

export default Excel;
