import Container from '@material-ui/core/Container';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Block, CtaBlock } from 'shared';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            maxWidth: '1340px',
            padding: theme.spacing(5, 3.75, 10),

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(15, 3.75, 27.5)
            }
        },
        title: {
            marginBottom: theme.spacing(2.5),
            fontSize: '2.2rem',
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(5),
                fontSize: '3rem',
                lineHeight: 1.3
            }
        },
        subHeader: {
            marginBottom: theme.spacing(2)
        },
        ctaBlocks: {
            marginBottom: theme.spacing(2)
        },
        contentBlock: {
            marginBottom: theme.spacing(5)
        },
        block: {
            margin: theme.spacing(0, -5),
            width: 'calc(100% + 80px)',
            display: 'flex',
            justifyContent: 'center',

            [theme.breakpoints.up('md')]: {
                width: '100%',
                margin: 0
            },
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(6.25)
            }
        },
        contentWrapper: {
            padding: theme.spacing(5, 5, 2.5, 5)
        },
        item: {
            padding: `0 ${theme.spacing(5)}px !important`,
            maxWidth: '640px',

            [theme.breakpoints.up('md')]: {
                padding: `${theme.spacing(5)}px !important`
            }
        },
        introText: {
            maxWidth: '560px',
            '& + p': {
                marginTop: theme.spacing(4)
            }
        }
    }),
    { name: 'MyFlowContainer' }
);

const MyFlowContainer = (props) => {
    const { title, subTitle, introText, buttons, buttonsTitle, renderInfoBox, renderAdditional, children } = props;

    const classes = useStyles();

    // function
    const handleClick = (data) => {
        if (props.onClick) props.onClick(data);
    };

    return (
        <Container maxWidth="lg" fixed className={classes.root}>
            <Grid container spacing={10} justify="space-between">
                <Grid item className={classes.contentWrapper} xs={12} md={renderInfoBox ? 6 : 7}>
                    <div className={classes.contentBlock}>
                        <Typography className={classes.title} variant="h3">
                            {title}
                        </Typography>
                        {subTitle && (
                            <Typography variant="h5" className={classes.subHeader}>
                                {subTitle}
                            </Typography>
                        )}
                        {introText && (
                            <Trans>
                                <Typography
                                    className={classes.introText}
                                    dangerouslySetInnerHTML={{ __html: introText }}
                                />
                            </Trans>
                        )}
                        {renderAdditional && renderAdditional()}
                    </div>
                    {buttons && (
                        <React.Fragment>
                            {buttonsTitle && (
                                <Typography variant="h5" className={classes.subHeader}>
                                    {buttonsTitle}
                                </Typography>
                            )}
                            <Grid container spacing={2} justify="space-between" className={classes.ctaBlocks}>
                                {buttons.map((button) => (
                                    <Grid item xs={12} md={6} key={button.id}>
                                        <CtaBlock
                                            id={button.id}
                                            text={button.text}
                                            size="small"
                                            color={button.color || 'primary'}
                                            extern={button.extern || false}
                                            blank={button.blank || false}
                                            to={button.to}
                                            disabled={button.disabled || false}
                                            onClick={() => handleClick(button)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                {renderInfoBox && (
                    <Grid item xs={12} md={6} className={classes.item}>
                        <div className={classes.block}>
                            <Block>{renderInfoBox()}</Block>
                        </div>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Container>
    );
};

export default MyFlowContainer;
