import React from 'react';
import CustomIcon from './CustomIcon';

const Download = (props) => {
    return (
        <CustomIcon {...props} svgAtts={{ viewBox: '0 0 18 18' }}>
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M17.1 10.8a.9.9 0 00-.9.9v3.6a.9.9 0 01-.9.9H2.7a.9.9 0 01-.9-.9v-3.6a.9.9 0 10-1.8 0v3.6C0 16.7912 1.2088 18 2.7 18h12.6c1.4912 0 2.7-1.2088 2.7-2.7v-3.6a.9.9 0 00-.9-.9zm-8.739 1.539a.9.9 0 00.297.189.846.846 0 00.684 0 .9.9 0 00.297-.189l3.6-3.6a.9037.9037 0 00-1.278-1.278L9.9 9.531V.9a.9.9 0 10-1.8 0v8.631l-2.061-2.07a.9037.9037 0 10-1.278 1.278l3.6 3.6z"
            />
        </CustomIcon>
    );
};

export default Download;
