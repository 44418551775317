import React from 'react';
import CustomIcon from './CustomIcon';

const ZipFile = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801' }}>
        <path d="M475.095 131.992c-.032-2.526-.833-5.021-2.568-6.993L366.324 3.694c-.021-.034-.053-.045-.084-.076-.633-.707-1.36-1.29-2.141-1.804-.232-.15-.465-.285-.707-.422-.686-.366-1.393-.67-2.131-.892-.2-.058-.379-.14-.58-.192C359.87.114 359.047 0 358.203 0H97.2C85.292 0 75.6 9.693 75.6 21.601v507.6c0 11.913 9.692 21.601 21.6 21.601h356.4c11.918 0 21.601-9.688 21.601-21.601V133.202c-.001-.406-.064-.804-.106-1.21zM243.599 523.494H141.75v-15.936l62.398-89.797v-.785h-56.565v-24.484h95.051v17.106l-61.038 88.636v.771h62.002v24.489zm48.422 0h-29.744V392.492h29.744v131.002zm107.684-60.054c-10.104 9.524-25.069 13.796-42.566 13.796-3.893 0-7.383-.19-10.104-.58v46.849h-29.352V394.242c9.134-1.561 21.958-2.721 40.036-2.721 18.277 0 31.292 3.491 40.046 10.494 8.354 6.607 13.996 17.486 13.996 30.322 0 12.826-4.282 23.716-12.056 31.103zM97.2 366.752V21.601h129.167v-3.396h32.756v3.396h88.28v110.515c0 5.961 4.831 10.8 10.8 10.8H453.6l.011 223.836H97.2z" />
        <path d="M359.279 413.87c-6.033 0-10.114.586-12.245 1.171v38.676c2.521.585 5.632.785 9.914.785 15.736 0 25.46-7.979 25.46-21.378 0-12.061-8.363-19.254-23.129-19.254zM259.124 39.918h32.756v13.516h-32.756zM226.368 21.601h32.756v10.125h-32.756zM226.368 60.146h32.756v13.516h-32.756zM259.124 82.274h32.756v13.518h-32.756zM259.124 124.983h32.756v13.516h-32.756zM226.368 103.275h32.756v13.516h-32.756zM259.124 149.537c-23.193 0-34.225 18.792-34.225 41.99l-7.765 70.348c0 23.198 18.792 42.003 41.984 42.003 23.19 0 41.974-18.805 41.974-42.003l-7.741-70.348c.01-23.193-11.033-41.99-34.227-41.99zm13.916 135.894h-27.799v-58.728h27.799v58.728z" />
    </CustomIcon>
);

export default ZipFile;
