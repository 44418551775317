import gql from 'graphql-tag';

const SEARCH_TRIGGERS = gql`
    query searchTriggers {
        searchTriggers {
            city {
                id
                name
            }
            distance
            search
            active
            id
            lastRun
        }
    }
`;

export { SEARCH_TRIGGERS };
