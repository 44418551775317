import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { ArrowRight, Link, StatusText, SwipeWrapper, Table, TableCell, TableRow } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginBottom: theme.spacing(2.75)
    },
    table: {
        background: theme.palette.background.paper,
        minWidth: '1000px',

        [theme.breakpoints.up('md')]: {
            minWidth: 0,
            maxWidth: '1000px'
        }
    },
    subTitle: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            fontSize: '16px'
        }
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            '&:hover': {
                '& .MuiSvgIcon-root': {
                    transform: 'translateX(10px)',
                    opacity: 1
                }
            }
        }
    },
    linkDisabled: {
        color: theme.palette.primary.light,
        cursor: 'default'
    },
    linkIcon: {
        width: '18px',
        transition: 'transform 250ms, opacity 250ms',

        [theme.breakpoints.up('md')]: {
            opacity: '0.5'
        }
    },
    stepLink: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        color: `${theme.palette.primary.main}!important`,
        textDecoration: 'none',
        fontSize: '18px',
        fontWeight: 500,
        marginTop: theme.spacing(1.25),
        cursor: 'pointer',
        '& svg': {
            marginRight: theme.spacing(1.75)
        }
    }
}));

const MyProgress = (props) => {
    const { tasks, wizzardStatus, userData } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    console.log(userData);

    // functions
    const getLink = (task) => {
        switch (task.name) {
            case 'vacancy_portal':
                return {
                    id: 'click-id-task-' + task.name,
                    to: `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}/mijn-gegevens/bewerken/mijn-flow`
                };
            case 'plan_of_tomorrow':
                return {
                    id: 'click-id-task-' + task.name,
                    to: `${process.env.REACT_APP_TOMORROW_FLOW_DOMAIN}/planvoormorgen`,
                    target: '_blank'
                };
            case 'wizard':
                return {
                    id: 'click-id-task-' + task.name,
                    to: '/mijn-loopbaanwijzer'
                };
            case 'flow_meter':
                return {
                    id: 'click-id-task-' + task.name,
                    to: '/quick-scan'
                };
            case 'career_advisor':
                return {
                    id: 'click-id-task-' + task.name,
                    to: '/mijn-loopbaanwijzer/loopbaanadviseur-kiezen'
                };
            case 'career_test':
                if (task.finished) {
                    return {
                        id: 'click-id-task-' + task.name,
                        to: ''
                    };
                } else {
                    return {
                        id: 'click-id-task-' + task.name,
                        to: task.url
                    };
                }
            case 'skill_test':
                if (task.finished) {
                    return {
                        id: 'click-id-task-' + task.name,
                        to: '/mijn-documenten'
                    };
                } else {
                    return {
                        id: 'click-id-task-' + task.name,
                        to: task.url
                    };
                }

            default:
                console.log('no name found');
        }
    };

    const taskLinkStatus = (data) => {
        switch (true) {
            case data.name === 'plan_of_tomorrow' && data.finished:
                return t('dashboard.plan_of_tomorrow_link_done');
            case data.name === 'career_advisor' && data.finished:
                return t('dashboard.editCareerAdvisor');
            case data.name === 'career_test' && data.finished:
                return t('dashboard.redoTest');
            case data.name === 'skill_test' && data.finished:
                return t('dashboard.skillTestDone');
            case data.name === 'vacancy_portal' && data.finished:
                return t('dashboard.vacancy_portal_link_done');
            case data.name === 'flow_meter' && data.finished:
                return t('dashboard.flow_meter_see_result');
            default:
                return t(`dashboard.${data.name}_link`);
        }
    };

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.title}>
                {t('dashboard.myProgress')}
            </Typography>

            <SwipeWrapper>
                <Table
                    className={classes.table}
                    head={[t('dashboard.part'), t('dashboard.status'), t('dashboard.link')]}
                    renderRows={() => (
                        <React.Fragment>
                            {tasks &&
                                tasks.length > 0 &&
                                tasks.map((task) => {
                                    // TODO: ugly hack for wizzard status
                                    if (task.name === 'wizard' && !task.finished) {
                                        task.inProgress = wizzardStatus;
                                    }
                                    return (
                                        <TableRow key={task.id}>
                                            <TableCell>
                                                <Typography>{t(`dashboard.${task.name}`)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <StatusText
                                                    text={
                                                        task.inProgress
                                                            ? 'Gestart'
                                                            : task.finished
                                                            ? t(`dashboard.${task.name}_done`)
                                                            : t(`dashboard.${task.name}_progress`)
                                                    }
                                                    finished={task.finished}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    component={Link}
                                                    {...getLink(task)}
                                                    className={classes.link}
                                                >
                                                    {taskLinkStatus(task)}
                                                    <ArrowRight
                                                        className={classNames(classes.linkIcon, {
                                                            [classes.linkIconDisabled]: task.finished
                                                        })}
                                                    />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </React.Fragment>
                    )}
                />
            </SwipeWrapper>
            <Typography>
                <br />
                Heb je eerder de Loopbaanwijzer-test gedaan? Het rapport daarvan kun je vinden in Mijn documenten.
                <Link className={classNames(classes.stepLink)} to={'/mijn-documenten'}>
                    <ArrowRight />
                    {'Naar Mijn documenten'}
                </Link>
            </Typography>
        </div>
    );
};

export default MyProgress;
