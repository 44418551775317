import { TableRow, withStyles } from '@material-ui/core';
import React from 'react';

const StyledTableRow = withStyles((theme) => ({
    root: {
        position: 'relative',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    }
}))(TableRow);

const TableRowContainer = (props) => {
    const { onClick, children } = props;

    const handleClick = () => {
        if (onClick) onClick();
    };

    return <StyledTableRow onClick={() => handleClick()}>{children}</StyledTableRow>;
};

export default TableRowContainer;
