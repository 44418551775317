import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography } from '@material-ui/core';
import { Loader } from 'shared';

const useStyles = makeStyles((theme) => ({
    loaderPopup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4, 5),
        borderRadius: '20px',
        zIndex: theme.zIndex.modal,
        outline: 'none'
    }
}));

const LoaderModal = (props) => {
    const { loading } = props;
    const classes = useStyles();
    return (
        <Modal open={loading}>
            <div className={classes.loaderPopup}>
                <Loader />
                <Typography>Laden...</Typography>
            </div>
        </Modal>
    );
};

export default LoaderModal;
