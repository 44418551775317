import gql from 'graphql-tag';

export const LIKE_VACANCY = gql`
    mutation ($input: LikeVacancyInput!) {
        likeVacancy(input: $input) {
            liked
            vacancyId
        }
    }
`;

export const LIKE_USER = gql`
    mutation ($input: LikeUserInput!) {
        likeUser(input: $input) {
            liked
            userId
        }
    }
`;
