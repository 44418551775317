import React from 'react';
import CustomIcon from './CustomIcon';

const SmileyNeutral = (props) => {
    return (
        <CustomIcon width="28" height="28" {...props}>
            <g fill="#F3A436" fillRule="nonzero">
                <path
                    d="M14 1C6.832 1 1 6.832 1 14s5.832 13 13 13 13-5.832 13-13S21.168 1 14 1zm0 24.87C7.455 25.87 2.13 20.545 2.13 14 2.13 7.455 7.455 2.13 14 2.13c6.545 0 11.87 5.325 11.87 11.87 0 6.545-5.325 11.87-11.87 11.87z"
                    stroke="#F3A436"
                    strokeWidth=".5"
                />
                <path d="M9.478 13.435c.935 0 1.696-.761 1.696-1.696 0-.935-.76-1.696-1.696-1.696-.935 0-1.695.761-1.695 1.696 0 .935.76 1.696 1.695 1.696zM18.522 10.043c-.935 0-1.696.761-1.696 1.696 0 .935.76 1.696 1.696 1.696.935 0 1.695-.761 1.695-1.696 0-.935-.76-1.696-1.695-1.696z" />
                <path
                    d="M19.652 16.826H8.348a.565.565 0 000 1.13h11.304a.565.565 0 000-1.13z"
                    stroke="#F3A436"
                    strokeWidth=".5"
                />
            </g>
        </CustomIcon>
    );
};

export default SmileyNeutral;
