import { GET_USER, client } from 'api';

export const loginUser = (value) => (dispatch) => {
    dispatch({
        type: LOGIN,
        payload: value
    });
};

export const logoutUser = (value) => (dispatch) => {
    dispatch({
        type: LOGOUT,
        payload: value
    });
};

export const setUser = (value) => (dispatch) => {
    dispatch({
        type: SET_USER,
        payload: value
    });
};

export const setToken = (value) => (dispatch) => {
    dispatch({
        type: SET_TOKEN,
        payload: value
    });
};

export const setHroId = (value) => (dispatch) => {
    dispatch({
        type: SET_HRO_ID,
        payload: value
    });
};

export const setUserData = (id) => (dispatch) => {
    client
        .query({
            query: GET_USER,
            variables: {
                id: id
            }
        })
        .then((result) => {
            const userData = result.data.currentUser;

            if (userData) {
                return dispatch(setUser(userData));
            }
        });
};

export const setUserRole = (role) => (dispatch) => {
    dispatch({
        type: SET_ROLE,
        payload: role
    });
};

export const LOGIN = 'LOGIN';
export const SET_USER = 'SET_USER';
export const SET_ROLE = 'SET_ROLE';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SET_HRO_ID = 'SET_HRO_ID';
