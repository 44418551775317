/* eslint-disable */

const data = {
    v: '5.5.8',
    fr: 25,
    ip: 41,
    op: 144,
    w: 80,
    h: 80,
    nm: 'flow-loader',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Mask 3',
            td: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [40, 40, 0], ix: 2 },
                a: { a: 0, k: [66, 66, 0], ix: 1 },
                s: { a: 0, k: [61, 61, 100], ix: 6 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [12.631, 0],
                                        [0, 0],
                                        [0, 7.831],
                                        [0, 0],
                                        [-8.932, -8.932]
                                    ],
                                    o: [
                                        [8.932, 8.932],
                                        [0, 0],
                                        [-7.831, 0],
                                        [0, 0],
                                        [0, -12.632],
                                        [0, 0]
                                    ],
                                    v: [
                                        [56.444, 41.171],
                                        [46.418, 65.376],
                                        [-51.197, 65.376],
                                        [-65.376, 51.196],
                                        [-65.376, -46.418],
                                        [-41.171, -56.444]
                                    ],
                                    c: true
                                },
                                ix: 2
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [0.701960784314, 0.83137254902, 0.313725490196, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 2',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [68.376, 63.624], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Group 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 107,
            op: 144,
            st: 64,
            bm: 0
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Shape Layer 3',
            tt: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 45, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.648, y: 1 },
                            o: { x: 1, y: 0 },
                            t: 64,
                            s: [-50, 130, 0],
                            to: [15, -15, 0],
                            ti: [-15, 15, 0]
                        },
                        { t: 149, s: [40, 40, 0] }
                    ],
                    ix: 2
                },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [140, 140, 100], ix: 6 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ty: 'rc',
                            d: 1,
                            s: { a: 0, k: [85.922, 82.992], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.702000038297, 0.830999995213, 0.313999998803, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [-0.016, -0.379], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Rectangle 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 107,
            op: 144,
            st: 64,
            bm: 0
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Mask 2',
            td: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [40, 40, 0], ix: 2 },
                a: { a: 0, k: [66, 66, 0], ix: 1 },
                s: { a: 0, k: [60, 60, 100], ix: 6 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [12.631, 0],
                                        [0, 0],
                                        [0, 7.831],
                                        [0, 0],
                                        [-8.932, -8.932]
                                    ],
                                    o: [
                                        [8.932, 8.932],
                                        [0, 0],
                                        [-7.831, 0],
                                        [0, 0],
                                        [0, -12.632],
                                        [0, 0]
                                    ],
                                    v: [
                                        [56.444, 41.171],
                                        [46.418, 65.376],
                                        [-51.197, 65.376],
                                        [-65.376, 51.196],
                                        [-65.376, -46.418],
                                        [-41.171, -56.444]
                                    ],
                                    c: true
                                },
                                ix: 2
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [68.376, 63.624], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Group 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 75,
            op: 130,
            st: 32,
            bm: 0
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Shape Layer 2',
            tt: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 45, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.648, y: 1 },
                            o: { x: 1, y: 0 },
                            t: 32,
                            s: [-50, 130, 0],
                            to: [15, -15, 0],
                            ti: [-15, 15, 0]
                        },
                        { t: 117, s: [40, 40, 0] }
                    ],
                    ix: 2
                },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [140, 140, 100], ix: 6 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ty: 'rc',
                            d: 1,
                            s: { a: 0, k: [85.922, 82.992], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.702000038297, 0.830999995213, 0.313999998803, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [0.43137254902, 0.196078431373, 0.525490196078, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [-0.016, -0.379], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Rectangle 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 75,
            op: 130,
            st: 32,
            bm: 0
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Mask',
            td: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [40, 40, 0], ix: 2 },
                a: { a: 0, k: [66, 66, 0], ix: 1 },
                s: { a: 0, k: [60, 60, 100], ix: 6 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [12.631, 0],
                                        [0, 0],
                                        [0, 7.831],
                                        [0, 0],
                                        [-8.932, -8.932]
                                    ],
                                    o: [
                                        [8.932, 8.932],
                                        [0, 0],
                                        [-7.831, 0],
                                        [0, 0],
                                        [0, -12.632],
                                        [0, 0]
                                    ],
                                    v: [
                                        [56.444, 41.171],
                                        [46.418, 65.376],
                                        [-51.197, 65.376],
                                        [-65.376, 51.196],
                                        [-65.376, -46.418],
                                        [-41.171, -56.444]
                                    ],
                                    c: true
                                },
                                ix: 2
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [68.376, 63.624], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Group 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 30,
            op: 107,
            st: 0,
            bm: 0
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Shape Layer 1',
            tt: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 45, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.648, y: 1 },
                            o: { x: 1, y: 0 },
                            t: 0,
                            s: [-50, 130, 0],
                            to: [15, -15, 0],
                            ti: [-15, 15, 0]
                        },
                        { t: 85, s: [40, 40, 0] }
                    ],
                    ix: 2
                },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [140, 140, 100], ix: 6 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ty: 'rc',
                            d: 1,
                            s: { a: 0, k: [85.922, 82.992], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.702000038297, 0.830999995213, 0.313999998803, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [0.701960784314, 0.83137254902, 0.313725490196, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [-0.016, -0.379], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Rectangle 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 30,
            op: 107,
            st: 0,
            bm: 0
        }
    ],
    markers: []
};

export default data;

/* eslint-enable */
