import React from 'react';
import { AppBar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        height: '80px',
        background: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: '100%',
        position: 'fixed',
        top: 'inherit',
        zIndex: '1099',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            position: 'relative'
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        flexFlow: 'row wrap',
        maxWidth: '1440px',
        width: '100%',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        margin: '0 auto',
        '& .MuiButton-root': {
            padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
            height: '51px',
            fontSize: '16px',
            [theme.breakpoints.down(768)]: {
                width: '100%'
            }
        },
        '& .MuiButton-containedSecondary': {
            background: theme.palette.secondary.light
        }
    },
    leftPart: {
        '& .MuiButton-root': {
            marginRight: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(2)
            },
            '&:last-child': {
                margin: 0
            },
            [theme.breakpoints.down(768)]: {
                width: '100%'
            }
        },
        [theme.breakpoints.down(768)]: {
            width: '100%'
        }
    },
    rightPart: {
        display: 'flex',
        '& .MuiButton-root:nth-child(n+2)': {
            marginLeft: theme.spacing(1),
            [theme.breakpoints.down(767)]: {
                margin: theme.spacing(1, 0, 0),
                minWidth: '50vw'
            }
        },
        [theme.breakpoints.down('sm')]: {
            order: -1,
            flexFlow: 'row wrap'
        },
        [theme.breakpoints.down(768)]: {
            margin: theme.spacing(0, 0, 1)
        }
    },
    spacer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: '80px'
        }
    },
    buttonWrap: {
        position: 'relative'
    },
    buttonMenu: {
        position: 'absolute'
    }
}));

const SubMenu = (props) => {
    const { renderLeft, renderRight } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar className={classes.root} direction="column">
                <Grid className={classes.content}>
                    <Grid className={classes.leftPart}>{renderLeft && renderLeft()}</Grid>
                    <Grid className={classes.rightPart}>{renderRight && renderRight()}</Grid>
                </Grid>
            </AppBar>
            <div className={classes.spacer} />
        </React.Fragment>
    );
};

export default SubMenu;
