import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { SmileyGood, SmileyNeutral, SmileyBad, ChevronRight } from 'shared';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import CallToActionButton from './CallToActionButton';

Moment.locale('nl');

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '20px',
        background: theme.palette.background.primary,
        padding: theme.spacing(3.75, 5),
        marginBottom: theme.spacing(2.5),
        width: '100%',
        maxWidth: '1140px',

        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.spacing(-5)
        }
    },
    smiley: {
        marginRight: theme.spacing(1),
        width: '26px',
        height: '26px',
        transform: 'translateY(6px)',

        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2)
        }
    },
    header: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        userSelect: 'none'
    },
    subHeader: {
        fontWeight: 500
    },
    action: {
        color: theme.palette.text.secondary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            fontSize: 0
        }
    },
    arrow: {
        transform: 'rotate(90deg)',
        transformOrigin: 'center center',
        marginLeft: theme.spacing(3.125),
        transition: 'transform 250ms'
    },
    arrowActive: {
        transform: 'rotate(-90deg)'
    },
    hideText: {
        fontSize: 0
    },
    content: {
        marginTop: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        opacity: 0,
        maxHeight: 0,
        pointerEvents: 'none',
        transition: 'opacity 300ms 0ms, max-height 300ms 200ms, margin-top 300ms 200ms',

        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(6),
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexDirection: 'row',

            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                height: '100%',
                width: '2px',
                left: '-36px',
                top: '0',
                backgroundImage: 'linear-gradient(black 20%, rgba(255,255,255,0) 0%)',
                backgroundPosition: 'right',
                backgroundSize: '4px 12px',
                backgroundRepeat: 'repeat-y',
                opacity: '0.2'
            }
        }
    },
    contentActive: {
        opacity: 1,
        marginTop: theme.spacing(2),
        maxHeight: '500px',
        pointerEvents: 'auto',
        transition: 'opacity 300ms 200ms, max-height 300ms 0ms, margin-top 300ms 0ms'
    },
    good: {
        fontWeight: 500,
        color: theme.palette.secondary.main
    },
    neutral: {
        fontWeight: 500,
        color: theme.palette.background.yellowContrast
    },
    bad: {
        fontWeight: 500,
        color: theme.palette.background.red
    },
    description: {
        maxWidth: '490px'
    },
    buttons: {
        padding: theme.spacing(2, 0, 0, 0),

        [theme.breakpoints.up('md')]: {
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 3.75),
            marginLeft: 'auto'
        }
    },
    contentButton: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1.25, 2.5),
        justifyContent: 'space-between',

        [theme.breakpoints.up('md')]: {
            width: '350px',
            marginRight: 0
        }
    },
    buttonIcon: {
        color: theme.palette.primary.contrastText
    },
    previous: {
        maxHeight: 0,
        opacity: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: theme.palette.background.primary,
        color: theme.palette.secondary.contrastText,
        paddingTop: 0,
        pointerEvents: 'none',
        transition: 'opacity 300ms 0ms, max-height 300ms 200ms, padding-top 300ms 200ms',

        '& .MuiSvgIcon-root': {
            transform: 'none',
            marginRight: theme.spacing(3)
        }
    },
    previousActive: {
        opacity: 1,
        paddingTop: theme.spacing(1),
        maxHeight: '100px',
        pointerEvents: 'auto',
        transition: 'opacity 300ms 500ms, max-height 300ms 0ms, padding-top 300ms 0ms'
    },
    date: {
        fontSize: '0.875rem',
        letterSpacing: '1px',
        lineHeight: '2rem',
        opacity: '0.5',
        marginLeft: theme.spacing(1)
    }
}));

const ResultPart = (props) => {
    const { title, currentMood, description, callToActions, previous, activeState = false } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const [active, setActive] = React.useState(activeState);

    const handleClick = () => {
        if (active) {
            setActive(false);
        } else {
            setActive(true);
        }
    };

    const getSmile = (mood) => {
        let content = '';
        switch (mood) {
            case 'H':
                content = <SmileyGood className={classes.smiley} />;
                break;
            case 'M':
                content = <SmileyNeutral className={classes.smiley} />;
                break;
            case 'L':
                content = <SmileyBad className={classes.smiley} />;
                break;
            default:
                content = '';
        }

        return content;
    };

    const getMotivation = (mood) => {
        let content = '';
        switch (mood) {
            case 'H':
                content = <span className={classes.good}>{t('quickScan.good')} </span>;
                break;
            case 'M':
                content = <span className={classes.neutral}>{t('quickScan.neutral')} </span>;
                break;
            case 'L':
                content = <span className={classes.bad}>{t('quickScan.bad')} </span>;
                break;
            default:
                content = '';
        }

        return content;
    };

    return (
        <div className={classes.root}>
            <div className={classes.header} onClick={() => handleClick()}>
                <Typography variant="h6" className={classes.subHeader}>
                    {getSmile(currentMood)} {title}
                </Typography>
                <Typography variant="body1" className={classes.action}>
                    <span className={classNames({ [classes.hideText]: active })}>{t('quickScan.seeActions')}</span>
                    <span className={classNames({ [classes.hideText]: !active })}>{t('quickScan.actions')}</span>
                    <ChevronRight className={classNames(classes.arrow, { [classes.arrowActive]: active })} />
                </Typography>
            </div>

            <div className={classNames(classes.content, { [classes.contentActive]: active })}>
                <Typography className={classes.description} variant="body1">
                    {getMotivation(currentMood)} - {description}
                </Typography>
                <div className={classes.buttons}>
                    {callToActions && callToActions.map((cta, index) => <CallToActionButton cta={cta} key={index} />)}
                </div>
            </div>

            {previous && (
                <div className={classNames(classes.previous, { [classes.previousActive]: active })}>
                    {getSmile(previous.value)}{' '}
                    <Typography variant="body1">
                        {t('quickScan.previousResult')}{' '}
                        <span className={classes.date}> {Moment(previous.date).format('llll')}</span>
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default ResultPart;
