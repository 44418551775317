import React from 'react';
import { Typography, Table, TableCell, TableRow, TableHead, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SwipeWrapper } from 'shared';

const CanceledMeetings = (props) => {
    const { meetings, classes } = props;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography variant="h4" className={classes.meetingsHeader}>
                {t('dashboard.canceledMeetings')}
            </Typography>
            <SwipeWrapper>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>{t('dashboard.meetingCode')}</TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.maxWidth)}>
                                {t('dashboard.meetingUserName')}
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>{t('dashboard.meetingUserEmail')}</TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.maxWidth)}>
                                {t('dashboard.meetingNumber')}
                            </TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.hidden)}>
                                Datum gesprek
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {meetings.map((meeting) => (
                            <TableRow key={meeting.id}>
                                <TableCell className={classes.tableCell}>{meeting.code}</TableCell>
                                <TableCell className={classNames(classes.tableCell, classes.maxWidth)}>
                                    {meeting.owner.profile.fullName}
                                </TableCell>
                                <TableCell
                                    className={classNames(
                                        classes.tableCell,
                                        classes.tableCellPrimary,
                                        classes.maxWidth
                                    )}
                                >
                                    {meeting.owner.email}
                                </TableCell>
                                <TableCell className={classNames(classes.tableCell, classes.maxWidth)} colSpan={2}>
                                    {meeting.mappedType}
                                </TableCell>
                            </TableRow>
                        ))}
                        {meetings.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4} className={classes.tableCell}>
                                    Er zijn geen geannuleerde gesprekken.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </SwipeWrapper>
        </React.Fragment>
    );
};

export default CanceledMeetings;
