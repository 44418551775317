import { gql } from 'apollo-boost';

export const GQL_CITIES = gql`
    query ($filter: CityFilterInput!) {
        cities(pagination: { page: 0, pageSize: 50 }, filter: $filter) {
            cities {
                id
                name
            }
        }
    }
`;

export const GQL_CITY = gql`
    query ($id: Long!) {
        city(id: $id) {
            id
            name
        }
    }
`;
