import React from 'react';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
        backgroundColor: theme.palette.background.paper,
        maxWidth: '400px',
        minWidth: '344px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    typography: {
        fontWeight: 500
    },
    close: {},
    icons: {
        marginLeft: 'auto'
    },
    success: {
        backgroundColor: theme.palette.primary.main,

        '& $typography': {
            color: theme.palette.primary.contrastText
        },
        '& $close': {
            color: theme.palette.primary.contrastText
        }
    },
    error: {
        backgroundColor: theme.palette.error.lightdark
    },
    info: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary
    },
    warning: {
        backgroundColor: theme.palette.error.dark,

        '& $typography': {
            color: theme.palette.primary.contrastText
        },
        '& $close': {
            color: theme.palette.primary.contrastText
        }
    }
}));

const Snackbar =
    // eslint-disable-next-line react/display-name
    React.forwardRef((props, ref) => {
        const { message = '', variant = 'info' } = props;
        const classes = useStyles();
        const { closeSnackbar } = useSnackbar();

        const handleDismiss = () => {
            closeSnackbar(props.id);
        };

        return (
            <Card className={classNames(classes.root, classes[variant])} ref={ref}>
                <Typography variant="body1" className={classes.typography}>
                    {message}
                </Typography>
                <div className={classes.icons}>
                    <IconButton className={classes.expand} onClick={handleDismiss}>
                        <Close className={classes.close} />
                    </IconButton>
                </div>
            </Card>
        );
    });

export default Snackbar;
