import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { ArrowRight, Link } from 'shared';
import { MyFlowContainer } from 'shared';

import { useCurrentUser } from 'hooks';
import MyProgress from './MyProgress';

import background1 from 'assets/images/background-1.svg';
import background2 from 'assets/images/background-2.svg';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat`,

            [theme.breakpoints.up('md')]: {
                background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat,
                url(${background1}) bottom right / 250px no-repeat`
            }
        },
        container: {
            padding: `${theme.spacing(15)}px 0 ${theme.spacing(27.5)}px`
        },
        subHeader: {
            marginBottom: theme.spacing(5)
        },
        title: {
            marginBottom: theme.spacing(5)
        },
        block: {
            marginTop: theme.spacing(6.25)
        },
        blockLink: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: '1.125rem',
            fontWeight: 500,
            marginTop: `${theme.spacing(4)}px`,
            marginLeft: 'auto',
            color: theme.palette.primary.main,
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    '& $linkIcon': {
                        transform: 'translateX(10px)'
                    }
                }
            }
        },
        linkIcon: {
            width: '20px',
            marginLeft: `${theme.spacing(2)}px`,
            transition: 'transform 200ms'
        }
    }),
    { name: 'Dashboard' }
);

const Dashboard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { corporation, loading, careerTasks, careerWizzardStatus, userData } = useCurrentUser();

    const [currentRole, setCurrentRole] = useState(null);
    const rolesList = useSelector((state) => state.auth.user.roles);
    const roleIdx = Cookie.get('roleSelect') || 0;

    useEffect(() => {
        if (rolesList && rolesList[roleIdx] && rolesList[roleIdx].name) {
            setCurrentRole(rolesList[roleIdx].name);
        }
    }, [roleIdx, rolesList]);

    const infoBlock = () => (
        <React.Fragment>
            <Typography variant="h5" gutterBottom>
                {t('dashboard.myNextStepsTitle')}
            </Typography>
            <Typography>{t('dashboard.myNextStepsContent')}</Typography>
            <Link
                target="_blank"
                className={classes.blockLink}
                to={`${process.env.REACT_APP_FLOW_WEB_DOMAIN}/veelgestelde-vragen`}
            >
                {t('dashboard.needHelp')}
                <ArrowRight className={classes.linkIcon} />
            </Link>
        </React.Fragment>
    );

    const buttons = [
        {
            id: 1,
            color: 'quaternary',
            text: t('dashboard.my_quick_scan'),
            to: '/quick-scan'
        },
        {
            id: 2,
            color: 'primary',
            text: t('dashboard.wizard'),
            to: '/mijn-loopbaanwijzer'
        },
        {
            id: 3,
            color: 'secondary',
            text: t('dashboard.plan_of_tomorrow'),
            to: `${process.env.REACT_APP_TOMORROW_FLOW_DOMAIN}/planvoormorgen`,
            blank: true
        },
        {
            id: 4,
            color: 'tertiary',
            text: t('dashboard.myChanged'),
            to:
                currentRole === 'HR_USER'
                    ? `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}/hr?role=${Cookie.get('roleSelect')}`
                    : `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}?role=${Cookie.get('roleSelect')}`,
            extern: true
        }
    ];

    const accademyButtons = [
        {
            id: 1,
            color: 'quaternary',
            text: t('dashboard.my_quick_scan'),
            to: '/quick-scan'
        },
        {
            id: 2,
            color: 'primary',
            text: t('dashboard.wizard'),
            to: '/mijn-loopbaanwijzer'
        },
        {
            id: 5,
            color: 'purple',
            text: 'Mijn academie',
            to: 'https://decorporatieacademie.plusport.com/scripts/login.aspx',
            extern: true,
            blank: true
        },
        {
            id: 4,
            color: 'secondary',
            text: t('dashboard.myChanged'),
            to:
                currentRole === 'HR_USER'
                    ? `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}/hr?role=${Cookie.get('roleSelect')}`
                    : `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}?role=${Cookie.get('roleSelect')}`,
            extern: true
        },
        {
            id: 3,
            color: 'tertiary',
            text: t('dashboard.plan_of_tomorrow'),
            to: `${process.env.REACT_APP_TOMORROW_FLOW_DOMAIN}/planvoormorgen`,
            blank: true
        },
        {
            id: 6,
            color: 'grey',
            text: 'Mijn documenten',
            to: '/mijn-documenten'
        }
    ];

    return (
        <div className={classes.root}>
            <MyFlowContainer
                title={t('dashboard.title')}
                buttonsTitle={t('dashboard.subTitle')}
                buttons={corporation && corporation.hasAcademyUrl ? accademyButtons : buttons}
                renderInfoBox={infoBlock}
                introText={t('dashboard.introText')}
                loading={loading}
            >
                <MyProgress tasks={careerTasks} wizzardStatus={careerWizzardStatus} userData={userData} />
            </MyFlowContainer>
        </div>
    );
};

export default Dashboard;
