import { useEffect, useRef } from 'react';

/* eslint-disable */
const ChatBot = () => {
    const ryloth = useRef(undefined);

    useEffect(() => {
        window.RylothPromise.then(() => {
            ryloth.current = Ryloth.flowChatBot;
            ryloth.current.mount();
        });

        return () => {
            ryloth.current?.unmount();
        };
    }, []);
    return null;
};
/* eslint-enable */

export default ChatBot;
