import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        maxWidth: '1440px',
        bottom: '150px',
        display: 'flex',
        justifyContent: 'flex-end',
        pointerEvents: 'none',
        opacity: 0,
        visibility: 'hidden',
        zIndex: 10,
        transition: 'opacity 300ms, visibility 300ms',
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(3)
        }
    },
    visible: {
        opacity: 1,
        visibility: 'visible'
    },
    arrow: {
        width: '50px',
        height: '50px',
        borderRadius: '25px',
        border: `1px solid ${theme.palette.borderColor.main}`,
        background: theme.palette.background.default,
        color: theme.palette.text.secondary,
        marginLeft: `${theme.spacing(3)}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background 300ms, color 300ms, border-color 300ms'
    },
    text: {
        color: theme.palette.text.secondary,
        fontWeight: 500
    },
    button: {
        background: theme.palette.background.opacity,
        height: '60px',
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(4)}px`,
        pointerEvents: 'all',
        cursor: 'pointer',

        '&:hover grid': {
            borderColor: theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.background.default
        }
    }
}));

const ScrollToTop = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    });

    const checkScroll = () => {
        if (window.scrollY > 600) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const handleButton = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Grid className={classNames(classes.root, { [classes.visible]: scrolled })}>
            <Grid className={classes.button} onClick={handleButton}>
                <Typography variant="body1" className={classes.text}>
                    {t('scrollToTop.top')}
                </Typography>
                <Grid className={classes.arrow}>
                    <ArrowUpwardIcon />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ScrollToTop;
