import React from 'react';
import CustomIcon from './CustomIcon';

const SmileyBad = (props) => {
    return (
        <CustomIcon width="28" height="28" {...props}>
            <g fill="#E25959" fillRule="nonzero">
                <path
                    d="M14 1C6.832 1 1 6.832 1 14s5.832 13 13 13c7.169 0 13-5.832 13-13S21.169 1 14 1zm0 24.87C7.455 25.87 2.13 20.545 2.13 14 2.13 7.455 7.455 2.13 14 2.13c6.545 0 11.87 5.325 11.87 11.87 0 6.545-5.325 11.87-11.87 11.87z"
                    stroke="#E25959"
                    strokeWidth=".5"
                />
                <path d="M11.174 11.74c0-.936-.76-1.697-1.696-1.697-.935 0-1.695.761-1.695 1.696 0 .935.76 1.696 1.695 1.696s1.696-.761 1.696-1.696zM18.522 10.043c-.935 0-1.696.761-1.696 1.696 0 .935.76 1.696 1.696 1.696.935 0 1.695-.761 1.695-1.696 0-.935-.76-1.696-1.695-1.696z" />
                <path
                    d="M14 16.826c-2.103 0-4.11.893-5.507 2.449a.565.565 0 10.84.755A6.277 6.277 0 0114 17.957c1.787 0 3.488.755 4.666 2.072a.563.563 0 00.798.045.566.566 0 00.044-.798A7.391 7.391 0 0014 16.826z"
                    stroke="#E25959"
                    strokeWidth=".5"
                />
            </g>
        </CustomIcon>
    );
};

export default SmileyBad;
