import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import background1 from 'assets/images/background-1.svg';
import background2 from 'assets/images/background-2.svg';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MyFlowContainer } from 'shared';
import { ExpansionPanel, PersonBlock } from 'shared';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat`,

            [theme.breakpoints.up('md')]: {
                background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat,
                url(${background1}) bottom right / 250px no-repeat`
            }
        },
        container: {
            maxWidth: '1300px',
            width: '100%',
            padding: `${theme.spacing(12.5)}px auto`,
            display: 'flex',
            justifyContent: 'space-between'
        },
        title: {
            paddingBottom: `${theme.spacing(5)}px`
        },
        subTitle: {
            paddingBottom: `${theme.spacing(3.75)}px`
        },
        block: {
            padding: `${theme.spacing(5)}px`
        },
        sidebar: {
            marginTop: `${theme.spacing(5.5)}px`,
            maxWidth: '470px',
            position: 'relative',
            zIndex: 2
        },
        faqContent: {
            maxWidth: '550px'
        },
        background: {
            position: 'absolute',
            height: '650px',
            width: '650px',
            top: '-320px',
            left: '-60px',
            zIndex: '-1'
        },
        panel: {
            '&:first-of-type': {
                borderTop: 'none'
            }
        }
    }),
    { name: 'Support' }
);

const Support = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [expanded] = useState(false);

    const person = {
        name: 'FLOW',
        phone: '036-5402304',
        mail: 'info@flowweb.nl'
    };

    return (
        <div className={classes.root}>
            <MyFlowContainer
                title={t('support.title')}
                subTitle={t('support.subTitle')}
                renderInfoBox={() => (
                    <PersonBlock person={person} title={t('support.sidebarTitle')} avatar={false} noEdit={true} />
                )}
                renderAdditional={() => (
                    <React.Fragment>
                        <ExpansionPanel
                            className={classes.panel}
                            title={t('support.faqQuestionOne')}
                            variant="large"
                            expanded={true}
                            icon={<ExpandMoreIcon />}
                        >
                            <Typography variant="body1" className={classes.faqContent}>
                                {t('support.faqAnswerOne')}
                            </Typography>
                        </ExpansionPanel>
                        <ExpansionPanel
                            title={t('support.faqQuestionTwo')}
                            variant="large"
                            expanded={expanded}
                            icon={<ExpandMoreIcon />}
                        >
                            <Typography variant="body1" className={classes.faqContent}>
                                {t('support.faqAnswerTwo')}
                            </Typography>
                        </ExpansionPanel>
                        <ExpansionPanel
                            title={t('support.faqQuestionThree')}
                            variant="large"
                            expanded={expanded}
                            icon={<ExpandMoreIcon />}
                        >
                            <Typography variant="body1" className={classes.faqContent}>
                                {t('support.faqAnswerThree')}
                            </Typography>
                        </ExpansionPanel>
                        <ExpansionPanel
                            title={t('support.faqQuestionFour')}
                            variant="large"
                            expanded={expanded}
                            icon={<ExpandMoreIcon />}
                        >
                            <Typography variant="body1" className={classes.faqContent}>
                                {t('support.faqQuestionFour')}
                            </Typography>
                        </ExpansionPanel>
                    </React.Fragment>
                )}
            ></MyFlowContainer>
        </div>
    );
};

export default Support;
