import React from 'react';
import CustomIcon from './CustomIcon';

const UnknownFile = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 317.001 317.001' }}>
        <path d="M270.825 70.55L212.17 3.66A10.743 10.743 0 00204.093 0H55.941C49.076 0 43.51 5.566 43.51 12.431V304.57c0 6.866 5.566 12.431 12.431 12.431h205.118c6.866 0 12.432-5.566 12.432-12.432V77.633c0-2.606-.947-5.123-2.666-7.083zM55.941 305.073V12.432H199.94v63.601c0 3.431 2.78 6.216 6.216 6.216h54.903l.006 222.824H55.941z" />
    </CustomIcon>
);

export default UnknownFile;
