import React from 'react';
import CustomIcon from './CustomIcon';

const JpegFile = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801' }}>
        <path d="M475.095 131.992c-.032-2.526-.844-5.021-2.579-6.993L366.324 3.694c-.031-.034-.062-.045-.084-.076-.633-.707-1.371-1.29-2.151-1.804-.232-.15-.464-.285-.707-.422-.675-.366-1.382-.67-2.13-.892-.201-.058-.38-.14-.58-.192C359.87.114 359.037 0 358.193 0H97.2C85.292 0 75.6 9.693 75.6 21.601v507.6c0 11.913 9.692 21.601 21.6 21.601h356.4c11.908 0 21.601-9.688 21.601-21.601V133.202c-.001-.406-.064-.804-.106-1.21zm-291.824 341.32c0 37.314-17.89 50.34-46.651 50.34-6.803 0-15.747-1.165-21.576-3.111l3.309-23.909c4.082 1.36 9.326 2.341 15.159 2.341 12.445 0 20.216-5.643 20.216-26.051v-82.413h29.544v82.803zm106.726-11.854c-10.114 9.523-25.083 13.795-42.57 13.795-3.892 0-7.391-.189-10.114-.58v46.85h-29.352V392.26c9.141-1.561 21.966-2.721 40.043-2.721 18.27 0 31.298 3.496 40.046 10.494 8.358 6.612 13.99 17.486 13.99 30.322.001 12.825-4.27 23.714-12.043 31.103zm140.347 54.031c-9.133 3.111-26.436 7.389-43.743 7.389-23.898 0-41.206-6.028-53.245-17.683-12.056-11.279-18.668-28.381-18.468-47.629.189-43.538 31.867-68.418 74.824-68.418 16.923 0 29.943 3.301 36.351 6.418l-6.218 23.715c-7.193-3.111-16.132-5.633-30.518-5.633-24.68 0-43.337 13.996-43.337 42.378 0 27.016 16.906 42.957 41.207 42.957 6.803 0 12.244-.785 14.575-1.951v-27.4h-20.218v-23.13h48.789v68.987zM97.2 366.752V21.601h250.192v110.515c0 5.961 4.842 10.8 10.801 10.8H453.6v223.836H97.2z" />
        <path d="M249.56 411.888c-6.02 0-10.101.58-12.248 1.171v38.675c2.534.58 5.646.78 9.917.78 15.746 0 25.471-7.973 25.471-21.378 0-12.056-8.364-19.248-23.14-19.248zM246.04 243.448C202.906 200.301 161.077 367.2 161.077 367.2h143.759s-15.641-80.578-58.796-123.752zM350.673 287.978c-30.575 7.82-45.837 79.223-45.837 79.223h103.096c-13.995-35.775-31.145-85.905-57.259-79.223zM161.088 204.989c14.773 0 26.747-11.965 26.747-26.747 0-14.778-11.973-26.733-26.747-26.733-14.797 0-26.768 11.955-26.768 26.733 0 14.782 11.971 26.747 26.768 26.747zM323.293 222.184c5.4 0 10.452-.557 14.945-1.464 5.231 2.247 11.591 3.592 18.51 3.592 17.671 0 32.015-8.651 32.015-19.312 0-10.671-14.338-19.316-32.015-19.316-6.645 0-12.793 1.215-17.919 3.301-2.089-2.02-4.915-3.301-8.042-3.301h-4.636c-5.938 0-10.642 4.498-11.316 10.251-14.354 1.49-24.976 6.669-24.976 12.873-.005 7.393 14.961 13.376 33.434 13.376z" />
    </CustomIcon>
);

export default JpegFile;
