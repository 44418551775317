import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, Pencil } from 'shared';
import LocationIcon from '@material-ui/icons/MyLocation';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/MailOutline';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '640px'
    },
    title: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    body: {
        color: theme.palette.text.primaryLight
    },
    link: {
        width: '100%',
        fontSize: '18px',
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'flex-end'
    },
    innerLink: {
        marginRight: theme.spacing(2)
    },
    personInfo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '26px'
    },
    advisorName: {
        fontWeight: 500
    },
    editIcon: {
        '&.MuiButton-outlined': {
            minWidth: 0,
            marginLeft: 'auto',
            padding: `${theme.spacing(1.5)}px`
        },
        '& svg': {
            width: '16px',
            height: '16px'
        }
    },
    image: {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        backgroundColor: theme.palette.background.secondary,
        marginRight: '20px',
        overflow: 'hidden',

        [theme.breakpoints.up('sm')]: {
            width: '120px',
            height: '120px'
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    city: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        color: theme.palette.text.secondary
    },
    job: {
        color: theme.palette.text.normal
    },
    locationIcon: {
        marginRight: '10px',
        color: theme.palette.text.secondary,
        opacity: '.5',
        display: 'flex',
        alignItems: 'center'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    meetingButton: {
        marginRight: theme.spacing(1)
    },
    phoneButton: {
        marginBottom: theme.spacing(1.875),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(0)
        }
    }
}));

const PersonBlock = (props) => {
    const { person, title, text, noEdit = false } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const isPlanPath = history.location.pathname.includes('gesprek-plannen');

    const handleEdit = () => {
        history.push('/mijn-loopbaanwijzer/loopbaanadviseur-kiezen');
    };

    return (
        <div className={classes.root}>
            <Typography variant="h5" className={classes.title}>
                {title || ''}
                {!noEdit && (
                    <Button
                        variant="outlined"
                        className={classes.editIcon}
                        iconLeft={() => <Pencil />}
                        onClick={handleEdit}
                    />
                )}
            </Typography>
            {text && <Typography variant="body1">{text}</Typography>}
            {person && (
                <>
                    <div className={classes.personInfo}>
                        {person.avatar && person.avatar.url && (
                            <div className={classes.image} alt={person.name || 'Person'}>
                                <img src={person.avatar.url} alt="Avatar" />
                            </div>
                        )}
                        <div className={classes.info}>
                            <Typography variant="h6">{person.name || ''}</Typography>

                            {person.city && (
                                <Typography className={classes.city}>
                                    <span className={classes.locationIcon}>
                                        <LocationIcon />
                                    </span>
                                    {person.city}
                                </Typography>
                            )}
                            {person.job && <Typography className={classes.job}>{person.job}</Typography>}
                        </div>
                    </div>
                    <div className={classes.buttons}>
                        {!isPlanPath && (
                            <Button
                                label="Plan gesprek"
                                className={classes.meetingButton}
                                component={Link}
                                to="/mijn-loopbaanwijzer/gesprek-plannen"
                                variant="outlined"
                            />
                        )}
                        {person.phone && (
                            <Button
                                variant="outlined"
                                className={classes.phoneButton}
                                label={person.phone || ''}
                                iconLeft={() => <PhoneIcon />}
                                href={`tel:${person.phone}`}
                            />
                        )}
                        {person.mail && (
                            <Button
                                variant="outlined"
                                label={t('general.emailing')}
                                iconLeft={() => <MailIcon />}
                                href={`mailto:${person.mail}`}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default PersonBlock;
