import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import CareerGuide from 'components/CareerGuide';
import QuickScan from 'components/QuickScan';
import CareerGuideAdvisor from 'components/CareerGuideAdvisor';
import Dashboard from 'components/Dashboard';
import Disclaimer from 'components/Disclaimer';
import Documents from 'components/Documents';
import Cookies from 'components/Cookies';
import PlanMeeting from 'components/PlanMeeting';
import Privacy from 'components/Privacy';
import Profile from 'components/Profile';
import Support from 'components/Support';
import CareerAdvisorMeetings from 'components/CareerAdvisorMeetings';
import { ChangeEmail } from 'shared';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: {
        ...theme.mixins.toolbar,
        height: '80px'
    },
    page: {
        position: 'relative',
        left: '15px',
        right: '15px'
    },
    pageEnter: {
        opacity: 0,
        transform: 'translateX(5%)'
    },
    pageEnterActive: {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'opacity 300ms, transform 300ms'
    },
    pageExit: {
        opacity: 1,
        transform: 'translateX(0)'
    },
    pageExitActive: {
        opacity: 0,
        transform: 'translateX(-5%)',
        transition: 'opacity 300ms, transform 300ms'
    }
}));

const App = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppHeader />
            <div className={classes.appBarSpacer} />
            <main>
                <Switch>
                    <Route path="/disclaimer" component={Disclaimer} />
                    <Route path="/mijn-documenten" component={Documents} />
                    <Route exact path="/quick-scan" component={QuickScan} />
                    <Route exact path="/mijn-loopbaanwijzer" component={CareerGuide} />
                    <Route path="/mijn-loopbaanwijzer/gesprek-plannen" component={PlanMeeting} />
                    <Route path="/mijn-loopbaanwijzer/loopbaanadviseur-kiezen" component={CareerGuideAdvisor} />
                    <Route path="/mijn-gegevens" component={Profile} />
                    <Route path="/cookies" component={Cookies} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/help" component={Support} />
                    <Route path="/gesprekken/overzicht" component={CareerAdvisorMeetings} />
                    <Route path="/" exact component={Dashboard} />

                    {/* email wijzigen routes */}
                    <Route exact path="/email-wijzigen" component={ChangeEmail} />
                </Switch>
            </main>
            <AppFooter />
        </React.Fragment>
    );
};

export default App;
