import React from 'react';
import CustomIcon from './CustomIcon';

const CsvFile = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801' }}>
        <path d="M475.084 131.992c-.021-2.521-.828-5.021-2.562-6.993L366.324 3.689c-.031-.035-.062-.045-.084-.077-.633-.706-1.371-1.284-2.151-1.803-.232-.145-.464-.285-.707-.422a11.187 11.187 0 00-2.13-.886c-.201-.063-.38-.145-.58-.197-.802-.19-1.635-.304-2.479-.304H97.2C85.282 0 75.6 9.693 75.6 21.601v507.6c0 11.907 9.682 21.601 21.6 21.601h356.4c11.908 0 21.601-9.693 21.601-21.601V133.202c-.001-.411-.064-.809-.117-1.21zm-293.983 363.68c8.931 0 18.845-1.94 24.677-4.271l4.472 23.129c-5.444 2.722-17.684 5.643-33.626 5.643-45.291 0-68.618-28.191-68.618-65.506 0-44.709 31.88-69.589 71.529-69.589 15.356 0 27.021 3.106 32.276 5.833l-6.033 23.514c-6.02-2.525-14.386-4.846-24.877-4.846-23.517 0-41.784 14.186-41.784 43.331-.001 26.245 15.545 42.762 41.984 42.762zm80.675 24.5c-14.964 0-29.742-3.892-37.125-7.973l6.02-24.49c7.976 4.082 20.218 8.163 32.854 8.163 13.605 0 20.798-5.643 20.798-14.196 0-8.163-6.217-12.824-21.963-18.457-21.766-7.583-35.955-19.627-35.955-38.675 0-22.359 18.658-39.467 49.565-39.467 14.771 0 25.66 3.106 33.428 6.613l-6.602 23.909c-5.258-2.531-14.576-6.223-27.412-6.223-12.825 0-19.045 5.833-19.045 12.636 0 8.369 7.38 12.055 24.303 18.468 23.124 8.559 34.014 20.598 34.014 39.065-.011 21.959-16.918 40.627-52.88 40.627zm138.404-1.956h-34.594L323.6 387.213h32.463L372 442.616c4.472 15.546 8.554 30.512 11.665 46.844h.58c3.301-15.747 7.383-31.298 11.854-46.259l16.717-55.982h31.493L400.18 518.216zM97.2 366.752V21.601h250.192v110.521c0 5.962 4.842 10.8 10.801 10.8H453.6v223.837H97.2v-.007z" />
        <path d="M377.188 170.058H177.579V289.27H377.311v-92.688h-.122v-26.524zm-133.46 116.913H179.89v-26.927h63.837v26.927zm0-29.229H179.89v-26.924h63.837v26.924zm0-29.236H179.89v-26.913h63.837v26.913zm1.163-29.225v-26.913h63.838v26.913h-63.838zm129.713 87.288h-25.207v-77.098H332.09v77.098h-14.418v-61.13h-17.308v61.13h-12.698v-45.362h-17.31v45.362h-24.321v-84.976H374.603v84.976zm.274-89.988h-.274v2.7h-63.565v-26.913h63.84v24.213z" />
    </CustomIcon>
);

export default CsvFile;
