import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import { ROLE_NAMES } from 'const';

import { makeStyles } from '@material-ui/styles';

import { MyFlowContainer } from 'shared';

import Meetings from './Meetings';
import { GQL_GET_CA_MEETINGS } from './graphql';
import background1 from 'assets/images/background-1.svg';
import background2 from 'assets/images/background-2.svg';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat`,

            [theme.breakpoints.up('md')]: {
                background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat,
                url(${background1}) bottom right / 250px no-repeat`
            }
        },
        container: {
            padding: `${theme.spacing(15)}px 0 ${theme.spacing(27.5)}px`
        },
        subHeader: {
            marginBottom: theme.spacing(5)
        },
        title: {
            marginBottom: theme.spacing(5)
        },
        block: {
            marginTop: theme.spacing(6.25)
        },
        blockLink: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: '1.125rem',
            fontWeight: 500,
            marginTop: `${theme.spacing(4)}px`,
            marginLeft: 'auto',
            color: theme.palette.primary.main,
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    '& $linkIcon': {
                        transform: 'translateX(10px)'
                    }
                }
            }
        },
        linkIcon: {
            width: '20px',
            marginLeft: `${theme.spacing(2)}px`,
            transition: 'transform 200ms'
        },
        link: {
            display: 'inline-block',
            background: theme.palette.secondary.main,
            padding: '12px 24px',
            borderRadius: '35px',
            textDecoration: 'none',
            color: theme.palette.text.secondary,
            fontSize: '18px',
            marginTop: theme.spacing(2),
            fontWeight: 500
        }
    }),
    { name: 'Dashboard' }
);

const Dashboard = () => {
    const classes = useStyles();
    const currentUser = useSelector((state) => state.auth.user);
    const currentUserRole = useSelector((state) => state.auth.role);

    const [loadMeetings, { meetingsLoading, data: meetingData = {} }] = useLazyQuery(GQL_GET_CA_MEETINGS, {
        fetchPolicy: 'no-cache',
        variables: { careerAdvisor: currentUser.id }
    });
    const { meetings = {} } = meetingData;

    // `useSelector` is async, this method checks whether we have the user ID required to succesfully fetch the meetings for the current user.
    const loadMeetingsIfCurrentUserIsKnown = useCallback(() => {
        if (currentUser && currentUser.id) {
            loadMeetings({ variables: { careerAdvisor: currentUser.id } });
        }
    }, [currentUser, loadMeetings]);

    const handleRefetch = () => {
        loadMeetingsIfCurrentUserIsKnown();
    };

    useEffect(() => {
        loadMeetingsIfCurrentUserIsKnown();
    }, [currentUser, loadMeetingsIfCurrentUserIsKnown]);

    return (
        <div className={classes.root}>
            <MyFlowContainer
                title="Mijn loopbaangesprekken"
                buttonsTitle=""
                buttons={[]}
                introText="Zodra iemand een verzoek voor een loopbaangesprek met jou in Mijn Loopbaanwijzer verzendt, komt hij of zij hieronder te staan en krijg jij een mail. Gaat het gesprek niet door? Pas dat dan meteen aan, zodat de aanvrager een ander gesprek kan inplannen. Is het gesprek gevoerd? Geef dit dan ook aan. Veel succes met de gesprekken!"
                renderAdditional={() => (
                    <a
                        className={classes.link}
                        href="https://flowweb.nl/vragen-dashboard-gesprekken"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Vragen?
                    </a>
                )}
                loading={meetingsLoading}
            >
                {currentUserRole === ROLE_NAMES.CAREER_ADVISOR && (
                    <Meetings
                        {...currentUser}
                        meetings={meetings && meetings.meetings ? meetings.meetings : []}
                        handleRefetch={handleRefetch}
                    />
                )}
            </MyFlowContainer>
        </div>
    );
};

export default Dashboard;
