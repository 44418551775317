import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import background1 from 'assets/images/background-1.svg';
import background2 from 'assets/images/background-2.svg';
import MeterContainer from './MeterContainer';
import ResultPart from './ResultPart';
import { GET_METER_RESULT, GET_METER_URL, SAVE_YMERE } from './graphql';
import parseHroQuickScanScore from './parser.js';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, Loader, Download, Redo, Hexa, Link } from 'shared';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            minHeight: '500px',
            position: 'relative',
            background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat`,

            [theme.breakpoints.up('md')]: {
                background: `url(${background2}) calc(50vw + 90px) -60px / 660px no-repeat,
                url(${background1}) bottom right / 250px no-repeat`
            }
        },
        loader: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        title: {
            marginBottom: theme.spacing(5)
        },
        blockTitle: {
            fontWeight: 600,
            marginBottom: theme.spacing(2)
        },
        resultLink: {
            display: 'inline-flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: '1.125rem',
            fontWeight: 500,
            marginTop: theme.spacing(1),
            marginRight: 'auto',
            padding: theme.spacing(1.25, 2.5),

            '&:hover': {
                '& .MuiSvgIcon-root': {
                    opacity: 1
                }
            }
        },
        resultIcon: {
            width: '28px',
            height: '28px',
            opacity: 0.5
        },
        blockLink: {
            display: 'inline-flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: '1.125rem',
            fontWeight: 500,
            marginTop: theme.spacing(2),
            background: theme.palette.background.secondary,
            padding: theme.spacing(1.25, 2.5),

            '&:hover': {
                background: theme.palette.background.secondaryContrast,

                '& .MuiSvgIcon-root': {
                    opacity: 1
                }
            }
        },
        linkIcon: {
            width: '22px',
            height: '22px',
            opacity: '0.5',
            transition: 'opacity 300ms, color 300ms'
        },
        redoWrap: {
            padding: theme.spacing(2, 4, 10),
            margin: '0 auto',
            width: '100%',
            maxWidth: '1340px',

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2, 4, 18)
            }
        },
        redoButton: {
            marginRight: 'auto',
            padding: theme.spacing(1, 2.5),
            color: theme.palette.primary.main,

            '&:hover .MuiSvgIcon-root': {
                color: theme.palette.primary.contrastText
            }
        },
        redoIcon: {
            width: '18px',
            marginRight: `${theme.spacing(2)}px`
        }
    }),
    { name: 'Quickscan' }
);

const QuickScan = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { data: meterUrl = {} } = useQuery(GET_METER_URL);

    const [currentScore, setCurrentScore] = useState(null);
    const [instrumentScores, setInstrumentScores] = useState({});
    const [allScores, setAllScores] = useState({});

    const currentUser = useSelector((state) => state.auth.user);
    const [saveYmere] = useMutation(SAVE_YMERE);

    const { loading, data = {} } = useQuery(GET_METER_RESULT, {
        fetchPolicy: 'no-cache'
    });

    const { getQuickscanResultsForCurrentUser: meterResult = {} } = data;
    const { getQuickscanUrlForCurrentUser: definitiveUrl = {} } = meterUrl;

    const showSaveYmere = currentUser.email?.indexOf('@ymere.nl') > 0;

    const handleSaveYmere = () => {
        saveYmere()
            .then((r) => {
                if (r.errors) {
                    enqueueSnackbar({
                        variant: 'warning',
                        message: t('quickScan.saveYmereError')
                    });
                } else {
                    enqueueSnackbar({
                        variant: 'success',
                        message: t('quickScan.saveYmereSuccess')
                    });
                }
            })
            .catch((e) => {
                enqueueSnackbar({
                    variant: 'warning',
                    message: t('quickScan.saveYmereError')
                });
            });
    };

    useEffect(() => {
        if (meterResult && !loading) {
            if (meterResult) {
                const { scores = {} } = meterResult;

                if (scores) {
                    const { instrumentScores = {} } = scores;
                    if (instrumentScores) {
                        setInstrumentScores(instrumentScores);
                        setAllScores(parseHroQuickScanScore(meterResult));
                    }
                } else if (!currentScore) {
                    redoRedirect();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meterResult, loading, currentScore]);

    useEffect(() => {
        if (instrumentScores.length) {
            // Sort the scores based on their dates, pick the first one in the resulting array as that has the most recent date
            const current = instrumentScores.sort(
                (scoreOne, scoreTwo) => new Date(scoreTwo.lastChangeUtc) - new Date(scoreOne.lastChangeUtc)
            )[0];
            setCurrentScore(current);
        }
    }, [instrumentScores]);

    const redoRedirect = () => {
        if (meterUrl.getQuickscanUrlForCurrentUser != null) {
            window.location.href = meterUrl.getQuickscanUrlForCurrentUser.url;
        } else {
            console.log('no url');
        }
    };

    const redoClick = () => {
        window.location.href = meterUrl.getQuickscanUrlForCurrentUser.url;
    };

    const infoBlock = () => (
        <React.Fragment>
            <Typography variant="h5" className={classes.blockTitle}>
                {t('quickScan.myResult')}
            </Typography>

            {meterResult.spiderweb && (
                <Button
                    color="primary"
                    variant="outlined"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.resultLink}
                    label={t('quickScan.resultButton')}
                    href={meterResult.spiderweb.url}
                    download={meterResult.spiderweb.name}
                    iconRight={() => <Hexa className={classes.resultIcon} />}
                />
            )}

            {meterResult.conversationPlan && (
                <Button
                    color="primary"
                    variant="outlined"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.resultLink}
                    label={t('quickScan.resultDownloadButton')}
                    href={meterResult.conversationPlan.url}
                    download={meterResult.conversationPlan.name}
                    iconRight={() => <Download className={classes.downloadIcon} />}
                />
            )}

            {showSaveYmere && (
                <Button
                    color="primary"
                    variant="outlined"
                    rel="noreferrer noopener"
                    onClick={handleSaveYmere}
                    className={classes.resultLink}
                    label={t('quickScan.saveYmere')}
                />
            )}
        </React.Fragment>
    );

    return (
        <div className={classes.root}>
            {loading ? (
                <Loader className={classes.loader} />
            ) : currentScore ? (
                currentScore.metaScores.length ? (
                    <React.Fragment>
                        <MeterContainer
                            title={t('quickScan.title')}
                            result={currentScore ? currentScore.logicalInterpretation : ''}
                            renderInfoBox={meterResult.spiderweb ? infoBlock : ''}
                            introText={t('quickScan.introText')}
                        >
                            {Object.entries(allScores).map(([key, _]) => {
                                return (
                                    <ResultPart
                                        key={key}
                                        title={allScores[key].name}
                                        currentMood={allScores[key].value}
                                        description={allScores[key].interpretation}
                                        callToActions={allScores[key].ctas ? allScores[key].ctas : []}
                                        previous={allScores[key].previous ? allScores[key].previous : ''}
                                        activeState={true}
                                    />
                                );
                            })}
                        </MeterContainer>
                        <div className={classes.redoWrap}>
                            <Typography variant="h5" className={classes.blockTitle}>
                                {t('quickScan.newMetingTitle')}
                            </Typography>
                            <Button
                                color="primary"
                                variant="outlined"
                                component={Link}
                                target="_blank"
                                className={classes.redoButton}
                                label={t('quickScan.newMetingButton')}
                                onClick={redoClick}
                                iconLeft={() => <Redo className={classes.redoIcon} />}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    redoRedirect()
                )
            ) : definitiveUrl ? (
                <Loader className={classes.loader} />
            ) : (
                redoRedirect()
            )}
        </div>
    );
};

export default QuickScan;
