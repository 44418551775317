import React, { useState } from 'react';
import { Typography, Table, TableCell, TableRow, TableHead, TableBody } from '@material-ui/core';
import { SwipeWrapper } from 'shared';
import { useTranslation } from 'react-i18next';
import MeetingRow from './MeetingRow';
import DatePicker from './MeetingDatePicker';
import classNames from 'classnames';

const FinishedMeetings = (props) => {
    const { meetings, classes, updateMeeting } = props;
    const { t } = useTranslation();

    const [currentMeeting, setCurrentMeeting] = useState({});
    const [showDatePicker, setShowDatePicker] = useState(false);

    const openDatePicker = () => {
        setShowDatePicker(true);
    };

    const handleDateChange = (date) => {
        setShowDatePicker(false);
        updateMeeting({
            id: currentMeeting.id,
            ownerId: currentMeeting.owner.id,
            status: date ? 'FINISHED' : 'PLANNED',
            finishedOn: date || null,
            message: t('dashboard.meetingUpdatedMessage')
        });
    };

    return (
        <React.Fragment>
            <Typography variant="h4" className={classes.meetingsHeader}>
                {t('dashboard.finishedMeetings')}
            </Typography>
            <SwipeWrapper>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>{t('dashboard.meetingCode')}</TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.maxWidth)}>
                                {t('dashboard.meetingUserName')}
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>{t('dashboard.meetingUserEmail')}</TableCell>
                            <TableCell className={classNames(classes.tableHeadCell, classes.maxWidth)}>
                                {t('dashboard.meetingNumber')}
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                                {t('dashboard.meetingFinishedDate')}
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {meetings.map((meeting) => (
                            <MeetingRow
                                key={meeting.id}
                                openDatePicker={openDatePicker}
                                setCurrentMeeting={setCurrentMeeting}
                                updateMeeting={updateMeeting}
                                classes={classes}
                                meeting={meeting}
                                editMode={showDatePicker}
                            />
                        ))}
                        {meetings.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={5} className={classes.tableCell}>
                                    Er zijn geen gevoerde gesprekken.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </SwipeWrapper>
            <DatePicker
                classes={{ root: classes.datePicker }}
                open={showDatePicker}
                onClose={() => setShowDatePicker(false)}
                onChange={handleDateChange}
                disablePast={false}
                openTo="date"
            />
        </React.Fragment>
    );
};

export default FinishedMeetings;
