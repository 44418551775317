import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { RetryLink } from 'apollo-link-retry';

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore'
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    },
    mutate: {
        errorPolicy: 'all'
    }
};

const customFetch = (uri, options) => {
    return fetch(uri, {
        ...options,
        headers: {
            ...options.headers,
            'x-language': 'nl-NL' || 'default'
        }
    });
};

const errorLink = onError(({ response, operation, graphQLErrors, networkError }) => {
    if (networkError) {
        console.log('networkError', networkError);
    } else if (graphQLErrors) {
        console.log('graphQLErrors', graphQLErrors);
    }
});

const retryLink = new RetryLink();

const httpLink = ApolloLink.from([
    retryLink,
    errorLink,
    new BatchHttpLink({
        batchInterval: 20,
        credentials: 'include',
        fetch: customFetch
    })
]);

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    fetch: customFetch
});

const link = ApolloLink.split((operation) => operation.getContext().hasUpload, uploadLink, httpLink);

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false
    }),
    defaultOptions,
    link
});

export default client;
