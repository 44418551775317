import gql from 'graphql-tag';

const TRIGGER_RESULTS = gql`
    query TriggerResults {
        triggerResults {
            id
            triggerId
            viewDate
            vacancyId
            type
            createdDate
            title
            body
        }
    }
`;

const UPDATE_ALERT = gql`
    mutation updateTriggerResult($input: TriggerResultInput!) {
        updateTriggerResult(input: $input) {
            viewed
        }
    }
`;

const DELETE_ALERT = gql`
    mutation deleteTriggerResult($input: TriggerResultInput!) {
        deleteTriggerResult(input: $input) {
            deleted
        }
    }
`;

export { TRIGGER_RESULTS };
export { UPDATE_ALERT };
export { DELETE_ALERT };
