import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.secondary.main
    },
    textField: {
        border: 'none',
        borderRadius: '3px',
        marginTop: `${theme.spacing(3.125)}px`
    },
    inputElement: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '3px',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(1.875)}px`,
        background: theme.palette.background.default,
        '&:before, &:after': {
            display: 'none'
        },
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: '0 0 0 4px #E7DCEC'
        }
    },
    inputLabel: {
        position: 'relative',
        fontSize: '1.125rem',
        fontWeight: 500,
        transform: 'none',
        userSelect: 'none',
        paddingBottom: `${theme.spacing(0.5)}px`,
        '& + $inputElement': {
            marginTop: theme.spacing(1)
        }
    },
    optionalLabel: {
        fontSize: '1rem',
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        marginLeft: theme.spacing(1)
    }
}));

const InputField = (props) => {
    const {
        className,
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        form = false,
        id,
        optional = false,
        label = false,
        name,
        onChange,
        placeholder,
        rows,
        type,
        initialValue = '',
        ...otherProps
    } = props;
    const classes = useStyles(className);

    const [error, setError] = useState(errorFromProps);
    const [errorMessage, setErrorMessage] = useState(errorMessageFromProps);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setError(errorFromProps);
        setErrorMessage(errorMessageFromProps);
    }, [errorFromProps, errorMessageFromProps]);

    useEffect(() => {
        if (form) form.onFieldChange({ key: name, value: initialValue });
        setValue(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (form.errors && form.errors[name] && form.errors[name].length) {
            setError(true);
            setErrorMessage(form.errors[name].join(','));
        } else {
            setError(false);
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const handleChange = (event) => {
        if (form) form.onFieldChange({ key: name, value: event.target.value, smooth: true });
        setValue(event.target.value);

        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <TextField
            id={id}
            label={
                label ? (
                    <React.Fragment>
                        {label}
                        {optional && <span className={classes.optionalLabel}>(optioneel)</span>}
                    </React.Fragment>
                ) : (
                    false
                )
            }
            className={classNames(classes.textField, className)}
            error={error}
            helperText={error ? errorMessage : ''}
            InputLabelProps={{
                shrink: false,
                className: classes.inputLabel
            }}
            InputProps={{
                className: classes.inputComponent,
                disableUnderline: true
            }}
            inputProps={{
                className: classes.inputElement
            }}
            FormHelperTextProps={{
                className: classes.helperText
            }}
            name={name}
            margin="normal"
            multiline={!!rows}
            onChange={handleChange}
            placeholder={placeholder}
            rows={rows}
            type={type}
            value={value}
            {...otherProps}
        />
    );
};

export default InputField;
