import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Cookie from 'js-cookie';
import { Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    dialogActionsRoot: {
        flexFlow: 'row wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    }
}));

const FirstTimePopup = (props) => {
    const hadPopup = Cookie.get('popup');
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(!hadPopup);

    const handleClose = (redirect) => () => {
        setOpen(false);
        Cookie.set('popup', true, { expires: 365 });

        if (redirect) {
            history.push(redirect);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Typography>
                    Op het nieuwe Kansenportaal kun je vanaf vandaag inloggen met je corporatie e-mailadres. Het nieuwe
                    Kansenportaal heeft niet alleen een nieuwe look en feel, het is veel gebruiksvriendelijker en je
                    hebt gelijk al je leer-en loopbaanactiviteiten op 1 plek. Ervaar het zelf en stel opnieuw je
                    wachtwoord in.
                </Typography>
                <br />
                <Typography>
                    Had je op het oude Kansenportaal een e-mailadres dat niet van een corporatie is, bijvoorbeeld je
                    privé-mailadres? Dan kun je daar helaas niet meer mee inloggen. Je zult je dan alsnog opnieuw moeten
                    aanmelden.
                </Typography>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                <Button onClick={handleClose('/register')} color="primary">
                    Aanmelden
                </Button>
                <Button onClick={handleClose('/register/forgot-password')} color="primary">
                    Wachtwoord instellen
                </Button>
                <Button onClick={handleClose(false)} color="secondary">
                    Annuleren
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FirstTimePopup;
