import React from 'react';
import CustomIcon from './CustomIcon';

const Notification = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 14 17.46' }}>
        <g data-name="nav/kp/2/alert">
            <g data-name="nav/mijnflow/dashboard">
                <g data-name="nav/dashboard">
                    <path
                        fill="currentColor"
                        d="M12.25 9.78V7a5.25 5.25 0 00-4.37-5.17V.88a.88.88 0 00-1.76 0v1A5.25 5.25 0 001.75 7v2.78A2.62 2.62 0 000 12.25V14a.88.88 0 00.88.88h2.74a3.5 3.5 0 006.76 0h2.74A.88.88 0 0014 14v-1.75a2.62 2.62 0 00-1.75-2.47zM3.5 7a3.5 3.5 0 017 0v2.62h-7zM7 15.75a1.75 1.75 0 01-1.5-.87h3a1.75 1.75 0 01-1.5.87zm5.25-2.63H1.75v-.87a.87.87 0 01.87-.87h8.76a.87.87 0 01.87.87z"
                        data-name="nav/kp/dashboard"
                    />
                </g>
            </g>
        </g>
    </CustomIcon>
);

export default Notification;
