import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';
import { InputField, Button } from 'shared';
import { useForm } from 'hooks';
import { makeStyles } from '@material-ui/styles';
import { Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        padding: `0 ${theme.spacing(3)}px`,
        margin: '0 auto'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    login: {
        maxWidth: '450px',
        width: '100%',
        margin: '0 auto'
    },
    input: {
        width: '100%',
        marginLeft: 0
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(4)
    },
    error: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.error.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const formSettings = {
    password: {
        format: 'password',
        required: true
    },
    secondPassword: {
        required: true,
        couple: 'password',
        coupleLabel: 'wachtwoord',
        format: 'password'
    }
};

const PasswordForm = (props) => {
    const { title, subTitle, onChange } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const [samePasswordError] = useState(false);
    const [formData, submitForm] = useForm(formSettings);

    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleLogin();
        }
    };

    const handleLogin = () => {
        submitForm()
            .then((r) => {
                if ((onChange && r.password, r.secondPassword)) {
                    onChange(r.password, r.secondPassword);
                } else {
                    console.warn('Handle form submit in your component');
                }
            })
            .catch((e) => {
                console.log('error', e);
            });
    };

    return (
        <Grid className={classes.root} container>
            <Grid className={classes.wrapper} item xs={12}>
                <div className={classes.login}>
                    <Typography variant="h2">{title}</Typography>
                    <Typography variant="body1">{subTitle}</Typography>
                    <form>
                        <InputField
                            name="password"
                            className={classes.input}
                            type="password"
                            label={t('register.newPassword')}
                            onKeyPress={onKeyPress}
                            form={formData}
                        />
                        <InputField
                            name="secondPassword"
                            className={classes.input}
                            type="password"
                            label={t('register.confirmPassword')}
                            onKeyPress={onKeyPress}
                            form={formData}
                        />
                        <Button
                            className={classes.button}
                            onClick={handleLogin}
                            variant="contained"
                            color="secondary"
                            label={t('register.buttonLabel')}
                        />
                    </form>
                </div>
                {samePasswordError && (
                    <Typography variant="error" className={classes.error}>
                        <Warning /> {t('register.errorMessage')}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default PasswordForm;
