import React, { useState, useEffect, useReducer } from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import { InputField, Button, Tooltip, Loader, Switch, SelectField } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { GQL_GET_USER, GQL_SAVE_USER, GQL_CITIES, GQL_NEWSLETTERS } from './graphql';
import { AutoCompleteInput, Link } from 'shared';
import { setUserData } from 'store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '1020px',
        padding: theme.spacing(5, 3.75, 10),

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(15, 3.75, 27.5)
        }
    },
    input: {
        width: '100%',
        marginTop: '12px'
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3.75),

        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(7.5)
        }
    },
    subTitle1: {
        marginBottom: theme.spacing(2.5),
        fontWeight: 600
    },
    subTitle2: {
        fontWeight: 500,
        fontSize: '1.125rem',
        marginBottom: theme.spacing(2.5)
    },
    item: {
        padding: theme.spacing(0, 3),
        margin: 0,

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        }
    },
    form: {
        marginBottom: theme.spacing(3.75)
    },
    tooltip: {
        marginTop: -theme.spacing(2),
        marginBottom: -theme.spacing(2)
    },
    text: {
        marginBottom: theme.spacing(4)
    },
    select: {
        marginTop: theme.spacing(2)
    },
    submit: {
        marginTop: theme.spacing(1.875)
    },
    emailContent: {
        margin: '20px 0'
    },
    changeEmail: {
        fontSize: '14px',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    newsLetterIntervalSelect: {
        width: 'auto',
        marginTop: theme.spacing(5)
    }
}));

const formData = {
    firstName: {
        required: true
    },
    lastName: {
        required: true
    },
    middleName: {
        required: false
    },
    city: {
        required: true
    }
};

const Profile = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [form, onSubmit] = useForm(formData);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [width] = useState(window.innerWidth);

    //queries
    const { data = {}, loading } = useQuery(GQL_GET_USER);
    const { currentUser = {} } = data;
    const { profile = {} } = currentUser;

    const { data: newsData = {}, loading: newsLoading } = useQuery(GQL_NEWSLETTERS);
    const { newsletters = {} } = newsData;

    //reducers
    const [newsLettersData, setNewsLetters] = useReducer((state, data) => {
        switch (data.type) {
            case 'INITIAL':
                return data.data;
            case 'CHANGE': {
                return state.map((i) => {
                    return {
                        ...i,
                        checked: i.uid === data.data.key ? data.data.value : i.checked
                    };
                });
            }
            default:
                return state;
        }
    }, []);

    //mutations
    const [updateUser, { loading: updateUserLoading }] = useMutation(GQL_SAVE_USER, {
        onCompleted: (r) => {
            dispatch(setUserData(currentUser.id));
            enqueueSnackbar({
                variant: 'success',
                message: t('profile.saveSuccess')
            });
        },
        onError: (r) => {
            enqueueSnackbar({
                variant: 'warning',
                message: t('profile.saveError')
            });
        },
        refetchQueries: ['currentUser']
    });

    // Refresh after updateUser mutation
    useEffect(() => {
        if (!newsLoading && !loading && newsletters && newsletters.newsletters && profile) {
            const nLetters = newsletters.newsletters.map((i) => {
                return {
                    ...i,
                    checked: profile.newsletters.some((v) => v.uid === i.uid)
                };
            });

            setNewsLetters({
                type: 'INITIAL',
                data: nLetters
            });
        }
    }, [newsLoading, newsletters, profile, loading]);

    const handleSave = () => {
        onSubmit().then((data) => {
            let nLetters = [];
            newsLettersData.forEach((i) => {
                if (i.checked) nLetters.push(i.id);
            });

            updateUser({
                variables: {
                    input: {
                        id: currentUser.id,
                        preferredEmail: data.preferredEmail,
                        daysBetweenAlertingMails: data.daysBetweenAlertingMails,
                        profile: {
                            firstName: data.firstName,
                            middleName: data.middleName,
                            lastName: data.lastName,
                            city: data.city && data.city.id,
                            newsletters: nLetters
                        }
                    }
                }
            });
        });
    };

    const handleChange = (data) => {
        setNewsLetters({
            type: 'CHANGE',
            data: data
        });
    };

    return (
        <Container className={classes.root}>
            <div className={classes.top}>
                <Typography className={classes.title} variant="h2" component="h1">
                    {t('profile.title')}
                </Typography>

                {width < 962 ? null : (
                    <Button
                        label="Opslaan"
                        variant="contained"
                        color="secondary"
                        onClick={handleSave}
                        loading={updateUserLoading}
                        disabled={updateUserLoading}
                    />
                )}
            </div>
            <Typography variant="h6" className={classes.subTitle1}>
                {t('profile.subTitle')}
            </Typography>
            {loading ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <Grid container className={classes.form} spacing={6}>
                        <Grid item className={classes.item} xs={12} md={6}>
                            <InputField
                                className={classes.input}
                                name="firstName"
                                label={t('profile.firstName')}
                                form={form}
                                initialValue={profile.firstName || ''}
                            />
                            <InputField
                                className={classes.input}
                                name="middleName"
                                label={t('profile.middleName')}
                                form={form}
                                initialValue={profile.middleName || ''}
                            />
                            <InputField
                                className={classes.input}
                                name="lastName"
                                label={t('profile.lastName')}
                                form={form}
                                initialValue={profile.lastName || ''}
                            />
                        </Grid>
                        <Grid item className={classes.item} xs={12} md={6}>
                            <AutoCompleteInput
                                classes={{
                                    textField: classes.input
                                }}
                                label={t('profile.city')}
                                noOptionsText="Typ en selecteer je plaats"
                                name="city"
                                initialValue={profile.city || ''}
                                form={form}
                                data={{
                                    query: GQL_CITIES,
                                    response: 'cities'
                                }}
                            />
                            <InputField
                                className={classes.input}
                                name="preferredEmail"
                                label={
                                    <React.Fragment>
                                        {t('profile.preferredEmail')}{' '}
                                        <Tooltip className={classes.tooltip} title={t('profile.preferredEmailInfo')} />
                                    </React.Fragment>
                                }
                                form={form}
                                optional={true}
                                initialValue={currentUser.preferredEmail || ''}
                            />

                            {width < 962 ? (
                                <Button
                                    label="Opslaan"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSave}
                                    loading={updateUserLoading}
                                    className={classes.submit}
                                    disabled={updateUserLoading}
                                />
                            ) : null}
                        </Grid>
                        <Grid item className={classes.item} xs={12} md={6}>
                            <div className={classes.emailContent}>
                                <Typography variant="h6" className={classes.subTitle1}>
                                    E-mail
                                    <Link className={classes.changeEmail} to="/email-wijzigen">
                                        {' '}
                                        (wijzigen)
                                    </Link>
                                </Typography>
                                <Typography variant="body1">{currentUser.email || ''}</Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.subTitle1}>
                        {t('profile.messages')}
                    </Typography>
                    <Typography variant="h6" className={classes.subTitle2}>
                        {t('profile.mailingTitle')}
                    </Typography>

                    <Grid container className={classes.switched} spacing={6}>
                        {newsLettersData.map((option, idx) => (
                            <Grid item xs={4} key={idx}>
                                <Switch
                                    label={option.title}
                                    name={option.uid}
                                    initialValue={option.checked}
                                    loading={newsLoading || !newsLettersData.length}
                                    onChange={handleChange}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <SelectField
                        form={form}
                        name="daysBetweenAlertingMails"
                        classes={{ formControl: classes.newsLetterIntervalSelect }}
                        label={
                            <React.Fragment>
                                {t('profile.newsLetterInterval.title')}{' '}
                                <Tooltip className={classes.tooltip} title={t('profile.newsLetterInterval.tooltip')} />
                            </React.Fragment>
                        }
                        options={[
                            { label: t('profile.newsLetterInterval.never'), value: 0 },
                            { label: t('profile.newsLetterInterval.twoWeeks'), value: 14 },
                            { label: t('profile.newsLetterInterval.monthly'), value: 30 },
                            { label: t('profile.newsLetterInterval.quarter'), value: 90 }
                        ]}
                        initialValue={
                            currentUser.daysBetweenAlertingMails >= 0 ? currentUser.daysBetweenAlertingMails : 90
                        }
                    />
                </React.Fragment>
            )}
        </Container>
    );
};

export default Profile;
