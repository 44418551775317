import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { ArrowRight } from 'shared';
import FavoriteButton from '../FavoriteButton';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid rgba(16, 16, 24, 0.2)`,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: 'auto'
    },
    title: {
        ...theme.typography.body1,
        textDecoration: 'none',
        color: theme.palette.primary.main,
        fontWeight: 500,
        transition: 'color 250ms',
        width: '100%',
        '&:hover': {
            color: theme.palette.text.hover
        },
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.5
        }
    },
    place: {
        color: theme.palette.primary.main,
        opacity: 0.5,
        lineHeight: '25px'
    },
    arrow: {
        color: theme.palette.primary.main,
        transition: 'color 250ms',
        '&:hover': {
            color: theme.palette.text.hover
        }
    },
    corporation: {
        color: theme.palette.primary.main,
        opacity: 0.5,
        marginLeft: theme.spacing(2.5),
        position: 'relative',
        lineHeight: '25px',
        '&::before': {
            content: `''`,
            position: 'absolute',
            left: '-13px',
            top: '11px',
            width: '5px',
            height: '5px',
            borderRadius: '50%',
            background: theme.palette.primary.main
        }
    },
    favorite: {
        marginLeft: theme.spacing(2)
    },
    arrowWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const VacancyItem = (props) => {
    const { title, link, place, corporation, id } = props;
    const classes = useStyles();
    const [active, setActive] = useState(false);
    const loggedIn = useSelector((state) => state.auth.isLoggedIn);
    const likedVacancies = useSelector((state) => state.auth.user.likedVacancies);

    useEffect(() => {
        if (likedVacancies) {
            likedVacancies.forEach((item) => {
                if (item.id === parseInt(id)) {
                    setActive(true);
                }
            });
        }
    }, [id, likedVacancies]);

    return (
        <div className={classes.root}>
            <span className={classes.wrapper}>
                <Link to={link} className={classes.title}>
                    {title}
                </Link>
                {place && (
                    <Typography className={classes.place} variant="body2">
                        {place}
                    </Typography>
                )}
                {corporation && (
                    <Typography className={classes.corporation} variant="body2">
                        {corporation}
                    </Typography>
                )}
            </span>

            <Link className={classes.arrowWrap} to={link}>
                <ArrowRight className={classes.arrow} />
            </Link>
            {loggedIn && <FavoriteButton className={classes.favorite} id={id} liked={active} />}
        </div>
    );
};

export default VacancyItem;
