import { gql } from 'apollo-boost';

export const ANONYMOUS_SCAN = gql`
    mutation createQuickscanAnonymous($type: QuickscanAnonymousType!) {
        createQuickscanAnonymous(type: $type) {
            id
            personId
            firstname
            lastname
            emailAddress
            url
        }
    }
`;

export const GET_ANONYMOUS_RESULTS = gql`
    query getQuickscanAnonymousResultsForHroPersonId($id: String!) {
        getQuickscanAnonymousResultsForHroPersonId(personId: $id) {
            scores {
                instrumentScores {
                    id
                    name
                    norm
                    textualInterpretation
                    logicalInterpretation
                    callToActions {
                        id
                        description
                        metaScoreLabel
                        url
                        fillColor
                    }
                    metaScores {
                        name
                        interpretation
                        label
                        value
                    }
                }
            }
            url
            conversationPlan {
                id
                url
                name
            }
        }
    }
`;
