import React from 'react';
import CustomIcon from './CustomIcon';

const LocationMarker = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 16 20' }}>
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M8 0C3.5817 0 0 3.5817 0 8c0 5.4 7.05 11.5 7.35 11.76a1 1 0 001.3 0C9 19.5 16 13.4 16 8c0-4.4183-3.5817-8-8-8zm0 17.65c-2.13-2-6-6.31-6-9.65 0-3.3137 2.6863-6 6-6s6 2.6863 6 6c0 3.34-3.87 7.66-6 9.65zM8 4C5.7909 4 4 5.7909 4 8c0 2.2091 1.7909 4 4 4 2.2091 0 4-1.7909 4-4a4 4 0 00-4-4zm0 6c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2z"
        />
    </CustomIcon>
);

export default LocationMarker;
