import React from 'react';
import CustomIcon from './CustomIcon';

const Folder = (props) => {
    return (
        <CustomIcon {...props} svgAtts={{ viewBox: '0 0 18 18' }}>
            <path
                fill="currentColor"
                fillOpacity=".3"
                d="M15.3 2.7H9.648l-.288-.9A2.7 2.7 0 006.804 0H2.7C1.2088 0 0 1.209 0 2.7v11.7c0 1.4912 1.2088 2.7 2.7 2.7h12.6c1.4912 0 2.7-1.2088 2.7-2.7v-9c0-1.4911-1.2088-2.7-2.7-2.7zm.9 11.7a.9.9 0 01-.9.9H2.7a.9.9 0 01-.9-.9V2.7a.9.9 0 01.9-.9h4.104a.9.9 0 01.855.612l.486 1.476A.9.9 0 009 4.5h6.3a.9.9 0 01.9.9v9z"
            />
        </CustomIcon>
    );
};

export default Folder;
