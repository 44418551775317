import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Block, Switch } from 'shared';
import ProfilePointer from './ProfilePointer';
import { GET_USER_COMPLETENESS, UPDATE_USER } from './queries';

const useStyles = makeStyles((theme) => ({
    list: {
        marginBottom: '30px',
        marginTop: `${theme.spacing(2)}px`
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px'
        }
    },
    percentage: {
        color: theme.palette.primary.main,
        opacity: 0.5
    },
    progress: (props) => ({
        height: '10px',
        width: '100%',
        margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
        background: theme.palette.background.primary,
        borderRadius: '5px',
        position: 'relative',
        '&:after': {
            width: `${props.percentage}%`,
            position: 'absolute',
            borderRadius: '5px',
            top: 0,
            left: 0,
            height: '10px',
            display: 'block',
            content: '" "',
            background: theme.palette.secondary.main
        }
    }),
    checkbox: {
        marginLeft: 0,
        marginBottom: `${theme.spacing(4)}px`
    },
    readMore: {
        alignSelf: 'flex-end'
    },
    block: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4)
        }
    },
    loader: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        background: 'rgba(255,255,255,  0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '25px'
    }
}));

const ProfileChecklist = () => {
    const { t } = useTranslation();
    const [percentage, setpercentage] = useState(0);
    const classes = useStyles({ percentage });
    const [isVisible, setIsVisible] = useState(false);
    const userId = useSelector((state) => state.auth.userId);

    const {
        loading,
        data = {},
        refetch
    } = useQuery(GET_USER_COMPLETENESS, {
        variables: {
            id: userId
        }
    });
    const { user = {} } = data;

    const [updateUser, { loading: loadingMutation }] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (user.profile) {
            const doneItems = user.profile.completeness.filter((item) => item.done).length;
            setpercentage(25 * doneItems);
            setIsVisible(user.profile.profilePublic);
        }
    }, [user]);

    const handleSwitchChange = (event) => {
        updateUser({
            variables: {
                input: {
                    id: userId,
                    profile: {
                        profilePublic: event.target.checked
                    }
                }
            }
        });
        refetch();
    };

    return (
        <Block className={classes.block} variant="white">
            <Typography className={classes.title} variant="h5">
                {t('profileChecklist.title')}
                <Typography className={classes.percentage} variant="body1">
                    {percentage} % {t('profileChecklist.voltooid')}
                </Typography>
            </Typography>
            <div className={classes.progress} />
            <Switch
                className={classes.checkbox}
                onChange={handleSwitchChange}
                label={t('profileChecklist.visible')}
                checked={isVisible}
            />

            <Typography className={classes.title} variant="h6">
                {t('profileChecklist.checklistTitle')}
            </Typography>

            <Typography variant="body1">{t('profileChecklist.text')}</Typography>

            <div className={classes.list}>
                {user.profile &&
                    user.profile.completeness.map((item, idx, arr) => (
                        <ProfilePointer
                            last={idx === arr.length - 1 ? 'last' : ''}
                            key={idx}
                            title={t(`${item.name}`)}
                            checked={item.done}
                            path={`/mijn-gegevens/bewerken?data-scroll-to=${arr[idx].name.split('.').pop()}`}
                        />
                    ))}
            </div>
            {loading && loadingMutation && (
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
            )}
        </Block>
    );
};

export default ProfileChecklist;
