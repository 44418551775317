import React from 'react';
import CustomIcon from './CustomIcon';

const Delete = (props) => {
    return (
        <CustomIcon {...props} svgAtts={{ viewBox: '0 0 18 20' }}>
            <path d="M1.125 8.25C.504 8.25 0 7.788 0 7.219V5.157C.004 3.85.905 2.686 2.25 2.25h4v-.5C6.25.783 7.034 0 8 0h2c.967 0 1.75.783 1.75 1.75v.5h4c1.345.436 2.246 1.6 2.25 2.907V7.22c0 .57-.504 1.031-1.125 1.031H16V18a2 2 0 01-2 2H4a2 2 0 01-2-2V8.25zM14 8.25H4V18h10V8.25zm.833-4H3.167c-.645 0-1.167.448-1.167 1v1h14v-1c0-.552-.522-1-1.167-1zM10 1.5H8a.25.25 0 00-.25.25v.5h2.5v-.5A.25.25 0 0010 1.5z"></path>
        </CustomIcon>
    );
};

export default Delete;
