import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { FavoriteBorder, Favorite } from '@material-ui/icons';
import { useMutation } from '@apollo/react-hooks';
import { LIKE_VACANCY, LIKE_USER } from './graphql';
import classNames from 'classnames';
import { setUserData } from 'store/actions';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer'
    },
    wrapper: {
        position: 'relative',
        [theme.breakpoints.down(768)]: {
            width: '100%'
        }
    },
    rootButton: {
        cursor: 'pointer',
        userSelect: 'none',
        borderRadius: '35px',
        padding: `0px ${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        position: 'relative',
        fontWeight: 500,
        lineHeight: '49px',
        display: 'inline-block',
        border: `1px solid ${theme.palette.borderColor.main}`,
        transition: 'color 300ms, background 300ms, border 300ms',
        '& $full': {
            left: '35px',
            color: theme.palette.primary.light
        },
        '& $border': {
            color: theme.palette.text.primary,
            transform: 'translateY(5px)',
            margin: theme.spacing(1, 1.5, 0, 1)
        },
        '&:hover': {
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
            '& $full': {
                transform: 'translate(-50%, -50%) scale(1)',
                opacity: 1
            }
        },
        '&:active': {
            '& $full': {
                transform: 'translate(-50%, -50%) scale(1.2)',
                color: theme.palette.primary.contrastText
            }
        },
        '& $filled': {
            color: theme.palette.primary.contrastText
        },
        [theme.breakpoints.down(768)]: {
            width: '100%'
        }
    },
    border: {
        color: theme.palette.primary.light,
        width: '22px',
        height: '22px',
        float: 'left'
    },
    full: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transformOrigin: 'center center',
        transform: 'translate(-50%, -50%) scale(1.5)',
        opacity: 0,
        color: theme.palette.primary.light,
        transition: 'opacity 300ms, transform 300ms, color 300ms',
        '&:hover': {
            transform: 'translate(-50%, -50%) scale(1)',
            opacity: 1
        },
        '&:active': {
            transform: 'translate(-50%, -50%) scale(1.2)',
            color: theme.palette.text.secondary
        }
    },
    filled: {
        opacity: 1,
        color: theme.palette.primary.main,
        transform: 'translate(-50%, -50%) scale(1)'
    },
    filledButton: {
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main
    },
    mention: {
        margin: `0px ${theme.spacing(2)}px`,
        padding: `0px ${theme.spacing(2.5)}px`,
        display: 'inline-block',
        background: theme.palette.background.primary,
        color: theme.palette.primary.main,
        lineHeight: '51px',
        borderRadius: '25px',
        userSelect: 'none',
        visibility: 'hidden',
        opacity: 0,
        fontSize: '0.88rem',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        pointerEvents: 'none',
        left: '-30px',
        transform: 'translateX(-95%)',
        position: 'absolute',
        transition: 'visibility 300ms, opacity 300ms, transform 300ms',
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            height: '20px',
            width: '20px',
            transform: 'rotate(45deg)',
            right: '-3px',
            top: '17px',
            background: theme.palette.background.primary
        }
    },
    visible: {
        visibility: 'visible',
        opacity: 1,
        transform: 'translateX(-100%)'
    }
}));

const FavoriteButton = (props) => {
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const { className, label = '', success, type = 'vacancy', id, liked, onChange } = props;
    const [likeVacancy] = useMutation(LIKE_VACANCY);
    const [likeUser] = useMutation(LIKE_USER);
    const [added, setAdded] = useState(liked);

    useEffect(() => {
        setAdded(liked);
    }, [liked]);

    const handleLike = (likeBolean, likeId) => {
        if (type === 'user') {
            likeUser({
                variables: {
                    input: {
                        like: likeBolean,
                        userId: likeId
                    }
                }
            });
        } else {
            likeVacancy({
                variables: {
                    input: {
                        like: likeBolean,
                        vacancyId: likeId
                    }
                }
            });
        }

        const token = Cookie.get('jwt_flow');
        const decodeToken = jwtDecode(token);
        dispatch(setUserData(decodeToken.id));
    };

    const handleFavorite = () => {
        if (added) {
            handleLike(false, id);
            setAdded(false);
            if (onChange) onChange(false);
        } else {
            handleLike(true, id);
            setAdded(true);
            if (onChange) onChange(true);
        }
    };

    return label.length > 0 ? (
        <span className={classes.wrapper}>
            <span className={classNames(classes.mention, { [classes.visible]: added })}>{success}</span>

            <span
                className={classNames(classes.rootButton, { [classes.filledButton]: added })}
                onClick={handleFavorite}
            >
                <FavoriteBorder className={classes.border} />
                <Favorite className={classNames(classes.full, { [classes.filled]: added })} />
                {label}
            </span>
        </span>
    ) : (
        <span className={`${classes.root} ${className}`} onClick={handleFavorite}>
            <FavoriteBorder className={classes.border} />
            <Favorite className={classNames(classes.full, { [classes.filled]: added })} />
        </span>
    );
};

export default FavoriteButton;
