import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '940px',
        margin: `${theme.spacing(8)}px auto`
    },
    heading: {
        width: '100%',
        margin: `${theme.spacing(4)}px 0`
    },
    paragraph: {
        marginBottom: `${theme.spacing(2)}px`
    }
}));

const Disclaimer = () => {
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Typography className={classes.heading} variant="h2">
                Disclaimer
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                De inhoud (waaronder tekst, foto's, logo's en dergelijke) van deze website mag niet zonder schriftelijke
                toestemming van stichting FLOW worden verveelvoudigd en/of worden gebruikt voor andere websites of enige
                andere toepassing.
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                De inhoud van deze website is met grote zorg samengesteld. Aan de informatie op deze website kunnen geen
                rechten worden ontleend en stichting FLOW neemt geen verantwoordelijkheid voor de consequenties van het
                gebruik van de geboden informatie.
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Suggesties, vragen of opmerkingen over de inhoud van de website kun je per e-mail aan ons kenbaar maken.
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Ook als je meent dat de site fouten bevat, niet volledig is of anderzijds verbeterd kan worden, dan
                horen wij dit graag.
            </Typography>
        </Grid>
    );
};

export default Disclaimer;
