import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { ArrowRight, Button } from 'shared';

import { lightenDarkenColor } from 'helpers';

const useStyles = makeStyles((theme) => ({
    ctaWithFillColor: (props) => ({
        backgroundColor: props.fillColor,

        '&:hover': {
            backgroundColor: lightenDarkenColor(props.fillColor, -40)
        }
    }),

    contentButton: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1.25, 2.5),
        justifyContent: 'space-between',

        [theme.breakpoints.up('md')]: {
            width: '350px',
            marginRight: 0
        }
    },

    buttonIcon: {
        color: theme.palette.primary.contrastText
    }
}));

const CallToActionButton = (props) => {
    const { cta } = props;
    const classes = useStyles({ fillColor: props.cta.fillColor });

    return (
        <Button
            key={cta.id}
            variant="contained"
            color="primary"
            target="_blank"
            className={classNames(classes.contentButton, { [classes.ctaWithFillColor]: cta.fillColor })}
            label={cta.description}
            href={cta.url}
            iconRight={() => <ArrowRight className={classes.buttonIcon} />}
        />
    );
};
export default CallToActionButton;
