import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    panel: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginTop: '14px'
    },
    title: {
        marginBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(2.5)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '24px'
        }
    },
    subTitle: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            fontSize: '16px'
        }
    },
    careerGuideGrid: {
        alignItems: 'flex-start',
        margin: '0 -40px',
        width: 'auto',
        [theme.breakpoints.up('md')]: {
            margin: 0,
            width: '100%'
        }
    },
    careerGuideBlock: {
        background: theme.palette.background.blue,
        borderRadius: 0,
        padding: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            borderRadius: '22px',
            padding: theme.spacing(6.25)
        },
        '&:nth-child(2)': {
            background: theme.palette.background.secondary
        }
    },
    careerGuideBlockRight: {
        position: 'relative',
        marginTop: theme.spacing(4.5),
        [theme.breakpoints.up('md')]: {
            left: '40px',
            marginTop: 0
        }
    },
    status: {
        width: '26px',
        height: '26px',
        border: '2px solid #2C276A',
        borderRadius: '50%',
        marginRight: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            color: 'white',
            width: '18px',
            height: '18px'
        }
    },
    panelRoot: {
        border: 0,
        minHeight: 0,
        padding: theme.spacing(1, 0),
        margin: 0,
        '&$finished': {
            '&::after': {
                opacity: 1
            },
            '& $status': {
                background: '#2C276A'
            },
            '& + $panelRoot': {
                '& $status': {
                    opacity: 1
                }
            }
        },
        '&:first-of-type': {
            border: 'inherit',
            '& $status': {
                opacity: 1
            }
        },
        '&$panelContentExpanded': {
            minHeight: 0
        },
        '&$expanded': {
            minHeight: 0
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '40px',
            height: 'calc(100% - 26px)',
            left: '28px',
            width: '2px',
            background: '#2C276A',
            opacity: '.2'
        },
        '&:last-of-type': {
            '&::after': {
                content: 'none'
            }
        }
    },
    panelMoreIcon: {
        color: '#2C276A',
        width: '1.25em',
        height: '1.25em'
    },
    panelContent: {
        margin: 0,
        '&$panelContentExpanded': {
            margin: 0,
            minHeight: 0
        }
    },
    panelContentExpanded: {},
    expanded: {},
    expansionPanelSummaryRoot: {
        minHeight: 0,
        '&$panelContentExpanded': {
            minHeight: 0
        }
    },
    panelTitle: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: '18px'
        }
    },
    expandIcon: {
        paddingTop: 0,
        paddingBottom: 0
    },
    expansionDetails: {
        paddingLeft: theme.spacing(6.375),
        flexFlow: 'column nowrap',
        '& p a': {
            color: 'inherit'
        }
    },
    stepLink: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        color: `${theme.palette.primary.main}!important`,
        textDecoration: 'none',
        fontSize: '18px',
        fontWeight: 500,
        marginTop: theme.spacing(1.25),
        cursor: 'pointer',
        '& svg': {
            marginRight: theme.spacing(1.75)
        }
    },
    disabledLink: {
        opacity: '.3',
        cursor: 'default'
    },
    finished: {},
    finishedPanel: {
        pointerEvents: 'none',
        '& $expandIcon': {
            display: 'none'
        }
    },
    time: {
        paddingTop: '5px',
        display: 'inline-flex',
        width: '65px',
        height: '65px',
        borderRadius: '50%',
        border: `2px solid ${theme.palette.divider}`,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: '12px',
        lineHeight: '15px',
        textAlign: 'center',
        fontWeight: 500,
        marginLeft: theme.spacing(4),
        color: theme.palette.primary.main
    },
    timeNumber: {
        fontSize: '24px',
        fontWeight: 500
    }
}));
