import React from 'react';
import CustomIcon from './CustomIcon';

const Redo = (props) => {
    return (
        <CustomIcon width="16" height="16" {...props}>
            <path
                d="M15.2 7.2a.8.8 0 00-.8.8 6.44 6.44 0 11-1.776-4.4h-1.92a.8.8 0 100 1.6h3.624a.8.8 0 00.8-.8V.8a.8.8 0 10-1.6 0v1.416A8 8 0 1016 8a.8.8 0 00-.8-.8z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </CustomIcon>
    );
};

export default Redo;
