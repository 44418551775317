import React from 'react';
import CustomIcon from './CustomIcon';

const ChevronRight = (props) => {
    return (
        <CustomIcon width="186" height="500" {...props}>
            <path d="M47.616 98.677a14.382 14.382 0 00-21.2 0L5.224 121.044a16.309 16.309 0 000 22.3l102.181 106.234L4.377 356.694a16.321 16.321 0 000 22.312l21.2 22.366a14.471 14.471 0 0021.2 0L181.662 260.74a16.5 16.5 0 000-22.352z" />
        </CustomIcon>
    );
};

export default ChevronRight;
