import gql from 'graphql-tag';

const SET_ALERT = gql`
    mutation addSearchAlert($input: AlertInput!) {
        addSearchAlert(input: $input) {
            id
        }
    }
`;

export { SET_ALERT };
