import React from 'react';
import CustomIcon from './CustomIcon';

const RtfFile = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 548.291 548.291' }}>
        <path d="M472.929 131.39c-.031-2.514-.829-5-2.556-6.96L364.656 3.677c-.021-.034-.053-.044-.084-.075-.63-.704-1.354-1.284-2.132-1.796-.23-.149-.462-.283-.703-.419-.683-.365-1.387-.667-2.121-.888-.2-.058-.377-.139-.577-.191-.808-.19-1.628-.308-2.467-.308H96.757C84.904 0 75.255 9.649 75.255 21.502V526.79c0 11.858 9.649 21.501 21.502 21.501h354.775c11.863 0 21.503-9.643 21.503-21.501V132.595c.001-.404-.064-.8-.106-1.205zM207.732 513.866c-1.93-3.34-4.745-12.998-8.252-27.571-3.161-14.751-8.25-18.792-19.147-18.96h-8.079v46.531h-26.517V397.087c8.606-1.407 21.422-2.462 35.651-2.462 17.562 0 29.853 2.63 38.279 9.307 7.029 5.617 10.889 13.868 10.889 24.757 0 15.106-10.714 25.46-20.9 29.15v.529c8.252 3.338 12.822 11.243 15.798 22.126 3.693 13.344 7.38 28.798 9.658 33.365h-27.38v.007zm124.172-95.881h-32.31v95.881h-26.868v-95.881h-31.792v-22.479h90.969v22.479zm86.589-.53h-45.48V444.5h42.499v21.774h-42.499v47.592h-26.867v-118.36h72.348v21.949zM96.757 365.081V21.502H345.82v110.011c0 5.935 4.81 10.751 10.752 10.751h94.961l.011 222.816H96.757z" />
        <path d="M184.37 414.473c-6.494 0-10.187.53-12.116.881v32.658h10.541c13.342 0 21.25-6.673 21.25-17.035-.001-10.887-7.381-16.332-19.675-16.504zM323.421 122.902h-12.493v-18.065l-30.919-30.929H151.757v218.217h43.413v48.999h159.165v-187.29l-30.914-30.932zm2.315 13.478l17.765 17.77h-17.765v-17.77zm-14.808 135.041c.057.924.057 1.853 0 2.777v-2.777zM282.323 87.382l17.76 17.771h-17.76V87.382zM159.655 284.236V81.796h114.773v31.248h28.61v171.197H159.655v-.005zm186.791 48.996H203.06v-41.107h107.862v-12.603h25.082v-10.751h-25.082v-24.185h25.082v-10.75h-25.082v-26.318h25.082v-10.75h-25.082v-65.973h6.908v31.247h28.61v171.19h.006z" />
        <path d="M184.575 137.986h21.984l2.542 20.095h8.027l-8.78-63.597h-9.244l-32.935 63.597h8.31l10.096-20.095zm12.364-24.727c1.885-3.869 3.489-7.832 5.187-11.604h.188c.283 3.772.756 8.401 1.134 11.701l2.451 18.399h-18.493l9.533-18.496zM228.008 127.226h69.434v10.75h-69.434zM228.008 154.548h69.434v10.751h-69.434zM172.254 180.979h125.188v10.751H172.254zM172.254 206.959h53.964v10.75h-53.964zM172.254 233.835h53.964v10.75h-53.964zM172.254 261.277h123.729v10.75H172.254zM212.28 303.606h123.729v10.751H212.28zM270 242.329l-2.176-6.945c-18.142-48.885-30.302 5.601-30.302 5.601l-.794 3.359h56.567C278.061 214.56 270 242.329 270 242.329z" />
    </CustomIcon>
);

export default RtfFile;
