import { gql } from 'apollo-boost';

export const LOGIN = gql`
    mutation login($email: String!, $password: String!, $quickscanAnonymousPersonId: String) {
        login(input: { email: $email, password: $password, quickscanAnonymousPersonId: $quickscanAnonymousPersonId }) {
            user {
                id
                email
                quickscanAnonymousPersonId
                roles {
                    id
                    name
                    title
                }
            }
            redirect
            message
        }
    }
`;

export const LOGOUT = gql`
    mutation logout {
        logout
    }
`;
