import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '940px',
        margin: `${theme.spacing(8)}px auto`
    },
    heading: {
        width: '100%',
        margin: `${theme.spacing(4)}px 0`
    },
    paragraph: {
        marginBottom: `${theme.spacing(2)}px`,
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 500,
            textDecoration: 'none',
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        }
    }
}));

const Privacy = () => {
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Typography className={classes.heading} variant="h2">
                Privacystatement
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW respecteert je privacy en gaat zeer zorgvuldig om met je persoonlijke gegevens. In dit
                privacystatement lichten we toe wat voor informatie we over jou verzamelen en wat we daarmee doen. Voor
                vragen en verzoeken over je gegevens kun je altijd contact met ons opnemen.
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Het Fonds Leren & Ontwikkelen Wooncorporaties (FLOW) kan persoonsgegevens over je verwerken, doordat je
                gebruikmaakt van de diensten van FLOW en/of omdat je deze zelf bij het invullen van gegevens op de
                website aan FLOW verstrekt. FLOW kan de volgende persoonsgegevens verwerken:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">voor- en achternaam</Typography>
                </li>
                <li>
                    <Typography variant="body1">voorletters</Typography>
                </li>
                <li>
                    <Typography variant="body1">adresgegevens</Typography>
                </li>
                <li>
                    <Typography variant="body1">telefoonnummer</Typography>
                </li>
                <li>
                    <Typography variant="body1">e-mailadres</Typography>
                </li>
                <li>
                    <Typography variant="body1">IP-adres.</Typography>
                </li>
            </ul>
            <Typography className={classes.heading} variant="h4">
                Waarom FLOW je gegevens nodig heeft?
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW verwerkt je persoonsgegevens vanwege je verzoek om je per e-mail op de hoogte te houden van de
                nieuwste informatie op flowweb.nl (via e-mailalerts) en/of digitale nieuwsbrieven.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Hoe lang bewaart FLOW je gegevens?
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW bewaart je persoonsgegevens niet langer dan strikt noodzakelijk is om de doelen te realiseren,
                zoals hierboven beschreven.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Delen met anderen
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW verstrekt je persoonsgegevens alléén aan derden om te voldoen aan een wettelijke verplichting (
                <a
                    href="https://www.rijksoverheid.nl/onderwerpen/privacy-en-persoonsgegevens/voorbereiden-op-de-avg"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    conform AVG
                </a>
                ).
            </Typography>

            <Typography className={classes.heading} variant="h4">
                In kaart brengen websitebezoek
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Op de website van FLOW worden algemene bezoekgegevens bijgehouden, waaronder het IP-adres van je
                computer en het tijdstip van opvraging en gegevens die je browser meestuurt. Deze gegevens worden
                gebruikt voor analyses van bezoek- en klikgedrag op de website. FLOW gebruikt deze informatie om de
                werking van de website te verbeteren. Deze gegevens worden zo veel mogelijk geanonimiseerd en niet aan
                derden verstrekt.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Gebruik van cookies
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                We maken bij het aanbieden van elektronische diensten gebruik van cookies. Een cookie is een eenvoudig
                klein bestandje dat met pagina’s van deze website wordt meegestuurd en door je browser op de harde
                schijf van je computer wordt opgeslagen.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Google Analytics
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW maakt gebruik van Google Analytics om bij te houden hoe gebruikers de website gebruiken. De aldus
                verkregen informatie wordt, met inbegrip van het adres van je computer (IP-adres), overgebracht naar en
                door Google opgeslagen op servers in de Verenigde Staten. Lees{' '}
                <a href="https://policies.google.com/privacy?hl=nl&gl=nl" rel="noopener noreferrer" target="_blank">
                    het privacybeleid van Google
                </a>{' '}
                voor meer informatie. Google gebruikt deze informatie om bij te houden hoe onze website gebruikt wordt
                en om rapporten over de website aan FLOW te kunnen verstrekken. Google kan deze informatie aan derden
                verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de informatie
                namens Google verwerken. FLOW heeft hier geen invloed op. FLOW heeft Google geen toestemming gegeven om
                via FLOW verkregen Analytics-informatie te gebruiken voor andere Google-diensten.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Privacyverklaring FLOW Gegevens inzien, aanpassen of verwijderen
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Je kunt een
                verzoek tot inzage, correctie of verwijdering sturen naar{' '}
                <a href="mailto:info@flowweb.nl">info@flowweb.nl</a>. FLOW zal zo snel mogelijk, maar binnen vier weken,
                op je verzoek reageren.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Beveiligen
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW neemt de bescherming van je gegevens serieus en neemt passende maatregelen om misbruik, verlies,
                onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. De website van
                FLOW maakt gebruik van een betrouwbaar SSL Certificaat om te borgen dat je persoonsgegevens niet in
                verkeerde handen vallen. Als je de indruk hebt dat je gegevens niet goed beveiligd zijn, of er
                aanwijzingen zijn van misbruik, of als je meer informatie wenst over de beveiliging van door FLOW
                verzamelde persoonsgegevens, neem dan contact op met FLOW via{' '}
                <a href="mailto:info@flowweb.nl">info@flowweb.nl</a>.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Formulieren
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Op de website staan regelmatig formulieren waarmee je je kunt aanmelden voor trainingen, bijeenkomsten,
                vouchers en dergelijke. Op deze formulieren vragen we naar enkele of alle van de volgende persoonlijke
                gegevens:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">
                        gegevens van de woningcorporatie waar de deelnemer werkt (naam corporatie en adres)
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        gegevens van de deelnemer (geslacht, naam, voorletters, roepnaam, telefoonnummer, e-mailadres,
                        geboortedatum)
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        factuuradres (naam en voorletters contactpersoon, e-mailadres en adresgegevens indien deze
                        afwijken van eerder ingevulde gegevens)
                    </Typography>
                </li>
            </ul>
            <Typography className={classes.paragraph} variant="body1">
                Voor het toekennen van bepaalde subsidies of masterclasses is het volgende nog nodig om aan te leveren:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">
                        kopie loonstrook (salarisbedragen mogen zwart gemaakt zijn).
                    </Typography>
                </li>
            </ul>
            <Typography className={classes.paragraph} variant="body1">
                Op basis van deze gegevens zijn we in staat om op een juiste manier de aanmelding voor een opleiding te
                verwerken of de aanvraag voor een omscholingsvoucher te kunnen beoordelen. We vragen niet meer dan
                hoogst noodzakelijk is.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Andere websites van FLOW
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                FLOW heeft naast haar algemene site (
                <a href="https://flowweb.nl" rel="noopener noreferrer" target="_blank">
                    flowweb.nl
                </a>
                ) nog enkele andere sites. Dit zijn sites die zij zelf niet rechtstreeks beheert, maar die zij wel
                betaalt en dus onder haar verantwoordelijkheid vallen. Het gaat om:{' '}
                <a href="http://watdoejijmorgen.nl/" rel="noopener noreferrer" target="_blank">
                    watdoejijmorgen.nl
                </a>
                ,{' '}
                <a href="http://www.transferpunt.com/" rel="noopener noreferrer" target="_blank">
                    transferpunt.com
                </a>
                ,{' '}
                <a href="http://www.expeditieloopbaan.nu/" rel="noopener noreferrer" target="_blank">
                    expeditieloopbaan.nu
                </a>
                ,{' '}
                <a href="http://www.samenwerkcorporatie.nl/" rel="noopener noreferrer" target="_blank">
                    samenwerkcorporatie.nl
                </a>
                ,{' '}
                <a href="http://www.projectloopbaan.nl/" rel="noopener noreferrer" target="_blank">
                    projectloopbaan.nl
                </a>
                ,{' '}
                <a href="http://www.klimmr.nl/" rel="noopener noreferrer" target="_blank">
                    klimmr.nl
                </a>
                ,{' '}
                <a href="http://www.loc-amsterdam.nl/" rel="noopener noreferrer" target="_blank">
                    loc-amsterdam.nl
                </a>
                ,{' '}
                <a href="https://www.loopbaaninitiatief.nl/" rel="noopener noreferrer" target="_blank">
                    loopbaaninitiatief.nl
                </a>
                ,{' '}
                <a href="http://www.corporatiesinbeweging.nl/" rel="noopener noreferrer" target="_blank">
                    corporatiesinbeweging.nl
                </a>
                ,{' '}
                <a href="http://www.krachtinmobiliteit.nl/" rel="noopener noreferrer" target="_blank">
                    krachtinmobiliteit.nl
                </a>
                ,{' '}
                <a href="http://www.zuidwestsamenwerkt.nl/" rel="noopener noreferrer" target="_blank">
                    zuidwestsamenwerkt.nl
                </a>
                ,{' '}
                <a href="http://www.bloei-hollandrijnland.nl/" rel="noopener noreferrer" target="_blank">
                    bloei-hollandrijnland.nl
                </a>
                ,{' '}
                <a href="http://www.flowwep.nl/" rel="noopener noreferrer" target="_blank">
                    flowwep.nl
                </a>
                ,{' '}
                <a href="http://www.flowarboportal.nl/" rel="noopener noreferrer" target="_blank">
                    flowarboportal.nl
                </a>
                ,{' '}
                <a href="http://www.floweuroportal.eu/" rel="noopener noreferrer" target="_blank">
                    floweuroportal.eu
                </a>{' '}
                en{' '}
                <a href="http://flow.destressmanager.nl/" rel="noopener noreferrer" target="_blank">
                    flow.destressmanager.nl
                </a>
                . Voor deze websites zijn eveneens bovengenoemde teksten over privacy en beveiliging van toepassing.
                Hierna wordt aangegeven welke gegevens op deze sites worden gevraagd en waarvoor. We vragen op deze
                sites niet meer dan hoogst noodzakelijk is voor het gebruik van de website door jou en we bewaren deze
                gegevens niet langer dan noodzakelijk is. Een deel van deze websites worden beheerd door externe
                partijen, waardoor medewerkers van deze partijen toegang hebben tot deze gegevens. Met deze partijen
                heeft FLOW een verwerkersovereenkomst afgesloten.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Watdoejijmorgen.nl
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                De website{' '}
                <a href="http://www.watdoejijmorgen.nl/" rel="noopener noreferrer" target="_blank">
                    watdoejijmorgen.nl
                </a>{' '}
                is het centrale startpunt voor iedereen die wil bijblijven, leren, ontwikkelen en groeien in de
                corporatiesector. De website bevat informatie over ontwikkelingen per vakgebied in de corporatiesector
                en welke kennis en vaardigheden hiervoor nodig zijn. Tevens geeft de website informatie over leerlijnen
                en opleidingen. Met het beantwoorden van een aantal vragen krijgt de medewerker een persoonlijk
                opleidingadvies. Dit advies is vrijblijvend en wordt niet geregistreerd. Het advies kan de medewerker
                aanvullen door drie open vragen te beantwoorden om zo een persoonlijk opleidings- en ontwikkelplan (plan
                voor morgen) te genereren. Dit plan wordt alleen opgeslagen als de medewerker inlogt en een account
                aanmaakt (e-mailadres en wachtwoord). Via{' '}
                <a href="http://www.watdoejijmorgen.nl/" rel="noopener noreferrer" target="_blank">
                    watdoejijmorgen.nl
                </a>{' '}
                kan een medewerker zich inschrijven voor een opleiding of leeractiviteit zoals een e-learning. Deze
                inschrijving wordt niet geregistreerd op de website van{' '}
                <a href="http://www.watdoejijmorgen.nl/" rel="noopener noreferrer" target="_blank">
                    watdoejijmorgen.nl
                </a>
                . De inschrijving vindt plaats via de watdoejijmorgen-Academie. FLOW heeft hiertoe een
                verwerkersovereenkomst afgesloten met De Corporatie Academie.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Regiosites
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                De regiosites van FLOW zijn:{' '}
                <a href="http://www.transferpunt.com/" rel="noopener noreferrer" target="_blank">
                    transferpunt.com
                </a>
                ,{' '}
                <a href="http://www.expeditieloopbaan.nu/" rel="noopener noreferrer" target="_blank">
                    expeditieloopbaan.nu
                </a>
                ,{' '}
                <a href="http://www.samenwerkcorporatie.nl/" rel="noopener noreferrer" target="_blank">
                    samenwerkcorporatie.nl
                </a>
                ,{' '}
                <a href="http://www.projectloopbaan.nl/" rel="noopener noreferrer" target="_blank">
                    projectloopbaan.nl
                </a>
                ,{' '}
                <a href="http://www.klimmr.nl/" rel="noopener noreferrer" target="_blank">
                    klimmr.nl
                </a>
                ,{' '}
                <a href="http://www.loc-amsterdam.nl/" rel="noopener noreferrer" target="_blank">
                    loc-amsterdam.nl
                </a>
                ,{' '}
                <a href="https://www.loopbaaninitiatief.nl/" rel="noopener noreferrer" target="_blank">
                    loopbaaninitiatief.nl
                </a>
                ,{' '}
                <a href="http://www.corporatiesinbeweging.nl/" rel="noopener noreferrer" target="_blank">
                    corporatiesinbeweging.nl
                </a>
                ,{' '}
                <a href="http://www.krachtinmobiliteit.nl/" rel="noopener noreferrer" target="_blank">
                    krachtinmobiliteit.nl
                </a>
                ,{' '}
                <a href="http://www.zuidwestsamenwerkt.nl/" rel="noopener noreferrer" target="_blank">
                    zuidwestsamenwerkt.nl
                </a>{' '}
                en{' '}
                <a href="http://www.bloei-hollandrijnland.nl/" rel="noopener noreferrer" target="_blank">
                    bloei-hollandrijnland.nl
                </a>
                . Op deze websites worden voor het aanmelden voor de nieuwsbrief en het aanmelden voor bijeenkomsten
                persoonlijke gegevens gevraagd. Het gaat om enkele of alle van de volgende gegevens:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">
                        Persoonsgegevens voor het aanmelden voor de nieuwsbrief. Het gaat dan om (een deel van de)
                        gegevens als voornaam, achternaam, e-mailadres, naam corporatie.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        Persoonsgegevens voor het aanmelden van trainingen en/of bijeenkomsten en dergelijke
                        (kennisnetwerken, stagemaand). Het gaat dan om (een deel van) de volgende gegevens: voornaam,
                        achternaam, e-mailadres, telefoonnummer werk, telefoonnummer mobiel, naam corporatie, functie,
                        naam en datum training of bijeenkomst waarvoor ze zich aanmelden, factuurgegevens (naam
                        corporatie, postadres, postcode, plaats, betalingskenmerk factuur, e-mailadres factuur).
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        Persoonsgegevens voor het aanmelden voor stages. Het gaat dan om: naam, corporatienaam, functie,
                        telefoonnummer, e-mailadres, de voor de stage gewenste functie/rol/onderwerpen, de
                        voorkeursperiode en -duur.
                    </Typography>
                </li>
            </ul>
            <Typography className={classes.paragraph} variant="body1">
                De gegevens zijn nodig om mensen een nieuwsbrief te kunnen mailen en/of om deelnemers te kunnen indelen
                bij trainingen en dergelijke, bevestigingen te versturen aan deelnemers en om ze te voorzien van
                informatie over de training en dergelijke, en/of mensen in te delen bij stages, bevestigen van
                betrokkenen, contact zoeken bij wijzigingen en om rapportages te maken voor FLOW. De ingevulde gegevens
                komen binnen bij het e-mailadres van de betreffende regio of bij Mailchimp. De projectmedewerkers van de
                regio (zoals projectleider, matchmaker en/of leeradviseur) hebben toegang tot deze gegevens, net als de
                projectmedewerker van FLOW die de rapportages ontvangt voor verantwoording over subsidie.
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                De gegevens worden niet langer bewaard dan noodzakelijk.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Kansenportaal.nl
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Het Kansenportaal is een website waarop werkgevers kansen (zoals vacatures) kunnen plaatsen en
                werknemers deze kunnen bekijken en met hun (eventueel) ingevulde profiel op de site daarop kunnen
                solliciteren. Werkgevers moeten daarvoor een aantal gegevens over de kans en hun organisatie invoeren,
                zodat werknemers weten waarop ze reageren. Als werknemers willen solliciteren met hun profiel in het
                Kansenportaal, zullen ze daarvoor eveneens een aantal (noodzakelijke) gegevens moeten invoeren, anders
                is de sollicitatie niet volledig. Alleen de gegevens met een * zijn verplicht.
                <br />
                <br />
                De volgende gegevens worden gevraagd:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">
                        <strong>Werkgevers</strong> (plaatsen van kans): contactpersoon*, weergavetitel*, functietitel*,
                        functieomschrijving, functiegroepen*, opleidingsniveau*, soort kans, functieschaal, duur*,
                        aantal uur per week*, aantal beschikbare plaatsen, werkadres (straat, huisnummer, postcode,
                        plaats, telefoonnummer)*, publicatiedata*, kans wel/niet opnemen in open vacaturebank, eventuele
                        afbeelding, eventuele bijlage.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Werknemers</strong> (om kansen te bekijken en te solliciteren): voornaam*, voorletters,
                        tussenvoegsel, achternaam*, e-mailadres*, wel of niet getoond in kandidatenoverzicht*,
                        motivatie, relevante ervaring en deskundigheid, pasfoto, geslacht, geboortedatum,
                        telefoonnummer, wel/niet ontvangen nieuwsbrief, adresgegevens (straat, huisnummer, postcode,
                        woonplaats), baanvoorkeuren (gewenst soort kans, gewenste functiegroep, gewenste functieschaal,
                        gewenste duur, hoogst genoten opleiding, wel/geen bezwaar om in ploegendienst te werken,
                        nachtdiensten of weekenddiensten te draaien), beschikbaarheid.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Voor je cv</strong>: werkervaring, opleidingen, cursussen, vervoer, competenties,
                        nevenactiviteiten, geüploade cv.
                    </Typography>
                </li>
            </ul>

            <Typography className={classes.heading} variant="h4">
                Flowwep.nl
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Op de FLOW Woondiensten Employability Portal (Flowwep.nl) worden de volgende gegevens gevraagd en
                bewaard. Dit zijn gegevens van werkgevers en werknemers van woningcorporaties en loopbaanadviseurs.
                Vanwege de looptijd van het abonnement en de registratie van de deelnemers worden de gegevens drie jaar
                bewaard. De verwerking van persoonsgegevens is nodig voor de inzet van diverse HR-instrumenten zodat dit
                kan leiden tot een gedegen loopbaanadviestraject. Het gaat om de volgende persoonsgegevens.
            </Typography>
            <ol>
                <li>
                    <Typography variant="body1">
                        <strong>Werknemersgegevens</strong>: e-mail, voornaam + tussenvoegsel + achternaam,
                        woningcorporatie, telefoon, opleidingsniveau, opleidingsrichting, werkervaring,
                        managementervaring, nevenactiviteiten, leeftijd
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Praktijkervaringen werkgevers en werknemers</strong>: naam, leeftijd, functie, foto,
                        locatie
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Loopbaanadviseur profielen</strong>: bedrijf, naam, adres, postcode, plaats, telefoon,
                        foto, e-mail, website, persoonsomschrijving
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Toekomstvizier data</strong>: antwoorden op vragen om een advies te kunnen geven
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Interessetest</strong>: interesses van een gebruiker, drijfveren van een gebruiker,
                        voorkeur activiteiten van een gebruiker, karaktereigenschappen van een gebruiker,
                        loopbaanwaarden van een gebruiker, werkwensen van een gebruiker, toekomstvisie van een
                        gebruiker, gewenste werksituatie, huidige werksituatie
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Talententest data</strong>: Gegevens worden bij NOA opgeslagen. Deze zijn opgegeven in
                        bijlage 2.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        <strong>Ervaringsscan data</strong>: ervaringen van een werknemer. De gegevens die je invoert op
                        Flowwep.nl en in de verschillende instrumenten zijn goed beveiligd en afgeschermd. Een goede
                        beveiliging wordt gegarandeerd door het gebruik van persoonlijke inloggegevens en de
                        versleuteling van gegevens. De communicatie via het internet is goed afgeschermd. De site is
                        beschermd tegen verlies, misbruik en verandering van gegevens.
                    </Typography>
                </li>
            </ol>

            <Typography className={classes.heading} variant="h6">
                Werkgeversportaal
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Niemand heeft ongeautoriseerde toegang tot de – versleutelde – gegevens. Leidinggevenden hebben bij het
                Strategisch Ontwikkelingsplan en de Talentenmonitor alleen toegang tot de gegevens die zij hebben
                ingevuld voor hun eigen afdeling. Medewerkers en collega-leidinggevenden hebben geen toegang tot deze
                gegevens. Uitsluitend de P&O-verantwoordelijke, administrator van de instrumenten voor de eigen
                organisatie, heeft toegang tot alle gegevens in het werkgeversportaal.
            </Typography>

            <Typography className={classes.heading} variant="h6">
                Werknemersportaal
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Niemand heeft ongeautoriseerde toegang tot de – versleutelde – gegevens. Alles wat je invult bij de
                Loopbaanwijzer is alleen voor jou toegankelijk. Je beslist zelf of je de uitkomsten wilt delen met je
                leidinggevende en/of P&O.
            </Typography>

            <Typography className={classes.heading} variant="h6">
                Flowarboportal.nl
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                De FLOW Arboportal vraagt persoonlijke gegevens bij het aanmelden voor de nieuwsbrief. Dit gaat om het
                e-mailadres. De aanmeldingen komen rechtstreeks binnen bij FLOW.
            </Typography>

            <Typography className={classes.heading} variant="h6">
                Floweuroportal.nl
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                De FLOW Europortal vraagt persoonlijke gegevens bij het aanmelden voor de nieuwsbrief. Dit gaat om naam,
                voorletters, geslacht, organisatienaam en e-mailadres. De aanmeldingen komen rechtstreeks binnen bij
                FLOW.
            </Typography>

            <Typography className={classes.heading} variant="h6">
                Flow.destressmanager.nl
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Om van de Stressmanager gebruik te kunnen maken is het nodig enkele persoonsgegevens in te voeren. Het
                gaat om de volgende gegevens: e-mailadres, voornaam, achternaam, geslacht, geboortedatum, hoogst genoten
                opleiding, of je werkzaam bent bij een wooncorporatie, e-mailadres privé (niet verplicht). Deze gegevens
                zijn nodig om de test goed uit te kunnen voeren en om inloggegevens (zoals wachtwoord) te kunnen
                aanpassen.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Websites van derden
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Links op de genoemde sites kunnen leiden naar een website van een derde. Bovenstaande privacyverklaring
                is niet van toepassing op websites van derden die via links met deze websites zijn verbonden. We kunnen
                niet garanderen dat deze derden op een betrouwbare of veilige manier met je persoonsgegevens omgaan. We
                raden je aan de privacyverklaring van deze websites te lezen voordat je van deze websites gebruikmaakt.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Wijzigen privacyverklaring
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                We behouden ons het recht voor om wijzigingen aan te brengen in deze verklaring. Het verdient
                aanbeveling om deze verklaring geregeld te raadplegen, zodat je van deze wijzigingen op de hoogte bent.
                <br />
                <br />
                Deze privacyverklaring is voor het laatst gewijzigd op 18 december 2018.
            </Typography>

            <Typography className={classes.heading} variant="h4">
                Contact
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
                Wil je bezwaar maken? Je kunt ons schrijven, bellen of mailen via onderstaande gegevens.
                <br />
                <br />
                Kadegriend 9<br />
                1356 CA Almere
                <br />
                T (036) 540 23 04
                <br />E <a href="mailto:info@flowweb.nl">info@flowweb.nl</a>
            </Typography>
        </Grid>
    );
};

export default Privacy;
