import React from 'react';
import CustomIcon from './CustomIcon';

const Facebook = (props) => {
    return (
        <CustomIcon {...props} width="258.781" height="499.906">
            <path
                className="cls-1"
                d="M258.775 82.9h-47.02c-36.837 0-43.726 17.724-43.726 43.261v56.789h87.753l-11.676 88.924h-76.077V499.9H76.382V271.874H.006V182.95h76.375v-65.5C76.382 41.448 122.806-.012 190.49-.012c32.347 0 60.2 2.406 68.285 3.606V82.9z"
            />
        </CustomIcon>
    );
};

export default Facebook;
