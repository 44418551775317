import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CheckCircle } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        '&.Mui-expanded': {
            margin: 0,
            '& $stripeVer': {
                transform: 'translateX(-9px) scaleX(0)'
            }
        },
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        borderTop: '1px solid rgba(44,39,106,0.15)'
    },
    summaryFont: {
        color: theme.palette.text.secondary,
        fontWeight: 500,
        minWidth: '64px'
    },
    largeFont: {
        fontSize: '1.25em',
        lineHeight: 1.9
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '15px',
        color: theme.palette.primary.main,
        opacity: '.25'
    },
    customIcon: {
        height: '30px',
        width: '30px',
        color: theme.palette.primary.main
    },
    stripeHor: {
        width: '16px',
        height: '2px',
        background: theme.palette.text.hover
    },
    stripeVer: {
        width: '2px',
        height: '16px',
        background: theme.palette.text.hover,
        transform: 'translateX(-9px) scaleX(1)',
        transformOrigin: 'center center',
        transition: 'opacity 400ms, transform 300ms'
    },
    content: {},
    expandIcon: {},
    expanded: {},
    expansionPanelSummaryRoot: {},
    expansionDetails: {}
}));

const ExpansionPanelContainer = (props) => {
    const {
        title,
        children,
        expanded: initialValue = false,
        icon,
        variant = 'default',
        disabled = false,
        className,
        done = false,
        noExpand = false
    } = props;

    const classes = useStyles(props);
    const [expanded, setExpanded] = useState(initialValue);

    useEffect(() => {
        setExpanded(initialValue);
    }, [initialValue]);

    const handleChange = (event, expanded) => {
        if (noExpand) return;
        setExpanded(expanded);
    };

    return (
        <Accordion
            disabled={disabled}
            expanded={expanded}
            onChange={handleChange}
            className={`${classes.root} ${className}`}
        >
            <AccordionSummary
                expandIcon={
                    noExpand ? (
                        false
                    ) : icon ? (
                        icon
                    ) : (
                        <React.Fragment>
                            <span className={classes.stripeHor} />
                            <span className={classes.stripeVer} />
                        </React.Fragment>
                    )
                }
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
                classes={{
                    root: classes.expansionPanelSummaryRoot,
                    content: classes.content,
                    expandIcon: classes.expandIcon,
                    expanded: classes.expanded
                }}
            >
                <Typography
                    className={classNames(classes.summaryFont, {
                        [classes.largeFont]: variant === 'large'
                    })}
                >
                    {title || ''}
                </Typography>
                {done && (
                    <span className={classes.icon}>
                        <CheckCircle />
                    </span>
                )}
            </AccordionSummary>
            <AccordionDetails className={classes.expansionDetails}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default ExpansionPanelContainer;
