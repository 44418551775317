import { createMuiTheme } from '@material-ui/core';
import { galano, galanoBold, galanoLight, galanoMedium } from './font';

//colors
const divider = 'rgba(44,39,106,0.15)';
const yellow = '#FFE88B';
const pink = '#FADCDC';
const red = '#E25959';
const purple = '#6E3286';
const darkBlue = '#2C276A';

//text
const defaultTitleColor = darkBlue;

//icon colors
const docIconColor = '#6FC6F0';
const pdfIconColor = red;
const sheetIconColor = '#B3D450';
const imageIconColor = '#FAAB1E';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Galanogrotesque',
        fontSize: 14,
        htmlFontSize: 16,
        fontWeightRegular: 'normal',
        color: '#424242',
        h1: {
            fontSize: '70px',
            lineHeight: '80px',
            color: defaultTitleColor,
            fontWeight: 700
        },
        h2: {
            fontSize: '48px',
            lineHeight: '58px',
            color: defaultTitleColor,
            fontWeight: 700
        },
        h3: {
            fontSize: '38px',
            lineHeight: '48px',
            color: defaultTitleColor,
            fontWeight: 700
        },
        h4: {
            fontSize: '32px',
            lineHeight: '38px',
            color: defaultTitleColor,
            fontWeight: 700
        },
        h5: {
            fontSize: '26px',
            lineHeight: '32px',
            color: defaultTitleColor,
            fontWeight: 500
        },
        h6: {
            fontSize: '20px',
            lineHeight: '28px',
            color: defaultTitleColor,
            fontWeight: '400'
        },
        subtitle1: {
            fontSize: '18px',
            lineHeight: '32px',
            color: defaultTitleColor,
            fontWeight: 500
        },
        body1: {
            fontSize: '18px',
            lineHeight: '32px',
            fontWeight: 'normal'
        },
        body2: {
            fontSize: '16px',
            lineHeight: '30px',
            fontWeight: 'normal'
        },
        button: {
            fontSize: '18px',
            textTransform: 'none',
            boxShadow: 'none'
        }
    },
    palette: {
        common: { black: '#000', white: '#fff' },
        background: {
            paper: '#fff',
            default: '#fff',
            primary: '#F8F5F9',
            secondary: '#D4ECF7',
            secondaryContrast: '#8bcce9',
            blue: '#d2ee7e',
            blueContrast: '#b7e431',
            pink: pink,
            pinkContrast: '#ef9090',
            yellow: yellow,
            yellowContrast: '#ffd734',
            purple: '#e6daeb',
            purpleContrast: '#7f5c8e',
            grey: '#efeff4',
            greyContrast: '#a2a2b1',
            dark: '#acd5e8',
            red: red
        },
        primary: {
            light: '#D0BADA',
            main: purple,
            contrastText: '#fff'
        },
        secondary: {
            light: '#D2EE7E',
            main: '#B3D450',
            contrastText: '#2C276A'
        },
        error: {
            light: 'rgba(232, 138, 131, 1)',
            main: 'rgba(217, 40, 28, 1)',
            dark: 'rgba(155, 26, 18, 1)',
            contrastText: '#fff'
        },
        text: {
            primary: '#424242',
            primaryLight: '#6b6b6b',
            secondary: '#2C276A',
            secondaryLight: 'rgba(44, 39, 106, 0.5)',
            hover: '#6FC6F0',
            normal: '#555555',
            disabled: 'rgba(49, 45, 119, 0.6)',
            hint: 'rgba(0, 0, 0, 0.38)',
            headers: defaultTitleColor
        },
        divider: divider,
        borderColor: {
            main: 'rgba(16, 16, 24, 0.2)',
            primary: '#e7dcec'
        },
        shape: {
            borderRadius: 35
        },
        icon: {
            docIconColor,
            pdfIconColor,
            sheetIconColor,
            imageIconColor
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [galano, galanoLight, galanoBold, galanoMedium]
            }
        },
        Breadcrumbs: {
            root: {
                background: 'transparent',
                position: 'absolute'
            }
        },
        MuiSvgIcon: {
            colorSecondary: {
                color: '#fff'
            }
        },
        ExpansionPanelSummary: {
            expanded: {
                '&$expandIcon': {
                    transform: 'rotate(180deg)'
                }
            },
            content: {
                margin: '14px 0'
            }
        },
        ExpansionPanel: {
            root: {
                borderTop: 0,
                borderBottom: `1px solid ${divider}`,
                '&:before': {
                    display: 'none'
                }
            }
        },
        table: {
            tableCell: {
                fontSize: '16px',
                color: defaultTitleColor,
                opacity: 1,
                lineHeight: '28px',
                fontWeight: 500
            }
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    }
});

export default theme;
