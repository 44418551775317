import { LOGIN, SET_USER, SET_TOKEN, LOGOUT, SET_ROLE, SET_HRO_ID } from './authActions';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const token = Cookies.get('jwt_flow');
let userId = null;
const haveToken = token && token !== 'deleted';

if (haveToken) {
    const decodeToken = jwtDecode(token);
    userId = decodeToken.id;
}

export default (
    state = {
        token: haveToken ? token : '',
        isLoggedIn: !!haveToken,
        user: {},
        userId: userId,
        quickscanAnonymousPersonId: '',
        role: ''
    },
    action
) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLoggedIn: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                token: undefined,
                user: {},
                userId: null,
                isLoggedIn: false,
                role: ''
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case SET_ROLE:
            return {
                ...state,
                role: action.payload
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case SET_HRO_ID:
            return {
                ...state,
                quickscanAnonymousPersonId: action.payload
            };
        default:
            return state;
    }
};
