import gql from 'graphql-tag';

const GET_VACANCIES = gql`
    query getVacancies($pagination: PaginationInput!, $filter: VacancyFilterInput) {
        vacancies(pagination: $pagination, filter: $filter) {
            vacancies {
                id
                title
                description
                crawled
                originURL
                city {
                    id
                    name
                }
                corporation {
                    name
                    region {
                        id
                        description
                    }
                }
            }
            count
        }
    }
`;

export { GET_VACANCIES };
