import React from 'react';
import CustomIcon from './CustomIcon';

const PngFile = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801' }}>
        <path d="M151.951 414.103c-5.677 0-9.526.554-11.541 1.107v36.492c2.381.554 5.313.732 9.352.732 14.851 0 24.026-7.524 24.026-20.171.006-11.379-7.883-18.16-21.837-18.16zM353.668 141.89l.043.016v-.016z" />
        <path d="M475.095 131.992c-.032-2.526-.844-5.021-2.568-6.993L366.324 3.694c-.021-.034-.053-.045-.084-.076-.633-.707-1.36-1.29-2.141-1.804-.232-.15-.465-.285-.707-.422-.686-.366-1.393-.67-2.131-.892-.2-.058-.379-.14-.59-.192C359.87.114 359.037 0 358.203 0H97.2C85.292 0 75.6 9.693 75.6 21.601v507.6c0 11.913 9.692 21.601 21.6 21.601h356.4c11.908 0 21.601-9.688 21.601-21.601V133.202c-.001-.406-.064-.804-.106-1.21zM190.107 460.867c-9.537 8.986-23.657 13.025-40.165 13.025-3.673 0-6.982-.189-9.537-.554v44.196h-27.696V395.582c8.617-1.466 20.725-2.568 37.779-2.568 17.23 0 29.531 3.296 37.776 9.903 7.881 6.229 13.204 16.501 13.204 28.608.011 12.098-4.023 22.381-11.361 29.342zm131.509 56.668h-29.341l-26.412-47.682c-7.349-13.205-15.412-29.152-21.477-43.644l-.538.18c.738 16.326 1.094 33.744 1.094 53.915v37.23h-25.671V393.932h32.643l25.684 45.293c7.325 13.025 14.671 28.424 20.161 42.367h.554c-1.841-16.321-2.384-33.012-2.384-51.532v-36.123h25.687v123.598zm127.47-5.684c-8.617 2.933-24.943 6.972-41.27 6.972-22.55 0-38.887-5.685-50.251-16.695-11.364-10.632-17.613-26.769-17.429-44.925.18-41.075 30.08-64.552 70.601-64.552 15.958 0 28.255 3.111 34.304 6.048l-5.864 22.376c-6.797-2.933-15.229-5.316-28.803-5.316-23.282 0-40.891 13.205-40.891 39.973 0 25.497 15.957 40.532 38.887 40.532 6.417 0 11.554-.728 13.753-1.835v-25.861h-19.074v-21.821h46.032v65.105h.005zM97.2 366.752V21.601h250.203v77.69h6.75v42.599h42.156v1.026h57.29l.011 223.836H97.2z" />
        <path d="M184.328 269.687h41.85v-42.594h-42.156v-42.602h42.156V141.89h-42.156V99.291H141.42v42.599h42.159v42.601H141.42v42.602h42.159v42.594h-41.84v42.606h42.159v42.594h42.583v-42.594h-42.153zM269.401 269.687h41.848v-42.594h-42.156v-42.602h42.156V141.89h-42.156V99.291h-42.612v42.599h42.159v42.601h-42.159v42.602h42.159v42.594H226.8v42.606h42.158v42.594h42.597v-42.594h-42.154zM311.555 141.89h42.113c-3.691-1.716-6.265-5.432-6.265-9.774V99.291h-35.849v42.599zM353.711 141.906v42.585h-42.156v42.602h42.156v42.594H311.86v42.602h42.167v42.599h42.593v-42.599h-42.15v-42.602h41.84v-42.594h-42.156v-42.602h42.156v-41.575h-38.106c-1.614 0-3.122-.377-4.493-1.01z" />
    </CustomIcon>
);

export default PngFile;
