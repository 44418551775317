import React from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: '56.25%'
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0
    }
}));

const VideoPlayer = (props) => {
    const { url } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <ReactPlayer
                className={classes.player}
                url={url || ''}
                controls
                width="100%"
                height="100%"
                config={{
                    youtube: {
                        playerVars: {
                            rel: 0,
                            autoplay: 0
                        }
                    }
                }}
            />
        </div>
    );
};

export default VideoPlayer;
