import React from 'react';
import CustomIcon from './CustomIcon';

const PowerPoint = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 548.291 548.291' }}>
        <path d="M271.447 413.066c-5.995 0-10.058.577-12.192 1.155v38.51c2.523.577 5.617.776 9.87.776 15.676 0 25.347-7.938 25.347-21.286-.001-12.001-8.316-19.155-23.025-19.155zM159.015 413.066c-5.995 0-10.058.577-12.191 1.155v38.51c2.522.577 5.616.776 9.869.776 15.675 0 25.347-7.938 25.347-21.286-.001-12.001-8.319-19.155-23.025-19.155z" />
        <path d="M472.929 131.39c-.031-2.514-.839-4.997-2.566-6.96L364.656 3.677c-.031-.034-.062-.044-.084-.075-.63-.704-1.365-1.284-2.142-1.796-.231-.149-.463-.283-.704-.419-.672-.365-1.376-.667-2.121-.888-.2-.058-.377-.139-.577-.191C358.231.113 357.4 0 356.561 0H96.757C84.904 0 75.255 9.649 75.255 21.502V526.79c0 11.858 9.649 21.501 21.502 21.501h354.775c11.853 0 21.503-9.643 21.503-21.501V132.595c.001-.404-.064-.8-.106-1.205zM199.265 462.4c-10.068 9.481-24.969 13.743-42.383 13.743-3.867 0-7.35-.2-10.058-.582v46.63h-29.221V393.517c9.088-1.543 21.866-2.709 39.863-2.709 18.185 0 31.15 3.487 39.854 10.447 8.326 6.583 13.932 17.412 13.932 30.184-.001 12.777-4.248 23.611-11.987 30.961zm112.435 0c-10.068 9.481-24.973 13.743-42.387 13.743-3.866 0-7.349-.2-10.058-.582v46.63h-29.22V393.517c9.089-1.543 21.866-2.709 39.864-2.709 18.185 0 31.151 3.487 39.854 10.447 8.326 6.583 13.933 17.412 13.933 30.184-.002 12.777-4.248 23.611-11.986 30.961zm121.134-45.854h-35.601v105.645h-29.607V416.546h-35.029v-24.768h100.228v24.768h.009zM96.757 365.081V21.502H345.81v110.011c0 5.935 4.819 10.751 10.751 10.751h94.972V365.08H96.757z" />
        <path d="M257.422 139.674c-48.614 0-88.011 39.407-88.011 88.017 0 48.606 39.396 88.008 88.011 88.008 48.607 0 88.011-39.402 88.011-88.008h-88.011v-88.017z" />
        <path d="M269.36 213.801v.026h88.019c0-24.758-10.269-47.092-26.72-63.084l-61.299 63.058zM268.436 198.336l62.224-62.231c-17.501-17.507-39.339-26.048-62.289-25.72l.044 87.93.021.021z" />
    </CustomIcon>
);

export default PowerPoint;
